<template>
  <component :is="getTemplateValue" v-if="getTemplateValue" />
</template>

<script setup>
import { watch, ref, defineAsyncComponent, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePagesStore } from '#root/stores'
import { usePageContext } from '#root/renderer/usePageContext'
import DefaultPage from '#root/components/templates/DefaultPage.vue'

const pageContext = usePageContext()
const pagesStore = usePagesStore()
const url = ref(pageContext.urlPathname)
const { template } = storeToRefs(pagesStore)
let article = null
let styleguide = null

watch(
  () => pageContext.urlPathname,
  (newVal, oldVal) => {
    // Reset tab focus on page change
    if (pageContext.urlPathname !== url.value) {
      url.value = pageContext.urlPathname
      document.body.setAttribute('tabindex', '-1')
      document.body.focus({ preventScroll: true })
    }
  }
)

const getTemplateValue = computed(() => {
  if (template.value === 'Article') {
    if (!article) article = defineAsyncComponent(() => import('#root/components/templates/ArticlePage.vue'))
    return article
  } else if (template.value === 'Styleguide') {
    if (!styleguide)
      styleguide = defineAsyncComponent(() => import('#root/components/templates/StyleguidePage.vue'))
    return styleguide
  } else {
    return DefaultPage
  }
})
</script>
