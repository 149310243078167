<!--
  * Component Name: Generic Form
  * Description: Generic Form Structure for use in different form types.
  * Props:
      wrapperClass, content
  * Components:
       InputRadioSelect, InputText, InputCheckbox, CtaButton, GenericModal, Incentive, HtmlContent, QuoteCallout
  * Usage:
      <GenericForm :content="content" :wrapper-class="" />
-->
<template>
  <div :class="['quote-container', wrapperClass]" :content-id="props.content?.system?.id">
    <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <div :class="['col-span-full', formPosition, formBackground]">
        <!-- Display thank you message if form is successfully submitted -->
        <template v-if="showModal.thanks">
          <h4 v-if="contentData.modal.thanks.title.value" class="mb-2">
            {{ contentData.modal.thanks.title.value }}
          </h4>
          <HtmlContent
            v-if="contentData.modal.thanks.content.value"
            :content="contentData.modal.thanks.content.value"
            class="thank-you-modal"
          />
        </template>

        <!-- Display the form -->
        <template v-else>
          <div class="form-intro mb-6">
            <component
              v-if="contentData.title || contentData.titleRich"
              :is="formTitle.type"
              :class="formTitle.classes"
              :content="contentData.titleRich"
            >
              {{ contentData.title }}
            </component>
            <HtmlContent
              v-if="contentData.description"
              :content="contentData.description"
              class="form-description mt-2"
            />
          </div>
          <form
            id="form-wrapper"
            autocomplete="off"
            list="autocompleteOff"
            novalidate
            @submit.prevent="submit()"
          >
            <div v-if="hasField.firstName && hasField.lastName" class="inline-input-group">
              <InputText
                v-model="firstName"
                name="firstName"
                input-type="text"
                :label="contentData.firstName"
                :label-class="contentData.labelSize"
                :aria-label="contentData.firstName"
                wrapper-class="m-half md:pr-2"
                provider-name="firstName"
                :pattern="inputPatterns.name"
                :blur-on-event="onInputBlur"
                :disabled-error="contentData.nameValidationType === 'combined'"
                :error="errors.firstName"
                :is-validated="!errors.firstName && firstName?.length >= 2"
                :validation-check-icon="contentData.nameValidationType !== 'combined'"
              />
              <InputText
                v-model="lastName"
                name="lastName"
                input-type="text"
                :label="contentData.lastName"
                :label-class="contentData.labelSize"
                :aria-label="contentData.lastName"
                wrapper-class="m-half md:pl-2 mt-6 md:mt-0"
                provider-name="lastName"
                :pattern="inputPatterns.name"
                :blur-on-event="onInputBlur"
                :disabled-error="contentData.nameValidationType === 'combined'"
                :error="errors.lastName"
                :is-validated="!errors.lastName && lastName?.length >= 2"
                :validation-check-icon="contentData.nameValidationType !== 'combined'"
                :name-validation-type="contentData.nameValidationType === 'combined'"
              />
              <small
                v-if="contentData.nameValidationType === 'combined' && (errors.firstName || errors.lastName)"
                class="form-error m-small"
              >
                {{ contentData.nameValidation }}
              </small>
            </div>

            <div v-if="hasField.dob" ref="scrollTarget" class="dob-input-group mt-6">
              <InputDate
                v-model="dob"
                name="dob"
                provider-name="dob"
                :sub-label="contentData.dobCopy"
                :labels="dobLabels"
                :label="contentData.dobLabel"
                :label-class="contentData.labelSize"
                :validated="isPassed.dob"
                v-bind="validationProps.dob.bind"
                v-on="validationProps.dob.on"
              />
            </div>

            <!-- Region -->
            <div
              v-if="hasField.provinceId"
              class="mt-6"
              :class="contentData.regionFieldType === 'dropdown' ? 'input-group' : 'input-group-radio'"
            >
              <InputRadioSelect
                v-if="contentData.regionFieldType === 'dropdown'"
                v-model="provinceId"
                name="location"
                provider-name="regionSelect"
                :label="contentData.regionLabel"
                :label-class="contentData.labelSize"
                :options="helperForm.regionCodenameToNum(contentData.regions)"
                :aria-label="contentData.regionLabel"
                placeholder="Please Select"
                :blur-on-event="onInputBlur"
                :is-validated="errors.provinceId"
                v-bind="validationProps.provinceId.bind"
                v-on="validationProps.provinceId.on"
              />
              <InputRadio
                v-if="contentData.regionFieldType === 'radio'"
                v-model="provinceId"
                name="location"
                provider-name="regionSelect"
                :options="helperForm.regionCodenameToNum(contentData.regionsRadio)"
                :label="contentData.regionLabel"
                :label-class="contentData.labelSize"
                :aria-label="contentData.regionLabel"
                :blur-on-event="onInputBlur"
                :is-validated="errors.provinceId"
                v-bind="validationProps.provinceId.bind"
                v-on="validationProps.provinceId.on"
              />
              <small v-if="errors.provinceId" class="form-error">
                {{ contentData.regionValidation }}
              </small>
            </div>

            <!-- Phone Number -->
            <div v-if="hasField.phone" class="input-group mt-6">
              <InputText
                v-model="phone"
                name="phone"
                input-type="tel"
                :label="contentData.phoneLabel"
                :sub-label="contentData.phoneCopy"
                :label-class="contentData.labelSize"
                :pattern="'^[0-9 ]*$'"
                mode="numeric"
                provider-name="Phone"
                :strip-spaces="true"
                :aria-label="contentData.phoneLabel"
                v-bind="validationProps.phone.bind"
                v-on="validationProps.phone.on"
              />
            </div>

            <!-- Email -->
            <div v-if="hasField.email" class="input-group mt-6">
              <InputText
                v-model="email"
                name="email"
                input-type="text"
                :label="contentData.emailLabel"
                :label-class="contentData.labelSize"
                model="email"
                provider-name="Email"
                :strip-spaces="true"
                :aria-label="contentData.emailLabel"
                v-bind="validationProps.email.bind"
                v-on="validationProps.email.on"
              />
            </div>

            <!-- Postcode  -->
            <div v-if="hasField.postCode" class="input-group mt-6">
              <InputText
                v-bind="postCode"
                name="postCode"
                input-type="text"
                :label="contentData.postcodeLabel"
                :label-class="contentData.labelSize"
                :sub-label="contentData.postcodeCopy"
                provider-name="PostCode"
                model-val="postCode"
                :blur-on-event="onInputBlur"
                :max-length="10"
                :strip-spaces="true"
                :aria-label="contentData.postcodeLabel"
              />
              <span
                v-if="isFieldValidating.postCode"
                :class="contentData.postcodeCopy ? 'field-check-sub-label' : 'field-check'"
                class="field-loading"
              >
                <LoadIcon v-if="!errors.postCode" class="loading" />
              </span>
              <small v-if="errors.postCode" class="form-error">
                <span v-html="errors.postCode" />
              </small>
            </div>

            <!-- Confirm Age Checkbox -->
            <div v-if="hasField.ageCheck" class="input-group mt-6">
              <InputCheckbox
                v-model="ageCheck"
                name="ageCheck"
                :value="ageCheck"
                wrapper-class="m-centered"
                :label="contentData.confirmAgeLabel"
                :label-class="contentData.labelSize"
                :aria-label="contentData.confirmAgeLabel"
                :is-validated="errors.ageCheck"
                v-bind="validationProps.ageCheck.bind"
                v-on="validationProps.ageCheck.on"
                :error-message="errors.ageCheck ? `${errors.ageCheck} ${buttonText}` : ''"
              />
            </div>
            <div
              v-if="contentData.disclaimerPosition === 'above_cta'"
              class="form-disclaimer mt-6 mb-6"
              @click="onDisclaimerClick"
              @keyup.enter.prevent="onDisclaimerClick"
              v-html="termsCopyDisplay"
              role="button"
              tabIndex="0"
            ></div>

            <div
              :class="[
                'form-submit-container',
                buttonPosition,
                buttonColour,
                contentData.ctaStatement ? 'cta-statement' : '',
                contentData.disclaimerPosition === 'below_cta' ? 'mt-10 mb-10' : ''
              ]"
            >
              <HtmlContent
                v-if="contentData.ctaStatement && buttonPosition == 'right'"
                tabindex="0"
                :content="contentData.ctaStatement"
                class="cta-statement-title m-0"
              />
              <CtaButton
                type="submit"
                variant="primary"
                :size="contentData.butttonSize || 'normal'"
                :loading="(isFormLoading, isFormBtnDisabled)"
                :disabled="(isFormLoading, isFormBtnDisabled)"
                :disable-scroll="true"
                :emitter="true"
                @mousedown="toggleValidationBlock(true)"
                @submit="submit()"
              >
                {{ contentData.submitCopy }}
              </CtaButton>
            </div>
            <div
              v-if="contentData.disclaimerPosition === 'below_cta'"
              class="form-disclaimer"
              @click="onDisclaimerClick"
              @keyup.enter.prevent="onDisclaimerClick"
              v-html="termsCopyDisplay"
              role="button"
              tabIndex="0"
            ></div>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'

import { useGlobalStore, useFormStore, useTrackingStore } from '#root/stores'
import { useGetContent } from '#root/components/composables/getContent.js'
import { useTrackFormErrors } from '#root/components/composables/trackFormErrors.js'
import { usePageContext } from '#root/renderer/usePageContext'
import { helperForm } from '#root/utils/helpers'
import InputRadio from '#root/components/inputs/InputRadio.vue'
import InputRadioSelect from '#root/components/inputs/InputRadioSelect.vue'
import InputText from '#root/components/inputs/InputText.vue'
import InputDate from '#root/components/inputs/InputDate.vue'
import InputCheckbox from '#root/components/inputs/InputCheckbox.vue'
import CtaButton from '#root/components/buttons/CtaButton.vue'
import HtmlContent from '#root/components/utils/HtmlContent.vue'
import LoadIcon from '#root/assets/images/icons/load-icon.svg'
import { navigate } from 'vike/client/router'

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  wrapperClass: { type: String, default: '' }
})

const scrollTarget = ref(null)
const pageContext = usePageContext()
const { trackFormFieldInteraction, trackFormError } = useTrackingStore()
const { createLead } = useFormStore()
const globalStore = useGlobalStore()

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'title.value', null),
    titleRich: useGetContent(props.content, 'title_rich.value', null),
    description: useGetContent(props.content, 'description.value', null),
    postcodeLabel: useGetContent(props.content, 'postcode_label.value', null),
    postcodeCopy: useGetContent(props.content, 'postcode_copy.value', null),
    firstName: useGetContent(props.content, 'first_name_label.value', null),
    lastName: useGetContent(props.content, 'last_name_label.value', null),
    dobLabels: useGetContent(props.content, 'date_of_birth_labels.value', null),
    dobLabel: useGetContent(props.content, 'date_of_birth_label.value', null),
    dobCopy: useGetContent(props.content, 'date_of_birth_copy.value', null),
    confirmAgeLabel: useGetContent(props.content, 'confirm_age_label.value', null),
    phoneLabel: useGetContent(props.content, 'phone_number_label.value', null),
    phoneCopy: useGetContent(props.content, 'phone_number_copy.value', null),
    emailLabel: useGetContent(props.content, 'email_label.value', null),
    regionLabel: useGetContent(props.content, 'region_select_label.value', null),
    regions: useGetContent(props.content, 'regions.linkedItems[0].elements.regions.value', []),
    regionsRadio: useGetContent(props.content, 'regions.linkedItems[0].elements.regions_radio.value', []),
    regionValidation: useGetContent(
      props.content,
      'form_validations.linkedItems[0].elements.validations_region_default.value',
      null
    ),
    regionFieldType: useGetContent(
      props.content,
      'regions.linkedItems[0].elements.field_type.value[0].codename',
      'radio'
    ),
    submitCopy: useGetContent(props.content, 'submit_copy.value', null),
    ctaStatement: useGetContent(props.content, 'cta_statement.value', null),
    termsCopy: useGetContent(props.content, 'terms_copy.value')
      .replace(
        'TERMS_MODAL',
        '<a tabindex="0" class="modal-link showModalA">' +
          useGetContent(props.content, 'terms_replacement_text.value', 'terms of use') +
          '</a>'
      )
      .replace(
        'PRIVACY_MODAL',
        '<a tabindex="0" class="modal-link showModalB">' +
          useGetContent(props.content, 'policy_replacement_text.value', 'privacy policy') +
          '</a>'
      ),
    termsCopyRT: useGetContent(props.content, 'terms_copy_rt.value', null)
      .replace(
        'TERMS_MODAL',
        '<a tabindex="0" class="modal-link showModalA">' +
          useGetContent(props.content, 'terms_replacement_text.value', 'terms of use') +
          '</a>'
      )
      .replace(
        'PRIVACY_MODAL',
        '<a tabindex="0" class="modal-link showModalB">' +
          useGetContent(props.content, 'policy_replacement_text.value', 'privacy policy') +
          '</a>'
      ),
    thankyouRedirect: useGetContent(props.content, 'thank_you_page_url.linkedItems[0].elements.url.value'),
    thankyouRedirectQC: useGetContent(props.content, 'thank_you_page_url.linkedItems[1].elements.url.value'),
    modal: {
      thanks: useGetContent(props.content, 'thanks_modal.linkedItems[0].elements', null),
      terms: useGetContent(props.content, 'terms_modal.linkedItems[0].elements', null),
      policy: useGetContent(props.content, 'policy_modal.linkedItems[0].elements', null)
    },
    product: {
      code: useGetContent(props.content, 'product.linkedItems[0].elements.product_code.value', null),
      type: useGetContent(props.content, 'form_type.value[0].codename', 'quote')
    },
    formType: useGetContent(props.content, 'form_type.value[0].codename', null),
    enableExitWarning: useGetContent(props.content, 'enable_page_exit_warning.value', []),
    callMeLaterOffered:
      useGetContent(props.content, 'call_me_later_offered.value[0].codename', 'disable') === 'enable',
    quoteCalloutTitle: useGetContent(props.content, 'quote_callout_title.value', null),
    quoteCalloutIcon: useGetContent(props.content, 'quote_callout_icon.value[0]'),
    formBackground: useGetContent(props.content, 'form_background.value[0].codename', ''),
    labelSize: useGetContent(props.content, 'label_size.value[0].codename', 'normal'),
    buttonPosition: useGetContent(props.content, 'button_position.value[0].codename', 'left'),
    butttonSize: useGetContent(props.content, 'button_size.value[0].codename', 'normal'),
    buttonColour: useGetContent(props.content, 'button_colour.value[0].codename', 'primary_cta'),
    disclaimerPosition: useGetContent(
      props.content,
      'disclaimer_text_position.value[0].codename',
      'above_cta'
    ),
    nameValidation: useGetContent(
      props.content,
      'form_validations.linkedItems[0].elements.validations_name_default.value',
      null
    ),
    disableEmailValidation: !!useGetContent(props.content, 'disable_email_validation.value.length', []),
    nameValidationType: useGetContent(props.content, 'name_validation.value[0].codename', 'combined')
  }
})

const inputPatterns = computed(() => helperForm.inputPatterns(globalStore.locale))

onMounted(() => {
  useTrackFormErrors(contentData.value.formType, 'form-wrapper')
})

const dobLabels = helperForm.getDOBLabels(props.content)

const submit = async () => {
  toggleValidationBlock(false)
  await validateFields()
  if (isFormValid.value) {
    const context = {
      productCode: contentData.value.product.code,
      formType: contentData.value.formType,
      callMeLaterOffered: contentData.value.callMeLaterOffered,
      ...(contentData.value.formType === 'quote' ? { trackLeadFormType: 'static form' } : {})
    }
    // Disable the form button to prevent multiple submissions
    isFormBtnDisabled.value = true

    try {
      const env = pageContext.env
      await submitForm((values) => createLead({ values, context, env }, pageContext))

      onSubmitComplete()
    } catch (e) {
      // Enable button to allow resubmission
      isFormBtnDisabled.value = false
      console.log(`${contentData.value.formType} form error: ${e}`)
      let errorLog = ''
      if (e instanceof Error) {
        errorLog = e.message
      } else {
        errorLog = e ?? 'Unknown Error'
      }
      trackFormError('Server Error', 'Request Form error: ' + errorLog, contentData.value.formType)
    }
  } else {
    // TODO: Show dialog maybe?
  }
}

const {
  firstName,
  lastName,
  dob,
  phone,
  email,
  postCode,
  provinceId,
  validateFields,
  submitForm,
  isPassed,
  isFieldValidating,
  isFormLoading,
  isFormValid,
  isFormInvalid,
  isFormBtnDisabled,
  validationProps,
  errors,
  hasField,
  toggleValidationBlock
} = helperForm.useGeneralForm(
  {
    ...helperForm.getGenericParams(props.content)
  },
  contentData.value.disableEmailValidation
)

const showModal = ref({
  thanks: false
})

const formTitle = computed(() => {
  return {
    type: contentData.value.titleRich ? HtmlContent : props.wrapperClass === 'in-page' ? 'h3' : 'h2',
    classes: props.wrapperClass === 'in-page' ? 'm-0 sidebar-form' : 'm-0'
  }
})

const buttonPosition = computed(() => {
  return contentData.value.buttonPosition === 'left'
    ? 'left'
    : contentData.value.buttonPosition === 'left_center'
      ? 'left-center'
      : contentData.value.buttonPosition === 'right'
        ? 'right'
        : contentData.value.buttonPosition === 'right_center'
          ? 'right-center'
          : contentData.value.buttonPosition === 'center'
            ? 'center'
            : 'left'
})

const buttonColour = computed(() => {
  return contentData.value.buttonColour === 'primary_cta'
    ? 'primary'
    : contentData.value.buttonColour === 'secondary_incentive'
      ? 'secondary-incentive'
      : 'primary'
})

const formBackground = computed(() => {
  switch (contentData.value.formBackground) {
    case 'primary':
      return 'primary-bg'
    case 'gs_200':
      return 'gs-200-bg'
    case 'brand_universal':
      return 'brand-unversal-bg'
    default:
      return ''
  }
})

const formPosition = computed(() => {
  if (props.wrapperClass.includes('left')) {
    return 'xl:col-span-7'
  } else if (props.wrapperClass.includes('center_small')) {
    return 'xl:col-span-6 xl:col-start-4 py-6 px-4 sm:p-6'
  } else if (
    ['center', 'm_center', 'm_native_center', 'form_center_submit_right'].some((c) =>
      props.wrapperClass.includes(c)
    )
  ) {
    return 'xl:col-span-8 xl:col-start-3 centered-form'
  } else if (props.wrapperClass.includes('in-page')) {
    return 'py-6 px-4 sm:p-6'
  } else {
    return ''
  }
})

const termsCopyDisplay = computed(() => {
  return contentData.value.termsCopyRT ? contentData.value.termsCopyRT : contentData.value.termsCopy
})

const onDisclaimerClick = (e) => {
  if (e.target.matches('.showModalA')) {
    globalStore.toggleModal('genericModal', contentData.value.modal.terms)
  } else if (e.target.matches('.showModalB')) {
    globalStore.toggleModal('genericModal', contentData.value.modal.policy)
  }
}

const onSubmitComplete = () => {
  if (contentData.value.modal.thanks) {
    showModal.value.thanks = true
  } else if (contentData.value.thankyouRedirect) {
    let thankYouRoute = contentData.value.thankyouRedirect
    // let qcRegionLookup = provinceList.value.find(
    //   (provinceObj) => provinceObj.id === submittedValues.regionId
    // )

    // // TODO: Have this be config-driven
    // if (qcRegionLookup?.code === 'QC' && contentData.value.thankyouRedirectQC) {
    //   thankYouRoute = contentData.value.thankyouRedirectQC
    // }
    navigate(thankYouRoute)
  }
}

const onInputBlur = (event) => {
  if (!event) return
  trackFormFieldInteraction(event)
}
</script>

<style lang="scss" scoped>
.quote-container {
  max-width: 100%;
  margin: auto;

  h4 {
    color: $headers;
  }

  .thank-you-modal {
    text-align: center;

    p {
      font-size: $body-regular;
      line-height: 3rem;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .form-intro {
    :deep(h2) {
      strong {
        font-weight: $w-heading;
        color: $primary-cta;
      }
    }

    :deep(h3) {
      strong {
        font-weight: $w-heading;
        color: $primary-cta;
      }
    }
  }

  :deep(.form-description) {
    p {
      line-height: 24px;
      font-size: $legal-text;
    }
  }

  .form-submit-container {
    &.secondary-incentive {
      button {
        background: $secondary-incentive;

        &:active,
        &:focus {
          outline: 4px solid darken($secondary-incentive, 8%);
          box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);
        }

        &:hover {
          background-color: darken($secondary-incentive, 5%);
          cursor: pointer;
        }

        &:disabled {
          pointer-events: none;
          background: $gs-200;
          color: $gs-500;
        }
      }
    }

    &.cta-statement {
      display: flex;
      flex-direction: column;

      @include media-query('desktop') {
        justify-content: space-between;
        flex-direction: row;
        column-gap: $spacing-6;
      }

      &.primary {
        .cta-statement-title {
          border-left: 2px solid $primary-cta;
        }
      }

      &.secondary-incentive {
        .cta-statement-title {
          border-left: 2px solid $secondary-incentive;
        }
      }

      .cta-statement-title {
        padding-left: $spacing-4;
        margin-bottom: $spacing-6;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @include media-query('desktop') {
          margin-bottom: 0px;
        }

        :deep(h5) {
          margin-bottom: 0;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
        }
      }

      .cta-btn {
        flex: 1 0 auto;
      }
    }
    &.stretch {
      text-align: left;

      :deep(button) {
        width: calc(100% - 48px);
      }
    }

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.left-center {
      text-align: left;

      @include media-query('desktop') {
        text-align: center;
      }
    }

    &.right-center {
      text-align: right;

      @include media-query('desktop') {
        text-align: center;
      }
    }
  }

  .input-wrapper,
  .dob-input-group,
  .input-group-radio {
    width: calc(100% - 48px);
  }

  .dob-input-group {
    :deep(input) {
      text-align: center;
    }
  }

  .form-intro {
    @include pageblock-header();

    h3.sidebar-form {
      display: none;
    }

    p:last-child {
      font-weight: $w-light;
      line-height: 24px;
      font-size: $legal-text;
      margin-bottom: 0;
    }
  }

  .form-disclaimer,
  .form-disclaimer :deep(p) {
    font-size: $legal-text;
    line-height: 24px;
    font-weight: $w-light;
    text-align: left;
    color: $gs-600;
  }

  .form-disclaimer :deep(a) {
    font-weight: $w-display;
    text-decoration: underline;
    color: $gs-600;
  }

  :deep(.modal-link) {
    font-weight: $w-heading;
    color: $gs-600;
    cursor: pointer;
  }

  .field-check {
    position: absolute;
    right: 20px;
    top: 40px;
    color: #11ad78;
  }

  .field-loading {
    position: absolute;
    right: 20px;
    top: 35px;

    svg.loading {
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .field-check-sub-label {
    position: absolute;
    right: 20px;
    top: 60px;
    color: #11ad78;
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;
  }
}

.quote-container.left {
  position: relative;
  padding: $spacing-4;

  @include media-query('phablet') {
    .inline-input-group {
      width: calc(100% - 48px);

      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
  }

  @include media-query('desktop') {
    padding: $spacing-12 $spacing-6;
    max-width: $g-desktop-breakpoint-min-width;

    .input-wrapper,
    .dob-input-group {
      width: calc(100% - 48px);
    }

    .inline-input-group {
      width: calc(100% - 48px);
      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;
  }
}

.quote-container.center_small {
  .form-intro {
    h2 {
      font-size: 2.8rem;
      line-height: 120%;

      @include media-query('desktop') {
        font-size: 3.2rem;
      }
    }
  }
}

.quote-container.center,
.quote-container.center_small {
  position: relative;
  padding: $spacing-4 $spacing-4 0;

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
  }

  @include media-query('desktop') {
    padding: $spacing-10 $spacing-6 $spacing-12;
    max-width: $g-desktop-breakpoint-min-width;
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;

    .centered-form {
      max-width: 712px;
      margin: 0 auto;
    }
  }

  @include media-query('phablet') {
    .inline-input-group {
      width: calc(100% - 48px);

      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }
}

.quote-container.in-page {
  border-radius: $card-radius;
  background-color: $gs-200;
  min-width: unset;
  margin: 32px auto 0 auto;

  .input-wrapper,
  .dob-input-group {
    width: calc(100% - 48px);
  }

  .dob-input-group {
    input {
      text-align: center;
    }
  }

  .form-intro {
    h1 {
      line-height: 1.2;
    }
    h2 {
      display: none;
    }
    h3.sidebar-form {
      display: block;
    }

    p {
      font-size: $legal-text;
    }
  }

  .form-submit-container {
    .cta-btn {
      width: auto;
    }
  }

  @include media-query('phablet') {
    max-width: 548px;

    .inline-input-group {
      width: calc(100% - 48px);
      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }

  @include media-query('desktop') {
    margin: 0 auto;
    max-width: 100%;
  }

  .thank-you-modal {
    text-align: left;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

.quote-container .primary-bg,
.quote-container .brand-unversal-bg {
  background-color: $primary;
  border-radius: $g-radius;
  color: $white;

  h1,
  h2,
  h3 {
    color: $white;
  }

  :deep(.label-wrapper label) {
    color: $white;
  }

  :deep(.label-info) {
    color: $white;
  }

  .form-description {
    color: $white;
  }

  :deep(.form-disclaimer) {
    color: $white;

    .modal-link,
    a,
    p {
      color: $white;
    }
  }
}

.quote-container .brand-unversal-bg {
  background-color: $brand-universal;
}

.quote-container .gs-200-bg {
  background-color: $gs-200;
  border-radius: $g-radius;
}
</style>

<style lang="scss">
// Unscoped style block needed to style validation colors
.quote-container .brand-unversal-bg {
  @include validation-colors($postive-green, $yellow);
}
</style>
