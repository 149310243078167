<!--
  * Component: Notice Bar
  * Description: This component displays a notice bar with a message and a symbol. Two types of notice bars are available:
    standard and alert.
  * Props:
      content
  * Usage:
      <notice-bar :content="value" />
-->

<template>
  <div class="grid-container mx-auto my-6 px-4 xxl:px-6" :content-id="props.content?.system?.id">
    <div class="notice-bar" :class="[`m-${contentData.type}`, contentData.background ? 'background' : '']">
      <!-- Default template -->
      <template v-if="contentData.type === 'simple'">
        <div v-if="contentData.symbol" class="notice-bar-symbol">
          {{ contentData.symbol }}
        </div>
        <html-content v-if="contentData.content" class="notice-bar-msg" :content="contentData.content" />
      </template>

      <!-- Alert template -->
      <template v-if="contentData.type === 'alert'">
        <info-icon class="info-icon" role="img" aria-label="info" />
        <html-content v-if="contentData.content" class="notice-bar-msg" :content="contentData.content" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import InfoIcon from '#root/assets/images/icons/info-icon.svg?component'
import HtmlContent from '#root/components/utils/HtmlContent.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    type: useGetContent(props.content, 'type.value[0].codename', 'simple'),
    symbol: useGetContent(props.content, 'symbol.value', null),
    content: useGetContent(props.content, 'content.value', null),
    background: !!useGetContent(props.content, 'background_colour.value[0]', null)
  }
})
</script>

<style lang="scss">
.notice-bar {
  @include flex-parent(row, nowrap, flex-start, flex-start);
  color: $core-font;

  &.background {
    padding: $spacing-2 $spacing-4;
    border-radius: $spacing-2;
    background-color: $gs-200;
  }

  .notice-bar-symbol {
    margin-right: $spacing-4;
    font-size: $body-small;
    line-height: 2.8rem;
  }

  .notice-bar-msg {
    p {
      font-size: $legal-text;
      line-height: 2.8rem;
    }

    p:last-child {
      margin-bottom: 0;
    }

    strong {
      font-weight: $w-heading;
    }

    a {
      color: $core-font;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  .notice-bar-btn {
    margin-left: auto;
    display: block;

    &:hover {
      cursor: pointer;
    }
  }

  &.m-alert {
    background-color: $yellow;
    align-items: center;
    justify-content: center;
  }

  .info-icon {
    margin-right: $spacing-4;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
}
</style>
