<template>
  <div
    class="key-benefits-card p-6"
    :class="{
      'm-row': contentData.variant === 'row',
      'm-column': contentData.variant === 'column',
      'm-column-centered': contentData.variant === 'column_centered',
      'm-column-left': contentData.variant === 'column_left_aligned'
    }"
  >
    <img
      v-if="contentData.icon.value.length"
      :src="contentData.icon.value[0].url"
      :alt="contentData.icon.value[0]?.description || 'Key Benefit Icon'"
      class="key-benefits-icon"
      width="104"
      height="104"
      fetchpriority="low"
      decoding="async"
      loading="lazy"
    />
    <div class="key-benefits-card-content">
      <h4 v-if="contentData.title.value" class="key-benefits-card-title mb-1">
        {{ contentData.title.value }}
      </h4>
      <div
        v-if="contentData.content.value"
        class="key-benefits-card-copy"
        v-html="contentData.content.value"
      ></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    icon: useGetContent(props.content, 'icon'),
    title: useGetContent(props.content, 'title'),
    content: useGetContent(props.content, 'content'),
    variant: useGetContent(props.content, 'layout_type.value[0].codename', 'row')
  }
})
</script>

<style lang="scss">
.key-benefits-grid-items {
  display: flex;
}

.key-benefits-card {
  border-radius: $g-radius;
  box-shadow: $g-box-shadow;
  background-color: $white;
  width: 100%;
}

.key-benefits-icon {
  display: block;
  width: 104px;
  flex: 0 0 104px;
  height: auto;
  margin-bottom: $spacing-6;

  @include media-query('tablet') {
    margin-bottom: 0;
  }
}

.key-benefits-card.m-row {
  .key-benefits-icon {
    text-align: center;
    margin: 0 auto $spacing-6 auto;

    @include media-query('phablet') {
      margin: 0 $spacing-6 0 0;
    }
  }

  @include media-query('phablet') {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
  }
}

.key-benefits-card-title {
  color: $headers;
  font-size: $body-large;
  font-weight: $w-heading;
  line-height: 24px;
}

.key-benefits-card-copy p {
  margin-bottom: 0;
  color: $core-font;
  font-size: $body-small;
  line-height: 28px;
  font-weight: $w-body;
}

.key-benefits-card.m-column {
  margin-bottom: $spacing-4;

  @include media-query('tablet') {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .key-benefits-card-content {
      margin-top: $spacing-6;
    }
  }
}

.key-benefits-card.m-column-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 0;

  .key-benefits-card-content {
    text-align: center;
    margin-top: $spacing-6;
  }
}

.key-benefits-card.m-column-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  margin-bottom: $spacing-4;
  height: 100%;

  .key-benefits-card-content {
    text-align: center;
  }

  @include media-query('desktop') {
    flex-direction: row;
    align-items: flex-start;

    .key-benefits-card-content {
      text-align: left;
      margin-left: $spacing-4;
    }
  }
}
</style>
