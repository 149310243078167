<!--
  * Component Name: ContentImage
  * Description: Component to render images
  * Props:
      content, addClass
  * Usage:
      <content-image :addClass="value" :content="value" />
-->

<template>
  <div class="image-container">
    <picture>
      <source
        v-if="contentData.mobileImage?.url"
        :srcset="helperImage.optimise(contentData.mobileImage)"
        :media="`(max-width: ${!contentData.tabletImage.url ? '1023px' : breakPointM})`"
        :width="contentData.mobileWidth"
        :height="contentData.mobileHeight"
      />
      <source
        v-if="contentData.tabletImage?.url"
        :srcset="helperImage.optimise(contentData.tabletImage)"
        :media="`(max-width: ${breakPointT})`"
        :width="contentData.tabletWidth"
        :height="contentData.tabletHeight"
      />
      <img
        v-if="contentData.desktopImage?.url"
        :src="helperImage.optimise(contentData.desktopImage)"
        :alt="contentData.desktopImage.description || contentData.desktopImage.name"
        :width="contentData.desktopWidth"
        :height="contentData.desktopHeight"
        fetchpriority="low"
        decoding="async"
        loading="lazy"
      />
    </picture>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import { helperImage } from '#root/utils/helpers'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  breakPointM: { type: String, default: '560px' },
  breakPointT: { type: String, default: '1023px' }
})

const contentData = computed(() => {
  return {
    desktopImage: useGetContent(props.content, 'image.value[0]', null),
    mobileImage: useGetContent(props.content, 'mobile_image.value[0]', null),
    tabletImage: useGetContent(props.content, 'tablet_image.value[0]', null),
    mobileHeight: useGetContent(props.content, 'mobile_image.value[0].height', 'auto'),
    mobileWidth: useGetContent(props.content, 'mobile_image.value[0].width', 'auto'),
    tabletHeight: useGetContent(props.content, 'tablet_image.value[0].height', 'auto'),
    tabletWidth: useGetContent(props.content, 'tablet_image.value[0].width', 'auto'),
    desktopHeight: useGetContent(props.content, 'image.value[0].height', 'auto'),
    desktopWidth: useGetContent(props.content, 'image.value[0].width', 'auto')
  }
})
</script>

<style lang="scss">
.image-container {
  width: auto;

  img {
    height: auto;
    width: auto;
  }
}

.image-container .image {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: $spacing-2;
  overflow: hidden;

  &.m-desktop {
    display: none;

    @include media-query('tablet') {
      display: block;
    }
  }

  &.m-tablet {
    @include media-query('tablet') {
      display: none;
    }
  }
}

.image-container.m-text-media {
  .image {
    object-fit: cover;
    object-position: center center;
    height: 336px;

    @include media-query('phone') {
      height: 343px;
    }

    @include media-query('phablet') {
      height: 528px;
    }

    @include media-query('tablet') {
      height: 548px;
    }

    @include media-query('desktop') {
      height: 404px;
    }

    @include media-query('desktop-large') {
      height: 504px;
    }
  }
}

.image-container.m-full-media {
  .image {
    object-fit: cover;
    object-position: center center;
    height: 228px;

    @include media-query('phone') {
      height: 343px;
    }

    @include media-query('phablet') {
      height: 528px;
    }

    @include media-query('tablet') {
      height: 404px;
    }
  }
}

.image-caption {
  display: block;
  font-size: $legal-text;
  width: 100%;
}
</style>
