<template>
  <div
    :class="['content-cta-block-container', 'py-10', contentData.bgColor]"
    :content-id="props.content?.system?.id"
  >
    <div class="cta-block grid-container grid grid-cols-1 xl:grid-cols-2 mx-auto py-10 px-4 xxl:px-6">
      <div class="text-block">
        <TextContent :content="contentData.contentBlock" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import TextContent from '#root/components/pageblocks/TextContent.vue'

const props = defineProps({
  content: { type: Object, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    contentBlock: useGetContent(props.content, 'content_block.linkedItems[0]', null),
    bgColor: useGetContent(props.content, 'bg_color.value[0].codename', '')
  }
})
</script>

<style lang="scss" scoped>
.content-cta-block-container {
  &.phone_standout_bg {
    background: $phone-standout-bg;
  }

  &.cta_block_bg {
    background: $cta-block-bg;

    :deep(.text-block) {
      .text-title {
        h2 {
          color: $white;
        }
      }

      .text-content {
        p {
          color: $white;
        }
      }
    }
  }

  :deep(.links) {
    a {
      font-size: $body-regular;
    }
  }
}
</style>
