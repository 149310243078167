<!--
  * Component Name: InputCheckbox
  * Description: Checkbox Input Type
  * Props:
      inputClass, wrapperClass, disabled, label, subLabel,
      toolTip, errorMessage, options, placeholder
  * Components:

  * Usage:
      <input-checkbox :propA="value" :propB="value" :propC="value" />
-->

<template>
  <div class="checkbox-wrapper" :class="wrapperClass">
    <div class="checkbox mt-4">
      <img v-if="icon" :src="icon" :alt="name" fetchpriority="low" decoding="async" loading="lazy" />
      <input :id="name" v-model="model" :value="value" type="checkbox" :name="name" :class="inputClass" />
      <label :for="name" :class="labelClass">
        <span class="checkbox" />
        {{ label }}
        <span v-if="subLabel" class="sub-label">
          {{ subLabel }}
        </span>
      </label>
    </div>
    <small v-if="!isValidated && errorMessage && !value" class="form-error mt-2">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: { type: Array, default: null },
  value: { type: [String, Number], default: null },
  name: { type: String, default: '' },
  inputClass: { type: String, default: '' },
  wrapperClass: { type: String, default: '' },
  disabled: { type: String, default: '' },
  label: { type: String, default: '' },
  subLabel: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  isValidated: { type: [String, Boolean], default: false },
  errorMessage: { type: String, default: '' },
  icon: { type: String, default: '' }
})

const emit = defineEmits(['update:modelValue'])
const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<style lang="scss">
.checkbox-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .checkbox {
    display: flex;
    align-items: center;
  }
}

span.checkbox {
  width: 50px;
}

.checkbox-wrapper.m-centered {
  position: relative;

  .input-group [type='checkbox']:not(:checked) + label,
  .input-group [type='checkbox']:checked + label {
    position: relative;
  }
}

.input-group [type='checkbox']:not(:checked),
.input-group [type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}

.input-group [type='checkbox']:not(:checked) + label,
.input-group [type='checkbox']:checked + label {
  padding-left: 48px;
  margin: 5px 0 3px;
  font-size: $body-small;
  line-height: 24px;
  font-weight: $w-label;
  cursor: pointer;

  @include media-query('tablet') {
    font-family: $primary-font;
  }
}

.input-group [type='checkbox']:not(:checked) + label .checkbox:before,
.input-group [type='checkbox']:checked + label .checkbox:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  border: 2px solid $gs-300;
  background-color: $white;
}

.input-group [type='checkbox']:not(:checked) + label .checkbox:after,
.input-group [type='checkbox']:checked + label .checkbox:after {
  content: '';
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 11px;
  width: 13px;
  height: 13px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.2 12.975L0 6.775L1.7 5.075L6.2 9.575L15.775 0L17.475 1.7L6.2 12.975Z' fill='%23F3B61A'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
}

.input-group [type='checkbox']:not(:checked) + label .checkbox:after {
  opacity: 0;
  transform: scale(0);
}

.input-group [type='checkbox']:checked + label .checkbox:after {
  opacity: 1;
  transform: scale(1.5);
}

.input-group [type='checkbox']:checked:focus + label .checkbox:before,
.input-group [type='checkbox']:not(:checked):focus + label .checkbox:before {
  border: 2px solid $gs-500;
  outline: 4px solid $yellow;
}

.checkbox-wrapper.optional-extra {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 40px;

  img {
    position: absolute;
    width: 40px;
    height: 40px;
    pointer-events: none;
  }

  label {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: $w-label;
    font-size: $body-small;
    line-height: 20px;

    .sub-label {
      font-size: $legal-text;
      line-height: 20px;
      font-weight: $w-body;
      color: $gs-500;
    }
  }

  [type='checkbox']:not(:checked) + label .checkbox:before,
  [type='checkbox']:checked + label .checkbox:before {
    right: 0;
    left: unset;
    top: 50%;
    transform: translateY(-50%);
  }

  [type='checkbox']:not(:checked) + label .checkbox:after,
  [type='checkbox']:checked + label .checkbox:after {
    right: 7px;
    left: unset;
    top: 50%;
    width: 18px;
    height: 13px;
    transform: translateY(-50%);
    mask: url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.2 12.975L0 6.775L1.7 5.075L6.2 9.575L15.775 0L17.475 1.7L6.2 12.975Z' fill='%23F3B61A'/%3E%3C/svg%3E%0A")
      no-repeat 100% 100%;
    mask-size: cover;
    background-color: $cta;
    background-image: unset;
  }
}
</style>
