<!--
  * Component Name: InputRadioSelect
  * Description: Select Input Type
  * Props:
      inputClass, wrapperClass, disabled, label, subLabel,
      toolTip, errorMessage, options, placeholder, changeOnEvent
  * Components:

  * Usage:
      <input-radio-select :propA="value" :propB="value" :propC="value" />
-->

<template>
  <div class="input-wrapper mb-1" :class="[wrapperClass, { valid: isValidated, invalid: error }]">
    <div class="dropdown">
      <InputLabel :label="label" :label-class="labelClass" :for-label="name" :sub-label="subLabel" />
      <button
        class="opener"
        tabindex="0"
        @click.prevent="opened = !opened"
        @keyup.enter.prevent="opened = !opened"
      >
        {{ model.name || placeholder }}
        <dropdown-icon class="icon" />
      </button>
      <div v-if="props.validationCheckIcon" class="validation-check">
        <LoadIcon v-if="isValidating" class="loading" />
        <InvalidIcon v-else-if="error" class="invalid-icon" />
        <ValidatedIcon v-else-if="isValidated" class="valid-icon" />
      </div>
      <div class="radio-list" :class="{ opened: opened }">
        <ul>
          <li
            v-for="(option, index) in options"
            :key="index"
            :value="{ text: option.name, value: option.codename }"
            tabindex="0"
            @blur.stop="[blurOnEvent($event), emit('blur', $event)]"
            @click.prevent="setValue(option)"
            @keyup.enter.prevent="setValue(option)"
            role="button"
            :name="name"
          >
            <span :class="{ check: model.name === option.name }">{{ option.name }}</span>
            <div class="radio-btn" :class="{ 'select-radio': model.name === option.name }"></div>
          </li>
        </ul>
      </div>
    </div>
    <small v-if="errorMessage" class="form-error pl-4 mt-2">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import InputLabel from '#root/components/inputs/InputLabel.vue'
import DropdownIcon from '#root/assets/images/icons/drop-arrow-down.svg'
import ValidatedIcon from '#root/assets/images/icons/validated-icon.svg'
import InvalidIcon from '#root/assets/images/icons/validation-error-icon.svg'
import LoadIcon from '#root/assets/images/icons/load-icon.svg'

const props = defineProps({
  modelValue: { type: [String, Number], default: '' },
  name: { type: String, default: '' },
  inputClass: { type: String, default: '' },
  wrapperClass: { type: String, default: '' },
  disabled: { type: String, default: '' },
  label: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  subLabel: { type: String, default: '' },
  errorMessage: { type: String, default: '' },
  isValidated: { type: Boolean, default: false },
  isValidating: { type: Boolean, default: false },
  validationCheckIcon: { type: Boolean, default: true },
  error: { type: [Boolean, String], default: null },
  blurOnEvent: { type: Function, default: () => ({}) },
  options: {
    type: Array,
    default: () => [{ value: '', text: '' }]
  },
  placeholder: { type: String, default: '' },
  changeOnEvent: { type: Function, default: () => ({}) },
  rules: { type: Object, default: () => ({}) }
})

const emit = defineEmits(['update:modelValue', 'input', 'blur'])
const model = ref({ name: '', id: '' })
const opened = ref(false)

onMounted(() => {
  window.addEventListener('click', outsideClick)
})

onUnmounted(() => {
  window.removeEventListener('click', outsideClick)
})

watch(opened, () => {
  const dropList = document.getElementsByClassName('dropdown-list')
  for (let i = 0; i < dropList.length; i++) {
    if (dropList[i].className.includes('opened')) {
      dropList[i].className = dropList[i].className.replace('opened', '')
    }
  }
})

const setValue = (option) => {
  model.value = { name: option.name, id: option.codename }
  opened.value = false
  emit('update:modelValue', option.codename)
  emit('input', option.codename)
}

const outsideClick = (e) => {
  if (e.target.className !== 'opener') {
    opened.value = false
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  .validation-check {
    position: absolute;
    bottom: 8px;
    right: -40px;
  }
}

.invalid {
  button.opener {
    border: 2px solid $red;
  }
}

.valid {
  button.opener {
    border: 2px solid $core-font;
  }
}

button.opener {
  @include flex-parent(row, nowrap, space-between, center);
  height: 48px;
  width: 100%;
  padding: 10px 16px;
  background-color: $white;
  border-radius: $input-radius;
  border: 1px solid $gs-500;
  color: $core-font;
  cursor: pointer;
  font-size: $body-small;
  line-height: 28px;
  font-family: $primary-font;

  .icon {
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    color: $gs-500;
    fill: $gs-500;

    path {
      fill: $gs-500;
    }
  }
}

.radio-list {
  display: none;
  position: absolute;
  top: 28px;
  left: 0;
  max-width: 100%;
  width: 100%;
  max-height: 340px;
  font-size: $body-small;
  line-height: 28px;
  z-index: 99;
  background-color: $white;
  border-radius: $spacing-1;
  border: 1px solid $gs-300;
  //overflow-y: scroll;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: calc(340px - 16px);
    width: 100%;
  }

  li {
    @include flex-parent(row, nowrap, space-between, center);
    cursor: pointer;
    user-select: none;
    color: $gs-800;
    font-size: $body-small;
    line-height: 28px;
    border-bottom: 1px solid $gs-300;
    padding: 18px;

    &:first-child {
      margin-top: 0;
    }

    svg {
      fill: currentColor;
      width: 16px;
      height: 16px;
      margin-right: 7px;

      path {
        fill: currentColor;
      }
    }

    .radio-btn {
      width: 24px;
      height: 24px;
      border: 2px solid $gs-400;
      background-color: $white;
      border-radius: 100px;

      &.select-radio {
        background-color: $white;
        border: 2px solid $primary;
        position: relative;

        &:after {
          content: '';
          width: 16px;
          height: 16px;
          background-color: $primary;
          border-radius: 100px;
          position: absolute;
          right: 2px;
          top: 2px;
        }
      }
    }
  }

  &.opened {
    display: block;
  }
}
</style>
