<!--
  * Component Name: AccordionView
  * Description: AccordionView for FAQ, contains a header title, questions and answer
  * Props:
      content
  * Components:
      CtaButton
  * Usage:
      <accordion-view :content="value" />
-->

<template>
  <div class="accordion">
    <html-content v-if="contentData.header" :content="contentData.header" class="accordion-header mb-6" />
    <div
      v-for="(accordionItem, index) in contentData.accordionItems"
      :key="index"
      class="accordion-item mb-4 last:mb-0 py-4 px-6"
      :class="{ opened: index === openedIndex }"
    >
      <div
        class="accordion-question"
        :data-gtm="
          googleTagManager.faqIcon +
          (index === openedIndex ? ' up' : ' down') +
          ' ' +
          accordionItem.elements.question.value.toLowerCase()
        "
        @click="toggleAccordion(index)"
        @keyup.enter="toggleAccordion(index)"
        role="button"
        tabIndex="0"
      >
        <button
          type="button"
          class="accordion-question-button"
          :aria-expanded="index === openedIndex ? 'true' : 'false'"
          :aria-controls="'section' + index"
        >
          {{ accordionItem.elements.question.value }}
        </button>
        <arrow-icon
          class="arrow"
          :data-gtm="
            googleTagManager.faqIcon +
            (index === openedIndex ? ' up' : ' down') +
            ' ' +
            accordionItem.elements.question.value.toLowerCase()
          "
        />
      </div>
      <transition name="accordion" @before-enter="start" @enter="start" @after-leave="end">
        <html-content
          v-show="index === openedIndex"
          :id="accordionItem.system.codename + index"
          :content="accordionItem.elements.answer.value"
          class="accordion-answer"
        />
      </transition>
    </div>
    <div v-if="contentData.linkText" class="accordion-footer mt-6">
      <cta-button :href="contentData.linkUrl" :variant="'tertiary'" :right-arrow="true">
        {{ contentData.linkText }}
      </cta-button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useGetContent, useGetContentOptions, useGetContentPage } from '#root/components/composables/getContent.js'

import HtmlContent from '#root/components/utils/HtmlContent.vue'
import CtaButton from '#root/components/buttons/CtaButton.vue'
import ArrowIcon from '#root/assets/images/icons/arrow-dropdown-icon.svg'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const openedIndex = ref(useGetContentPage('accordion_collapse.value[0].codename', 'no') === 'yes' ? null : 0)

const contentData = computed(() => {
  return {
    header: useGetContent(props.content, 'header.value', null),
    accordionItems: useGetContent(props.content, 'accordion_items.linkedItems', null),
    linkText: useGetContent(props.content, 'module_cta.linkedItems[0].elements.link_text.value', null),
    linkUrl: useGetContent(
      props.content,
      'module_cta.linkedItems[0].elements.site_link.linkedItems[0].elements.url.value',
      ''
    )
  }
})

const googleTagManager = computed(() => {
  return {
    faqIcon: useGetContentOptions('faq_icon.value'),
    faqCta: useGetContentOptions('faq_cta.value')
  }
})

const toggleAccordion = (index) => {
  openedIndex.value = openedIndex.value === index ? null : index
}

const start = (el) => {
  el.style.height = el.scrollHeight + 'px'
}

const end = (el) => {
  el.style.height = 'px'
}
</script>

<style lang="scss">
.accordion {
  margin-right: auto;
}

.accordion-header {
  @include pageblock-header();

  p {
    font-weight: $w-body;
    font-size: $body-regular;
    line-height: 30px;
    color: $core-font;
    margin: $spacing-3 0;

    a {
      color: $core-font;
      text-decoration: underline;
    }
  }
}

.accordion-item {
  background-color: $accordion-bg;
  border-radius: $card-radius;

  .accordion-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      .accordion-question-button {
        color: $headers;
      }

      .arrow {
        path {
          stroke: $primary;
        }
      }
    }

    .accordion-question-button {
      appearance: none;
      cursor: pointer;
      text-align: left;
      font-family: $primary-font;
      padding: 0;
      background-color: inherit;
      font-weight: $w-cta;
      font-size: $body-large;
      line-height: 24px;
      color: $accordion-header;
      width: 90%;
    }

    .arrow {
      transform: rotate(0deg);
      transition: all 0.3s ease-in-out;
      fill: none;

      path {
        stroke: $accordion-header;
      }
    }
  }

  .accordion-answer {
    a {
      color: $accordion-font;
      text-decoration: underline;

      &:hover {
        color: $cta;
      }
    }

    p:first-child {
      padding-top: $spacing-8;
    }

    p:last-child {
      padding-bottom: $spacing-4;
    }

    p {
      margin: 0;
      padding: 0 0 $spacing-4 0;
    }

    b {
      font-weight: $w-heading;
    }

    ul {
      padding: $spacing-8 0 $spacing-4;
      margin-left: 30px;

      li {
        margin-bottom: $spacing-4;
        position: relative;
      }

      li:before {
        content: '';
        display: inline-block;
        position: absolute;
        background-color: $accordion-font;
        border-radius: 50px;
        left: -22px;
        top: 12px;
        height: 8px;
        width: 8px;
      }
    }

    ol {
      counter-reset: my-counter;
      list-style: none;

      li {
        counter-increment: my-counter;
        position: relative;
        margin-bottom: $spacing-4;
      }

      li:before {
        content: counter(my-counter);
        width: 32px;
        height: 32px;
        display: inline-block;
        border-radius: 50%;
        color: $white;
        background-color: $accordion-font;
        text-align: center;
        font-size: $body-small;
        margin-right: $spacing-4;
      }
    }
  }

  &.opened {
    .accordion-question {
      .accordion-question-button {
        color: $accordion-header;
      }

      .arrow {
        transform: rotate(180deg);
        transition: all 0.3s ease-in-out;

        path {
          stroke: $accordion-header;
        }
      }
    }
  }
}

.accordion-footer {
  .cta-btn.m-tertiary {
    text-align: left;
    padding: 0;
    height: auto;
    min-width: auto;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition:
    height 1s ease,
    all 0.5s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
