<!--
  * Component: MultiCardPanel
  * Description: Short Description of component
  * Props:
      content
  * Components:
      HtmlContent
  * Usage:
      <MultiCardPanel :content="value" />
-->

<template>
  <section
    class="grid-container mx-auto py-10 xxl:py-12 px-4 xxl:px-6"
    :content-id="props.content?.system?.id"
  >
    <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <div class="col-span-full">
        <div class="multicard-panel-content">
          <HtmlContent
            v-if="contentData.title"
            :content="contentData.title"
            class="multicard-panel-title mb-4"
          />
          <HtmlContent
            v-if="contentData.content"
            :content="contentData.content"
            class="multicard-panel-copy"
          />
        </div>
      </div>
      <div class="col-span-full options-card-container mt-10">
        <OptionsCard
          v-for="(card, index) in contentData.optionsCards"
          :key="index"
          :content="card"
          :gtm="`${gtmData.optionsCardCta}${index + 1}`"
        />
      </div>
      <div v-if="contentData.footerContent" class="col-span-full mt-10">
        <HtmlContent class="multicard-panel-footer" :content="contentData.footerContent" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { gtmData } from '#root/utils/types'
import { useGetContent } from '#root/components/composables/getContent.js'

import OptionsCard from '#root/components/pageblocks/OptionsCard.vue'
import HtmlContent from '#root/components/utils/HtmlContent.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'title.value'),
    content: useGetContent(props.content, 'content.value'),
    optionsCards: useGetContent(props.content, 'options_cards.linkedItems'),
    footerContent: useGetContent(props.content, 'footer_content.value')
  }
})
</script>

<style lang="scss">
.multicard-panel-copy {
  font-size: $body-regular;
  color: $core-font;
}

.multicard-panel-title {
  @include pageblock-header();
}

.options-card-container {
  @include media-query('desktop') {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
}

.multicard-panel-content,
.multicard-panel-footer {
  p:not(:last-child) {
    margin-bottom: $spacing-4;
  }

  [href^='tel:'] {
    @include phone-standout();
  }
}
</style>
