<template>
  <div>
    <div class="dob-input-group">
      <div class="input-birthdate">
        <div class="birthdate-row" :class="globalStore.locale">
          <div :class="['month', ...rowState.month]">{{ modelValue.month || 'MM' }}</div>
          <div :class="['day', ...rowState.day]">{{ modelValue.day || 'DD' }}</div>
          <div :class="['year', ...rowState.year]">{{ modelValue.year || 'YYYY' }}</div>
          <span class="reset" @click="reset" @keyup.enter.prevent="reset" role="button" tabIndex="0">
            {{ contentData.sublabel }}
          </span>
        </div>
        <div v-if="showPad && data.activeRange" class="birthdate-pad">
          <div
            v-for="(range, i) in data.activeRange.range"
            :key="i"
            :class="[
              'range',
              data.activeRange.type,
              { selected: modelValue[data.activeRange.type] === range }
            ]"
            @click="selectRange(range, data.activeRange.type, $event)"
            @keyup.enter.prevent="selectRange(range, data.activeRange.type, $event)"
            role="button"
            tabIndex="0"
          >
            {{ range }}
          </div>
        </div>
        <div v-else class="form-submit-container">
          <small v-if="error && typeof error === 'string'" class="form-error m-small">
            <span v-html="error" />
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores'
import { useGetContent } from '#root/components/composables/getContent'
import * as date from '#root/utils/models/Date'
import { usePageContext } from '#root/renderer/usePageContext'
import { helperScroll } from '#root/utils/helpers'

const globalStore = useGlobalStore()
const pageContext = usePageContext()

const props = defineProps({
  content: {
    type: Object,
    default: null
  },
  modelValue: {
    type: Object,
    default: () => new date.Model('', '', '')
  },
  error: {
    type: [String, Boolean],
    default: false
  },
  isValidated: {
    type: Boolean,
    default: false
  },
  isValidating: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'nextStep', 'buttonState'])
const data = reactive({
  activeRange: null,
  ranges: {
    month: {
      type: 'month',
      range: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    day: {
      type: 'day',
      range: 31
    },
    year: {
      type: 'year',
      range: []
    }
  }
})

onMounted(() => {
  data.activeRange = globalStore.locale === 'CA' ? data.ranges.month : data.ranges.day
  data.ranges.year.range = yearRangeCalc
})

const contentData = computed(() => {
  return {
    sublabel: 'Reset',
    yearConstraints: useGetContent(props.content, 'dob_constraints.value', []),
    dobMaxOverride: useGetContent(props.content, 'dob_max_override.value', null),
    cta: useGetContent(props.content, 'cta_button.value', '')
  }
})

const rowState = computed(() => {
  return {
    day: props.modelValue.day ? ['filled'] : data?.activeRange?.type === 'day' ? ['selected'] : '',
    month: props.modelValue.month ? ['filled'] : data?.activeRange?.type === 'month' ? ['selected'] : '',
    year: props.modelValue.year ? ['filled'] : data?.activeRange?.type === 'year' ? ['selected'] : ''
  }
})

const yearRangeCalc = computed(() => {
  const date = new Date()
  const currentYear = date.getFullYear()
  const min = currentYear - contentData.value.yearConstraints[0].name
  const max = currentYear - contentData.value.yearConstraints[1].name
  const maxOverride = contentData.value.dobMaxOverride
    ? currentYear - contentData.value.dobMaxOverride
    : false
  const maxAge = maxOverride || max
  const ranges = []
  for (let i = min; i >= maxAge - 1; i--) {
    ranges.push(i)
  }
  return ranges
})

const reset = () => {
  data.activeRange = ['GB', 'NZ', 'AU'].includes(globalStore.locale) ? data.ranges.day : data.ranges.month
  emit('update:modelValue', { day: '', month: '', year: '' })
}

const showPad = computed(() => {
  return !props.modelValue.day || !props.modelValue.month || !props.modelValue.year
})

const { windowSize } = storeToRefs(globalStore)

const selectRange = (range, type) => {
  if (type === 'month') range = data.ranges.month.range.indexOf(range) + 1
  if (range < 10) range = '0' + range

  const { year, month, day } = props.modelValue
  const dateObject = new date.Model(year, month, day)
  dateObject[type] = range.toString()
  emit('update:modelValue', dateObject)

  if (['GB', 'NZ', 'AU'].includes(globalStore.locale)) {
    data.activeRange =
      type === 'day' ? data.ranges.month : type === 'month' ? data.ranges.year : data.ranges.day
  } else {
    data.activeRange =
      type === 'month' ? data.ranges.day : type === 'day' ? data.ranges.year : data.ranges.month
  }

  const scrollToTop = () => {
    const progressBar = document.querySelector('.progress-bar-container')
    if (progressBar) {
      helperScroll.scrollToElement('.progress-bar-container', windowSize.value?.isDesktop ? 160 : 80)
    } else {
      helperScroll.scrollToElement('.form-step', windowSize.value?.isDesktop ? 190 : 80)
    }
  }

  scrollToTop()
}
</script>

<style lang="scss" scoped>
.input-birthdate {
  min-height: 100%;

  .birthdate-row {
    background: $white;
    display: flex;
    height: 86px;
    border: 1px solid $gs-400;
    border-radius: $spacing-2;
    padding: $spacing-6 $spacing-10;
    position: relative;
    justify-content: center;

    .day,
    .month,
    .year {
      cursor: pointer;
      font-size: 3.2rem;
      font-weight: $w-label;
      line-height: 3.8rem;
      margin: 0 $spacing-2;
      position: relative;
      color: $core-font;

      &.selected {
        color: $primary-cta;
      }

      &::after {
        content: '/';
        color: $gs-400;
        position: absolute;
        right: -11px;
        font-size: $legal-text;
      }

      &:nth-child(3) {
        &::after {
          content: '';
        }
      }
    }

    &.GB,
    &.NZ,
    &.AU {
      .day {
        order: 0;
      }

      .month {
        order: 1;
      }

      .year {
        order: 2;
      }
    }

    .reset {
      cursor: pointer;
      position: absolute;
      right: 12px;
      bottom: 0px;
      font-size: $legal-text;
      color: $tertiary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .birthdate-pad {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin: $spacing-6 0 0;
    justify-content: center;

    .range {
      background: $white;
      cursor: pointer;
      border: 1px solid $gs-400;
      border-radius: $spacing-2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $spacing-2 $spacing-4;
      font-size: $h4-d;
      font-weight: $w-label;
      text-transform: uppercase;
      color: $gs-600;

      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &.selected {
        background: $primary-cta;
        color: $white;
      }

      &.day {
        width: calc((100% - 24px) / 4);

        @include media-query('phablet') {
          width: calc((100% - 32px) / 5);
        }
      }

      &.month {
        width: calc((100% - 16px) / 3);
        max-width: calc((100% - 16px) / 3);
      }

      &.year {
        width: calc((100% - 16px) / 3);

        @include media-query('phablet') {
          width: calc((100% - 32px) / 5);
        }
      }
    }
  }
}
</style>
