<!--
  * Component: Options Card
  * Description: Short Description of component
  * Props:
      content
  * Components:
      linkElement
  * Usage:
      <key-benefits :content="value" />
-->

<template>
  <div class="option-card-wrapper" :content-id="props.content?.system?.id">
    <div v-if="contentData.title" class="option-card-title p-8">
      <img
        v-if="contentData.icon"
        :src="helperImage.optimise(contentData.icon)"
        :alt="contentData.iconDescription"
        class="mr-6"
        fetchpriority="low"
        decoding="async"
        loading="lazy"
      />
      <span v-html="contentData.title" />
    </div>
    <div v-if="contentData.content || contentData.optionCta" class="option-card-content-wrapper p-8">
      <div v-if="contentData.content" class="option-card-content" v-html="contentData.content" />
      <div v-if="contentData.optionCta" class="option-card-cta">
        <LinkItem
          :content="contentData.optionCta"
          :anchor="anchorTitle"
          :right-arrow="true"
          :gtm="contentData.gtm"
          :disable-scroll="contentData.disableScrollTo"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { helperImage } from '#root/utils/helpers'
import { useGetContent } from '#root/components/composables/getContent.js'

import LinkItem from '#root/components/buttons/LinkItem.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  gtm: { type: String, required: false, default: '' }
})

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'title.value'),
    content: useGetContent(props.content, 'content.value'),
    optionCta: useGetContent(props.content, 'option_cta.linkedItems[0]', null),
    icon: useGetContent(props.content, 'icon.value[0]', null),
    iconDescription: useGetContent(props.content, 'icon.value[0].description', 'Icon Image'),
    type: useGetContent(props.content, 'type.value[0].codename', 'customer'),
    scrollToAnchor: useGetContent(props.content, 'scroll_to_anchor.value', null),
    disableScrollTo: useGetContent(props.content, 'disable_scrollto.value[0].codename', null) === 'yes',
    gtm: useGetContent(props.content, 'data_gtm.value', null) || props.gtm
  }
})

const anchorTitle = computed(() => {
  if (contentData.value.scrollToAnchor.length) {
    const foundHeadingTag = contentData.value.title.match(/<h[1-6][^>]*>(.*?)<\/h[1-6]>/g)
    return foundHeadingTag[0]
      .replace(/<\/?[^>]+(>|$)/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()
  } else {
    return ''
  }
})
</script>

<style lang="scss">
.option-card-wrapper {
  border-radius: $card-radius;
  overflow: hidden;
  box-shadow: $g-box-shadow;
  display: flex;
  flex-direction: column;
  flex: 0 0 32.5%;

  &:not(:last-child) {
    margin-bottom: $spacing-6;

    @include media-query('desktop') {
      margin-bottom: 0;
    }
  }
}

.option-card-title {
  background-color: $options-card-header-bg;
  color: $options-card-header-font;
  display: flex;
  align-items: center;

  @include headings {
    color: $options-card-header-font;
  }
}

.option-card-content {
  padding-bottom: $spacing-4;
}

.option-card-content-wrapper {
  @include flex-parent(column, wrap, space-between, stretch);
  height: 100%;
}
</style>
