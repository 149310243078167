import { computed } from 'vue'
import { useGetContent, useGetContentOptions } from '#root/components/composables/getContent.js'

export default function getBannerContent(props) {
  const gtm = computed(() => {
    return {
      heroBannerCTA: useGetContentOptions('hero_banner_cta.value', '')
    }
  })

  const contentData = computed(() => {
    return {
      foregroundImageDesktop: useGetContent(
        props.content,
        'foreground_image.linkedItems[0].elements.image.value[0]',
        null
      ),
      foregroundImageTablet: useGetContent(
        props.content,
        'foreground_image.linkedItems[0].elements.tablet_image.value[0]',
        null
      ),
      foregroundImageMobile: useGetContent(
        props.content,
        'foreground_image.linkedItems[0].elements.mobile_image.value[0]',
        null
      ),
      incentive: useGetContent(props.content, 'incentive.linkedItems[0].elements', null),
      incentiveLocation: useGetContent(props.content, 'incentive_location.value[0].codename', null),
      trustPilotWidget: useGetContent(props.content, 'trustpilot_widget.linkedItems[0].elements', null),
      bannerContent: {
        title: useGetContent(props.content, 'title.value', ''),
        byline: useGetContent(props.content, 'byline.value', ''),
        content: useGetContent(props.content, 'content.value', ''),
        ctas: useGetContent(props.content, 'ctas.linkedItems', [])
      },
      type: useGetContent(props.content, 'type.value[0].codename', ''),
      appointmentForm: useGetContent(props.content, 'appointment_form.linkedItems[0]', null),
      multiStepForm: useGetContent(props.content, 'multi_step_form.linkedItems[0]', null)
    }
  })

  return {
    gtm,
    contentData
  }
}
