<!--
  * Component Name: Compare Table
  * Description: This component uses the Compare Table Row component to add rows the table head and body.
    The Compare Table Row component has slots for Image, Text, and Choice of true or false.
  * Props:
      content
  * Usage:
      <compare-table :content="value" />
-->

<template>
  <div :class="contentData.wrapperClass">
    <section
      class="grid-container mx-auto py-10 px-4 xxl:px-6"
      :class="{ 'xxl:py-11': !contentData.options.isPopout }"
      :content-id="props.content?.system?.id"
    >
      <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
        <div v-if="contentData.compareContent.length" class="col-span-full compare-table-header">
          <TextContent class="compare-table-intro mb-10" :content="contentData.compareContent[0]" />
          <span
            class="close-popout"
            @click="togglePopout"
            @keyup.enter.prevent="togglePopout"
            role="button"
            tabIndex="0"
          >
            <close-icon />
          </span>
        </div>
        <div class="col-span-full lg:col-span-6 lg:col-start-2 xl:col-span-full xl:col-start-1">
          <div class="compare-table-full-container">
            <!-- Head -->
            <div class="row compare-table-head">
              <div
                v-for="(head, item) in contentData.head"
                :key="item"
                class="column compare-table-column"
                :class="`position-${slidePosition}`"
                :style="`transform: translateX(${slidePosition * -100}%);`"
              >
                <div v-if="useCleanText(head.elements.text.value)" v-html="head.elements.text.value" />
                <img
                  v-else-if="head.elements.image.value.length"
                  :src="helperImage.optimise(head.elements.image.value[0])"
                  :alt="head.elements.image.value[0].description"
                  class="mx-auto"
                  fetchpriority="low"
                  decoding="async"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="compare-table-rows-container">
              <div v-for="(row, index) in contentData.rows" :key="index" class="row compare-table-row">
                <div class="column compare-table-column p-4">
                  <div v-if="row.elements.image.value.length">
                    <img
                      :src="helperImage.optimise(row.elements.image.value[0])"
                      :alt="row.elements.image.value[0].description"
                      fetchpriority="low"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <div v-else-if="useCleanText(row.elements.title.value)" v-html="row.elements.title.value" />
                </div>
                <div
                  v-for="(option, item) in row.elements.columns.linkedItems"
                  :key="item"
                  class="column compare-table-column py-5"
                  :class="`position-${slidePosition}`"
                  :style="`transform: translateX(${slidePosition * -100}%);`"
                >
                  <div class="content">
                    <true-icon
                      v-if="
                        option.elements.choice.value.length &&
                        option.elements.choice.value[0].codename === 'true'
                      "
                      class="compare-icon"
                    />
                    <false-icon
                      v-else-if="
                        option.elements.choice.value.length &&
                        option.elements.choice.value[0].codename === 'false'
                      "
                      class="compare-icon"
                    />

                    <div
                      v-if="useCleanText(option.elements.text.value)"
                      class=""
                      v-html="option.elements.text.value"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="compare-table-footer">
              <div class="compare-table-pagination p-4">
                <div
                  class="pagination-arrow mr-4"
                  @click="previousSlide()"
                  @keyup.enter.prevent="previousSlide()"
                  role="button"
                  tabIndex="0"
                >
                  <arrow-icon class="arrow m-left" />
                </div>
                <div class="pagination-dots">
                  <div
                    v-for="(items, dot) in contentData.head"
                    :key="dot"
                    class="pagination-dot"
                    :class="slidePosition === dot ? 'active' : ''"
                  />
                </div>
                <div
                  class="pagination-arrow ml-4"
                  @click="nextSlide()"
                  @keyup.enter.prevent="nextSlide()"
                  role="button"
                  tabIndex="0"
                >
                  <arrow-icon class="arrow m-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Table footer content -->
        <div v-if="contentData.compareFooterContent.length" class="col-span-full">
          <TextContent class="compare-table-intro" :content="contentData.compareFooterContent[0]" />
        </div>
        <div v-if="contentData.cta || contentData.callus" class="cta-block col-span-full">
          <div class="cta">
            <LinkItem v-if="contentData.cta.length" :content="contentData.cta[0]" />
          </div>
          <div class="call-us">
            <p v-if="contentData.callus">{{ contentData.callus }}</p>
          </div>
          <div v-if="dynamicPhoneNumber" class="phone">
            <phone-icon class="mr-2" role="img" aria-label="phone" />
            <a aria-label="phone number" :href="'tel:' + dynamicPhoneNumber" class="m-0">
              {{ dynamicPhoneNumber }}
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '#root/stores'
import { helperPopout } from '#root/utils/helpers'
import { helperImage } from '#root/utils/helpers'
import { useGetContent, useCleanText } from '#root/components/composables/getContent.js'
import TextContent from '#root/components/pageblocks/TextContent.vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'
import TrueIcon from '#root/assets/images/icons/true-icon.svg'
import FalseIcon from '#root/assets/images/icons/false-icon.svg'
import ArrowIcon from '#root/assets/images/icons/chevron-icon.svg'
import PhoneIcon from '#root/assets/images/icons/phone-icon.svg'
import CloseIcon from '#root/assets/images/icons/close-icon.svg'

const slidePosition = ref(0)
const globalStore = useGlobalStore()
const { dynamicPhoneNumber } = storeToRefs(globalStore)
const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const popoutClass = useGetContent(props.content, 'popout_class.value', null)

const contentData = computed(() => {
  const contentOptions = useGetContent(props.content, 'options.value', [])
  let wrapperClass = contentOptions.length
    ? contentOptions[0].codename === 'popout_table'
      ? 'popout-table'
      : null
    : null
  const options = {
    isPopout: wrapperClass === 'popout-table'
  }
  if (popoutClass) wrapperClass += ' ' + popoutClass

  const ctaBlock = useGetContent(props.content, 'compare_footer_cta.linkedItems', [])
  const cta = ctaBlock.length ? ctaBlock[0].elements.cta.linkedItems : null
  const callus = ctaBlock.length ? ctaBlock[0].elements.call_us.value : null

  return {
    rows: useGetContent(props.content, 'compare_rows.linkedItems', []),
    head: useGetContent(props.content, 'compare_head.linkedItems', []),
    compareContent: useGetContent(props.content, 'compare_content.linkedItems', []),
    compareFooterContent: useGetContent(props.content, 'compare_footer_content.linkedItems', []),
    cta,
    callus,
    wrapperClass,
    options
  }
})

const previousSlide = () => {
  if (slidePosition.value === 0) return
  slidePosition.value = slidePosition.value - 1
}

const nextSlide = () => {
  let slideLength = contentData.value.head.length - 2 // Ignore the first column
  if (slidePosition.value === slideLength) return

  slidePosition.value = slidePosition.value + 1
}

const togglePopout = () => {
  if (popoutClass) helperPopout.toggle(popoutClass)
}
</script>

<style lang="scss">
.compare-table-intro {
  @include pageblock-header();

  h2 {
    margin-bottom: $spacing-4;
  }
}

.compare-table-full-container {
  margin-bottom: $spacing-10;
}

.compare-table {
  border-radius: $spacing-2;
  overflow: hidden;
  margin-bottom: $spacing-4;

  .column {
    width: 100%;

    @include media-query('tablet') {
      display: block;
      flex: 0 0 10%;
      text-align: center;

      &:first-child {
        flex: 1 0 45%;
        text-align: left;
      }
    }
  }

  .row {
    @include flex-parent(row, nowrap, space-between, flex-start);

    padding: $spacing-2 $spacing-2;
    padding-right: $spacing-4;

    @include media-query('tablet') {
      padding: $spacing-4 $spacing-4;
      padding-right: $spacing-5;
    }

    @include media-query('desktop') {
      padding-right: $spacing-12;
    }
  }
}

.compare-table-head,
.compare-table-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: hidden;
  position: relative;

  .column {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    min-width: 50%;
    min-height: 56px;
    transform: translateX(0%);
    transition: transform 0.2s ease-out;

    div {
      width: 100%;
      padding: 0 $spacing-2;

      @include media-query('desktop') {
        padding: 0;
      }
    }

    &:first-child {
      position: relative;
      left: 0;
      top: 0;
      right: 50%;
      bottom: 0;
      z-index: 2;
      background-color: $table-odd;
      transform: translateX(0) !important;

      @include media-query('desktop') {
        position: static;
        min-width: 236px;
        flex: 0 0 236px;
        background-color: unset;
      }
    }
  }

  @include media-query('desktop') {
    justify-content: space-between;
    align-items: stretch;

    .column {
      justify-content: space-between;
      flex: 1 1 20%;
      min-width: 163px;
      min-height: 88px;
      transform: translateX(0) !important;
    }
  }
}

.compare-table-head {
  background-color: $table-background;
  border-radius: 8px 8px 0 0;
  color: contrastText($table-background);

  .column:first-child {
    background-color: $table-background;
  }

  .compare-table-column,
  .compare-table-column p {
    font-size: $body-small;
    line-height: 30px;
    font-weight: $w-label;
  }

  .compare-table-column:first-child p {
    font-size: $body-regular;
    font-weight: $w-body;
  }
}

.compare-table-row {
  &:nth-child(1n + 1) {
    background-color: $table-even;
  }

  &:nth-child(2n + 2) {
    background-color: $table-odd;
  }

  .column:first-child {
    border-right: 1px solid $gs-400;
    min-height: 56px;
  }

  @include media-query('desktop') {
    &:not(:last-child) {
      border-bottom: 1px solid $gs-300;
    }

    p {
      font-weight: $w-body;
    }

    .column:first-child {
      border-right: 1px solid $gs-400;
      font-weight: $w-label;

      p {
        font-weight: $w-body;
      }
    }
  }
}

.compare-table-column h4 {
  font-size: $body-regular;
  color: $primary;
  margin: 0;
}

.compare-table-column,
.compare-table-column p {
  font-size: $body-small;
  line-height: 28px;
  font-weight: $w-heading;
  margin: 0;
}

.compare-table-column img {
  max-width: 90px;

  @include media-query('desktop') {
    max-width: 100%;
  }
}

.compare-table-column .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: $spacing-2 0;
  color: $black;

  @include media-query('desktop') {
    padding: $spacing-4 0;
  }
}

.compare-table-column.m-mobile-icons {
  padding: $spacing-4 0;

  .compare-table-column.m-mobile-icon {
    width: auto;
    display: none;
    flex: 1 0 15%;
    text-align: center;

    &:nth-child(2) {
      color: $postive-green;
    }

    &:nth-child(-n + 4) {
      display: block;

      &:first-child {
        flex: 1 0 30%;
        text-align: left;
      }
    }
  }
}

.compare-icon {
  height: auto;
  width: auto;
  margin: 5px auto 0;
}

.compare-table-rows-container {
  position: relative;
  max-height: 468px;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-query('tablet') {
    max-height: 100%;
  }

  @include media-query('desktop') {
    border-radius: 0 0 8px 8px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 49%;
    border-right: 1px solid $gs-400;
    z-index: 2;

    @include media-query('desktop') {
      display: none;
    }
  }
}

.compare-table-footer {
  background-color: $table-background;
  border-radius: 0 0 8px 8px;
  min-height: 80px;
  margin-bottom: $spacing-6;

  @include media-query('desktop') {
    display: none;
    margin-bottom: 0;
  }
}

.compare-table-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-arrow {
  height: 48px;
  width: 48px;
  background-color: $cta;
  border-radius: $spacing-1;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    color: $white;
    height: auto;
    width: auto;

    &.m-right {
      transform: rotate(180deg);
    }
  }
}

.pagination-dots {
  display: flex;
}

.pagination-dot {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $white;
    opacity: 0.4;
  }

  &:last-child {
    display: none;

    &:before {
      display: none;
    }
  }

  &.active:before {
    opacity: 1;
  }
}

.popout-table {
  background-color: $expanded-bg;
  display: none;

  .close-popout {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;

    svg {
      path {
        fill: $headers;
      }
    }
  }

  .compare-table-intro {
    padding-right: $spacing-10;
  }

  .compare-table-header {
    position: relative;
  }

  &.m-active {
    display: block;
    margin-bottom: $spacing-12;
  }

  .cta-block {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-6;

    .cta {
      text-align: center;
      font-weight: $w-body;
    }

    .call-us {
      p {
        text-align: center;
        font-weight: $w-body;
        font-size: $body-small;
        margin-top: $spacing-4;
        margin-bottom: $spacing-4;
      }
    }

    .phone {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
        color: $headers;
      }

      a {
        color: $headers;
        font-size: 3.2rem;
        font-weight: $w-display;
      }
    }

    @include media-query('tablet') {
      display: flex;
      flex-direction: row;
      grid-gap: $spacing-4;
    }

    @include media-query('tablet') {
      .cta-block {
        max-width: 100%;
        grid-gap: $spacing-6;
      }
    }

    @include media-query('desktop') {
      .cta-block {
        max-width: unset;
        grid-gap: o;
        flex-direction: column;
      }

      .phone {
        a {
          font-size: 3.2rem;
        }
      }
    }
  }
}
</style>
