<!--
  * Component Name: Appointment Selector
  * Description: The Appointment Selector allows a user to select a date and time
    from a dropdown list to receive a callback about their quote request.
  * Props:
      content:
      modal:
  * Components:
      HtmlContent,
      CtaButton,
      Text,
      InputDropdown
  * Usage:
      <appointment-selector :content="content" />
-->

<template>
  <div
    class="appointment-container"
    :content-id="props.content?.system?.id"
    :class="[
      'm-' + contentData.type,
      contentData.type === 'in_page' ? 'grid-container mx-auto pt-5 pb-10 px-4 lg:pt-10 xxl:px-6' : '',
      contentData.type === 'modal' ? 'grid-container mx-auto pt-6 px-4' : '',
      contentData.transparent_background ? 'bg-transparent' : ''
    ]"
  >
    <div
      :class="contentData.type === 'in_page' ? 'grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12' : ''"
    >
      <div
        class="appointment-wrapper"
        :class="[
          contentData.type === 'in_page' ? 'col-span-full xl:col-span-5' : '',
          contentData.type === 'in_page_full' ? 'col-span-full' : ''
        ]"
      >
        <!-- Loading -->
        <div v-if="isLoading" class="appointment-loading p-2 mb-10">
          <LoadingIcon class="appointment-spinner mb-10" />
          <h3>
            {{ contentData.loadingContent }}
          </h3>
        </div>
        <!-- Alert -->
        <div v-else-if="hasError" class="appointment-alert p-2 mb-10">
          <div class="appointment-alert-content">
            <img
              :src="helperImage.optimise(contentData.alertIcon)"
              alt="Alert Icon"
              fetchpriority="low"
              decoding="async"
              loading="lazy"
            />
            <HtmlContent v-if="contentData.alertContent" :content="contentData.alertContent" />
          </div>
        </div>
        <!-- Confirmation -->
        <div v-else-if="isComplete" class="appointment-confirmation py-10 px-4">
          <p class="appointment-confirmation-label mb-10">
            {{ contentData.confirmationTitle }}
          </p>
          <h4 class="mb-10" v-if="appointmentWindow">
            {{ dateSelect.text }} <br />
            between {{ timeSelect.text.replace('-', 'and') }}
          </h4>
          <h4 class="mb-10" v-else>{{ dateSelect.text }} @ {{ timeSelect.text }}</h4>

          <HtmlContent
            v-if="contentData.confirmationContent && isConfirmed"
            :content="contentData.confirmationContent"
          />
          <div v-else class="appoinment-confirmation-ctas">
            <CtaButton
              :variant="'tertiary'"
              :disabled="isSubmitting"
              class="mr-10"
              @click="proceed(false)"
              @keyup.enter.prevent="proceed(false)"
            >
              {{ contentData.cancelCta }}
            </CtaButton>
            <CtaButton
              :variant="'primary'"
              :loading="isSubmitting"
              :disabled="isSubmitting"
              @click="schedule()"
              @keyup.enter.prevent="schedule()"
            >
              {{ contentData.confirmCta }}
            </CtaButton>
          </div>
        </div>
        <!-- Select -->
        <div v-else class="appointment-select p-2 mb-10">
          <TrustPilot v-if="trustPilotWidget && mountClientOnly" :content="trustPilotWidget" />
          <form autocomplete="off" list="autocompleteOff" novalidate @submit.prevent="proceed()">
            <div v-if="contentData.selectTitleRT" class="mb-8" v-html="contentData.selectTitleRT" />
            <h3 v-else-if="contentData.selectTitle" class="mb-8">
              {{ contentData.selectTitle }}
            </h3>
            <p v-if="contentData.selectCopy" class="mb-8">{{ contentData.selectCopy }}</p>
            <div class="appointment-input-group">
              <InputDropdown
                v-model="dateSelect"
                :label="contentData.selectLabelDate"
                :options="dateList"
                name="date"
                :date-icon="true"
                :aria-label="contentData.selectLabelDate"
              />
            </div>
            <div class="appointment-input-group">
              <InputDropdown
                v-model="timeSelect"
                :label="contentData.selectLabelTime"
                sub-label=""
                :options="timeList"
                name="time"
                :time-icon="true"
                :aria-label="contentData.selectLabelTime"
              />
            </div>

            <div
              v-if="contentData.termsCopy"
              class="appointment-disclaimer mb-6"
              v-html="contentData.termsCopy"
            />
            <CtaButton
              type="submit"
              :variant="'primary'"
              :loading="isCallbackDisabled"
              :disabled="isCallbackDisabled"
              :emitter="true"
              @submit="schedule()"
            >
              {{ contentData.selectCtaLabel }}
            </CtaButton>
          </form>
        </div>
      </div>
      <div
        v-if="contentData.sidebar"
        class="appointment-sidebar"
        :class="
          contentData.type === 'in_page' ? 'col-span-full xl:col-span-6 xl:col-start-7 mt-10 xl:mt-0' : ''
        "
      >
        <TextContent :content="contentData.sidebar" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, watch, ref, defineAsyncComponent } from 'vue'
import { callCenterTimezone, showAppointmentTimezone } from '#config'
import { useGetContent } from '#root/components/composables/getContent.js'
import { useGlobalStore, useCustomerStore, useTrackingStore } from '#root/stores'
import { postCallMeLater, getCallcenterHours, getLeadByteQueue, putLeadByte } from '#root/api'
import { helperDateTime } from '#root/utils/helpers'
import { helperImage } from '#root/utils/helpers'
import { appTypes, trackingTypes } from '#root/utils/types'

import HtmlContent from '#root/components/utils/HtmlContent.vue'
import CtaButton from '#root/components/buttons/CtaButton.vue'
import InputDropdown from '#root/components/inputs/InputDropdown.vue'
import LoadingIcon from '#root/assets/images/icons/loading-icon.svg'

const TrustPilot = defineAsyncComponent(() => import('#root/components/pageblocks/TrustPilot.vue'))
const TextContent = defineAsyncComponent(() => import('#root/components/pageblocks/TextContent.vue'))

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  trustPilotWidget: { type: [Object, Boolean], default: () => ({}) },
  bannerContent: { type: Object, default: () => ({}) }
})

const trackingStore = useTrackingStore()
const customerStore = useCustomerStore()

const mountClientOnly = ref(false)
const isComplete = ref(false)
const isConfirmed = ref(false)
const isLoading = ref(true)
const isSubmitting = ref(false)
const hasError = ref(false)
const encryptedEntityId = ref(null)
const entityType = ref(null)
const timeZone = ref(null) // selection timezone
const dates = ref([])
const interval = ref(0)
const appointmentWindow = ref(0)
const defaultDateTime = ref(null)
const dateSelect = ref(null)
const timeSelect = ref(null)
const leadByteRetryCount = 3
const leadByteId = ref(null)

const dateList = computed(() =>
  dates.value.map((item) => ({
    text: helperDateTime.dateObj(item.startDateTime).format('dddd, Do MMMM'),
    value: item.startDateTime
  }))
)

// const configStore = useConfigStore()
// const { transactionInfo } = storeToRefs(configStore) // TODO - ADD VALUE TO STORE

onMounted(() => {
  mountClientOnly.value = true
  const { queryParams } = useGlobalStore()
  encryptedEntityId.value = queryParams?.encryptedEntityId || null
  entityType.value = queryParams?.entityType || null
  setDateList()

  // leadbyte ID will come from url when this is standalone, otherwise we need to fetch the id from the leadbyte api queue
  if (queryParams.hasOwnProperty('id')) {
    leadByteId.value = queryParams.id
  }
})

const contentData = computed(() => {
  return {
    loadingContent: useGetContent(props.content, 'loading_content.value', null),
    alertContent: useGetContent(props.content, 'alert_content.value', null),
    alertIcon: useGetContent(props.content, 'alert_icon.value[0].url', null),
    selectTitle: useGetContent(props.content, 'select_title.value', null),
    selectTitleRT: useGetContent(props.content, 'select_title_rt.value', null),
    selectCopy: useGetContent(props.content, 'select_copy.value', null),
    selectContent: useGetContent(props.content, 'select_content.value', null),
    selectLabelDate: useGetContent(props.content, 'select_label_date.value', null),
    selectLabelTime: useGetContent(props.content, 'select_label_time.value', null),
    selectCtaLabel: useGetContent(props.content, 'select_cta_label.value', null),
    confirmationTitle: useGetContent(props.content, 'confirmation_title.value', null),
    confirmationContent: useGetContent(props.content, 'confirmation_content.value', null),
    termsCopy: useGetContent(props.content, 'terms_copy.value', null),
    confirmCta: useGetContent(props.content, 'confirm_cta.value', null),
    cancelCta: useGetContent(props.content, 'cancel_cta.value', null),
    type: useGetContent(props.content, 'type.value[0].codename', null),
    submitLead: useGetContent(props.content, 'submit_lead.value[0].codename', 'call_back'),
    sidebar: useGetContent(props.content, 'sidebar_content.linkedItems[0]', null),
    transparent_background:
      useGetContent(props.content, 'transparent_background.value[0].codename', '') === 'yes' ? true : false
  }
})

const sendToLeadByte = computed(() => {
  return contentData.value.submitLead === 'leadbyte'
})

// moved to promise callback
// const proceed = (forward = true) => {
//   isComplete.value = forward
// }

const isCallbackDisabled = computed(() => {
  return isSubmitting.value
})

const schedule = () => {
  const { getCustomer, leadbyteQueueId, productCode } = useCustomerStore()
  const { transactionId } = useGlobalStore()
  const { firstName, lastName, email, phoneNumber, dateOfBirth } = getCustomer.mapToServices()
  const data = {
    firstName,
    lastName,
    email,
    dateOfBirth,
    phoneNumber,
    productCode,
    preferredCallbackTime: timeSelect.value.value,
    transactionId
  }
  if (encryptedEntityId.value) {
    data.encryptedEntityId = encryptedEntityId.value
  }
  if (entityType.value) {
    data.entityType = entityType.value
  }
  isSubmitting.value = true
  if (sendToLeadByte.value) {
    submitLeadByte(leadbyteQueueId)
  } else {
    submitCallback(data)
  }
}

const submitLeadByte = async (leadbyteQueueId) => {
  let tries = 1
  let queue = null
  // go get the lead ID from the queue if we don't have it
  if (!leadByteId.value) {
    while (tries <= leadByteRetryCount && !leadByteId.value) {
      queue = await getLeadByteQueue(leadbyteQueueId)
      const record = queue.records.find((record) => {
        return record.queueId === leadbyteQueueId
      })
      leadByteId.value = record.leadId
      // sleep for 1 second if we don't find a leadId which means the queue is still pending
      if (!leadByteId.value) {
        await helperUtils.sleep(1000)
      }
      tries++
    }
    // if we still don't have a lead ID, error
    if (!leadByteId.value) {
      hasError.value = true
      return
    }
  }

  // update existing leadbyte lead
  putLeadByte({
    id: leadByteId.value,
    update: {
      // leadbyte always uses callCenterTimezone since it uses the data lead API (not the web lead API)
      // AND we need to remove the timezone offset because it's already assumed!
      preferredcontact: helperDateTime
        .dateObj(timeSelect.value.value, callCenterTimezone)
        .format('YYYY-MM-DDTHH:mm:ss')
    }
  })
    .then(() => {
      trackingStore.trackCallback({ label: trackingTypes.callback.Submitted })
      isComplete.value = true
      isConfirmed.value = true
    })
    .catch(() => {
      hasError.value = true
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

const submitCallback = (data) => {
  postCallMeLater({ data })
    .then(() => {
      trackingStore.trackCallback({ label: trackingTypes.callback.Submitted })
      isComplete.value = true
      isConfirmed.value = true
    })
    .catch(() => {
      hasError.value = true
    })
    .finally(() => {
      isSubmitting.value = false
    })
}

const setDateList = async () => {
  const { getCustomer } = useCustomerStore()
  const daysToSchedule = 5
  isLoading.value = true

  try {
    const payload = {
      daysToSchedule
    }
    if (getCustomer.phone) {
      payload.phoneNumber = getCustomer.phone
    }
    if (getCustomer.provinceId) {
      payload.provinceId = getCustomer.provinceId
    }
    if (getCustomer.provinceId) {
      payload.provinceId = getCustomer.provinceId
    }
    if (encryptedEntityId.value) {
      payload.encryptedEntityId = encryptedEntityId.value
    }
    if (entityType.value) {
      payload.entityType = entityType.value
    }
    const data = await getCallcenterHours(payload)

    // ------------------------------- dev only -------------------------------
    // const data = {
    //   appointmentWindow: 15,
    //   dates: [
    //     {
    //       startDateTime: '2024-08-06T21:30:00-04:00',
    //       endDateTime: '2024-08-06T23:30:00-04:00'
    //     },
    //     {
    //       startDateTime: '2024-08-07T00:00:00+01:00',
    //       endDateTime: '2024-08-07T23:30:00+01:00'
    //     },
    //     {
    //       startDateTime: '2024-08-08T00:00:00+01:00',
    //       endDateTime: '2024-08-08T23:30:00+01:00'
    //     },
    //     {
    //       startDateTime: '2024-08-09T00:00:00+01:00',
    //       endDateTime: '2024-08-09T23:30:00+01:00'
    //     },
    //     {
    //       startDateTime: '2024-08-10T09:00:00+01:00',
    //       endDateTime: '2024-08-10T17:45:00+01:00'
    //     }
    //   ],
    //   defaultDateTime: '2024-08-06T21:45:00+01:00',
    //   interval: 15,
    //   timezoneAbbrev: 'GMT',
    //   timezoneValue: 'GMT Standard Time'
    // }

    dates.value = data.dates
    interval.value = data.interval
    appointmentWindow.value = data.appointmentWindow

    // manually remove timezone offset from data as we will no longer do timezone conversion on the ui
    defaultDateTime.value = data.defaultDateTime.slice(0, -6)
    let timezoneOffset = ''
    data.dates.forEach((dateData, index) => {
      if (index === 0) {
        timezoneOffset = dateData.startDateTime.slice(-6)
      }

      data.dates[index].startDateTime = dateData.startDateTime.slice(0, -6)
      data.dates[index].endDateTime = dateData.endDateTime.slice(0, -6)
    })

    timeZone.value = {
      text: data.timezoneValue,
      code: data.timezoneAbbrev,
      offset: timezoneOffset
    }

    dateSelect.value = dateList.value[0]
  } catch (e) {
    hasError.value = true
  } finally {
    isLoading.value = false
  }
}

const timeList = computed(() => {
  const list = []
  const dateItem = dateSelect.value
    ? dates.value.find((item) => item.startDateTime === dateSelect.value.value)
    : null

  if (dateItem) {
    const displayTz = showAppointmentTimezone ? timeZone.value.code : ''
    const defDateTime = helperDateTime.dateObj(defaultDateTime.value)
    const endDateTime = helperDateTime.dateObj(dateItem.endDateTime)
    let dateTime = helperDateTime.dateObj(dateItem.startDateTime)

    while (dateTime.isSameOrBefore(endDateTime)) {
      if (dateTime.isSameOrAfter(defDateTime)) {
        const appointmentStart = dateTime.format('hh:mma')
        const appointmentEnd = dateTime.add(appointmentWindow.value, 'minute').format('hh:mma')
        list.push({
          text: `${appointmentStart} ${appointmentWindow.value ? `- ${appointmentEnd}` : ''} ${displayTz}`,
          value: dateTime.format('YYYY-MM-DDTHH:mm:ss') + timeZone.value.offset
        })
      }
      dateTime = dateTime.add(interval.value, 'minute')
    }
  }

  return list
})

watch(dateSelect, () => {
  hasError.value = false
})

watch(timeList, (list) => {
  if (list.length > 0) {
    timeSelect.value = list[0]
  }
})
</script>

<style lang="scss">
.appointment-container.m-in_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .appointment-select {
    background: $gs-200;
    padding: $spacing-6 $spacing-4;
    border-radius: $spacing-2;
  }

  @include media-query('tablet') {
    flex-direction: row;
    justify-content: space-between;
  }
}

.appointment-loading {
  max-width: 100%;
  background: $gs-100;
  min-height: 442px;
  border-radius: $spacing-2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-query('tablet') {
    max-width: 548px;
    margin: auto;
    width: 100%;
  }

  svg {
    path {
      fill: $primary;
    }
  }

  @include media-query('desktop') {
    max-width: 504px;
    width: 100%;
  }

  h3 {
    text-align: center;
  }
}

.appointment-spinner {
  width: 96px;
  height: 96px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.appointment-alert {
  max-width: 100%;
  background: $gs-100;
  min-height: 442px;
  border-radius: $spacing-2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @include media-query('tablet') {
    max-width: 548px;
    margin: auto;
    width: 100%;
  }

  @include media-query('desktop') {
    max-width: 504px;
    width: 100%;
  }

  .appointment-alert-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include headings {
      font-size: 2.8rem;
      line-height: 33px;
      margin: $spacing-10 0;
    }
  }

  svg {
    min-width: 32px;
    min-height: 32px;
  }

  p {
    font-weight: $w-body;
    font-size: $body-large;
    line-height: 32px;
  }
}

.appointment-select {
  max-width: 100%;
  background: $gs-100;
  min-height: 442px;
  border-radius: $spacing-2;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  @include media-query('tablet') {
    max-width: 548px;
    margin: auto;
    width: 100%;
  }

  @include media-query('desktop') {
    max-width: 504px;
    width: 100%;
  }

  h1,
  h2,
  h3 {
    color: $headers;
    font-size: $h3-d;
  }

  p {
    font-weight: $w-body;
    font-size: $legal-text;
    line-height: 24px;
  }
}

.appointment-input-group {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;

  .icon {
    position: absolute;
    width: auto;
    height: auto;
    padding: 0 $spacing-3;
    top: 40px;
    right: 0;
  }

  label {
    color: $headers;
    font-weight: $w-subheading;
  }
}

.appointment-confirmation {
  max-width: 100%;
  background: $gs-200;
  border-radius: $spacing-4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-query('tablet') {
    max-width: 548px;
    margin: auto;
    width: 100%;
  }

  @include media-query('desktop') {
    max-width: 504px;
    width: 100%;
  }

  .appointment-confirmation-label {
    font-weight: $w-body;
    font-size: $body-large;
    line-height: 32px;
  }

  h2,
  h3,
  h4 {
    color: $headers;
    text-align: center;
    font-size: $h4-d;
    line-height: 38px;
  }

  p {
    color: $core-font;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }
}

.appoinment-confirmation-ctas {
  display: flex;
  justify-content: center;
}

.appointment-disclaimer {
  p {
    font-size: $legal-text;
    font-weight: $w-light;
    color: $tertiary;
  }
}

.bg-transparent {
  .appointment-loading,
  .appointment-alert,
  .appointment-confirmation,
  .appointment-select {
    background: transparent;
  }
}
</style>
