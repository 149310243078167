<!--
  * Component Name: TextContent
  * Description: Component to handle Text
  * Props:
      addClass, content
  * Components:
      Link
  * Usage:
      <text-content :addClass="value" :content="value" />
-->

<template>
  <div class="text-container" :class="[addClass]" :content-id="props.content?.system?.id">
    <HtmlContent
      v-if="contentData.title && props.showTitle"
      class="text-title md:mt-2 xl:mt-0"
      :content="contentData.title"
    />
    <HtmlContent
      v-if="contentData.content"
      class="text-content"
      :class="[
        { 'numbered-list-alt-p': contentData.numberedListIndent === true },
        contentData.tickList,
        contentData.layoutClass
      ]"
      :content="contentData.content"
      :nofollow="contentData.nofollow"
    />

    <div class="links">
      <div v-for="cta in contentData.cta" :key="cta.system.id">
        <!-- ScrollTo disabled -->
        <LinkItem
          v-if="cta && !scrollTo && !contentData.externalUrl"
          :key="cta.system.id"
          :content="cta"
          :add-class="'mt-6'"
          :in-content="true"
        />

        <!-- ScrollTo enabled -->
        <div
          v-if="cta && scrollTo && !contentData.externalUrl"
          class="inline-flex"
          @click.stop.prevent="scrollToElement('quote-container', 94)"
          @keyup.enter.prevent="scrollToElement('quote-container', 94)"
          role="button"
          tabIndex="0"
        >
          <LinkItem :key="cta.system.id" :content="cta" :add-class="'mt-6'" :in-content="true" />
        </div>

        <!-- if External Link -->
        <LinkItem
          v-if="cta && !scrollTo && contentData.externalUrl"
          :key="cta.system.id"
          :content="cta"
          :add-class="'mt-6 cta-btn m-secondary'"
          :in-content="true"
          :nofollow="contentData.nofollow"
        />

        <div v-if="contentData.popoutButton" class="inline-flex">
          <LinkItem
            :key="cta.system.id"
            :content="contentData.popoutButton"
            :add-class="'mt-6'"
            :in-content="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

import HtmlContent from '#root/components/utils/HtmlContent.vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  addClass: { type: String, required: false, default: '' },
  scrollTo: { type: Boolean, required: false, default: false },
  openNewWindow: { type: Boolean, required: false, default: false },
  showTitle: { type: Boolean, required: false, default: true }
})

const contentData = computed(() => {
  const layout = useGetContent(props.content, 'layout_options.value', null)
  const option = layout.length ? layout[0] : null
  const layoutClass = option ? (option.codename === 'narrow_container' ? 'narrow-container' : null) : null
  return {
    content: useGetContent(props.content, 'content.value').replace(/http:\/\/tel:/g, 'tel:'),
    title: useGetContent(props.content, 'title.value', null),
    externalUrl: useGetContent(props.content, 'cta_button.linkedItems[0].elements.external_link.value', ''),
    nofollow: useGetContent(props.content, 'no_follow_link.value[0].name', '') ? 'nofollow' : '',
    tickList: useGetContent(props.content, 'tick_list.value[0].codename'),
    layoutClass,
    cta: useGetContent(props.content, 'cta_button.linkedItems', []),
    popoutButton: useGetContent(props.content, 'popout_button.linkedItems[0]', null),
    openModal: useGetContent(
      props.content,
      'cta_button.linkedItems[0].elements.open_modal_window.value[0].codename',
      null
    )
  }
})

const scrollTo = computed(() => {
  return useGetContent(props.content, 'scroll_to.value[0].codename', '') === 'yes' ? true : false
})

const scrollToElement = async (scrollToElement, offset) => {
  const element = document.querySelector('.' + scrollToElement)

  if (element) {
    const headerOffset = offset
    const elementPosition = element.getBoundingClientRect().top + window.scrollY
    const offsetPosition = elementPosition - headerOffset

    await nextTick
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.indexOf('Edg') !== -1) {
      window.scrollTo(0, offsetPosition)
    } else {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
.text-container {
  .cta-btn {
    margin-right: $spacing-6;
  }

  &.m-bold {
    a,
    p,
    ul {
      font-weight: $w-subheading;
    }

    h4 {
      font-size: $body-large;
    }
  }

  &.m-small-title {
    h4 {
      font-size: $body-large;
      line-height: 32px;
      font-weight: $w-subheading;
    }
  }

  &.m-large {
    a,
    p,
    ul {
      font-size: $body-large;
      line-height: 32px;
    }
  }

  .cta-btn.m-tertiary {
    text-align: left;
    padding: 0;
    height: auto;
    min-width: auto;
  }

  .external-link {
    font-weight: $w-display;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  [href^='tel:'] {
    @include phone-standout();
  }
}

.text-title {
  @include pageblock-header();
  margin: 0 0 $spacing-6;

  h2,
  h3 {
    strong {
      color: $primary-cta;
      font-weight: $w-heading;
    }
  }

  [id] {
    scroll-margin-top: 240px;
  }
}

.text-content {
  width: 100%;
  font-size: $body-regular;
  line-height: 3rem;
  color: $core-font;

  &.narrow-container {
    max-width: 920px;

    p,
    ul,
    ol {
      font-weight: $w-light;
    }

    strong {
      font-weight: $w-subheading;
    }
  }

  h2 {
    color: $headers;
    margin: 0 0 $spacing-2 0;
  }

  h3 {
    color: $headers;
    margin-top: $spacing-10;
  }

  h4 {
    color: $headers;
    font-size: $h4-d;
    line-height: 32px;
    margin-bottom: $spacing-2;
  }

  h5 {
    color: $headers;
    font-size: $body-large;
    line-height: 24px;
    margin-bottom: $spacing-4;
    margin-top: $spacing-8;

    &:first-child {
      margin-top: 0px;
    }
  }

  a,
  .optanon-toggle-display {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  em {
    font-weight: $w-subheading;
    font-style: normal;
  }

  ul {
    margin: $spacing-6 0 $spacing-6 $spacing-2;

    li {
      margin-bottom: $spacing-4;
      position: relative;
      padding-left: $spacing-4;
    }

    li:before {
      content: '';
      display: inline-block;
      position: absolute;
      background-color: $core-font;
      border-radius: 50px;
      left: 0px;
      top: 14px;
      height: 4px;
      width: 4px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    counter-reset: my-counter;
    list-style: none;
    margin: $spacing-6 0 $spacing-6 0;

    li {
      counter-increment: my-counter;
      position: relative;
      margin-bottom: $spacing-4;
      padding-left: $spacing-8;
    }

    li:before {
      content: counter(my-counter) '.';
      width: 32px;
      height: 32px;
      display: inline-block;
      border-radius: 50%;
      color: $core-font;
      text-align: center;
      margin-right: $spacing-4;
      position: absolute;
      left: 0px;
    }
  }

  table {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
    border-collapse: collapse;
    table-layout: fixed;

    td {
      border: solid 1px $gs-300;
      padding: $spacing-1;
      font-size: $legal-text;
      text-align: center;

      @include media-query('tablet') {
        font-size: $body-small;
      }

      p {
        font-size: $legal-text;

        @include media-query('tablet') {
          font-size: $body-small;
        }
      }
    }
  }
}

.text-content.m_ticks {
  p + ul {
    margin-top: $spacing-6;
  }

  ul {
    margin: 0 0 0 $spacing-9;

    li {
      font-weight: $w-body;
      font-size: $body-regular;
      padding-left: 0%;
    }

    li:before {
      @include ticks-plain();
      left: -34px;
      top: 5px;
      height: 24px;
      width: 24px;
      border-radius: 0;
      background-color: transparent;
      background-repeat: no-repeat;
    }
  }
}

.text-content.m_ticks_circle {
  p + ul {
    margin-top: $spacing-6;
  }

  ul {
    margin: 0 0 0 $spacing-9;

    li {
      font-weight: $w-body;
      font-size: $body-small;
      padding-left: 0;

      &:last-child {
        margin-bottom: $spacing-6;
      }
    }

    li:before {
      @include ticks-circle();
      left: -34px;
      top: 5px;
      height: 24px;
      width: 24px;
      border-radius: 0;
      background-color: transparent;
      background-repeat: no-repeat;
    }
  }
}
</style>
