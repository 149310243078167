import { ref, onBeforeUnmount, nextTick } from 'vue'
import { useTrackingStore } from '#root/stores'

export const useTrackFormErrors = (formType = '', target) => {
  const { trackFormError } = useTrackingStore()
  const trackErrorEvent = (fieldName, errorLog, formType) => {
    trackFormError(fieldName, errorLog, formType)
  }
  const observer = ref(null)
  let targetNode = document.getElementById(target)
  let config = { subtree: true, childList: true, attributes: false }
  const callback = (mutationList) => {
    nextTick(() => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (mutation.addedNodes[0]?.classList?.contains('form-error')) {
            const errorMessage = mutation.addedNodes[0]?.innerText || 'Unknown Error'
            const formField =
              mutation.addedNodes[0]?.offsetParent?.querySelector('label')?.innerText || 'Unknown Field'
            trackErrorEvent(formField, errorMessage, formType)
          }
        }
      }
    })
  }
  observer.value = new MutationObserver(callback)
  observer.value.observe(targetNode, config)

  onBeforeUnmount(() => {
    if (observer.value) {
      observer.value.disconnect()
    }
  })
}
