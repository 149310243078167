<!--
  * Component Name: Banner Content
  * Description: Base content for banner
  * Props:
      Content
  * Components:
  * Usage:
      <Component :content="value" />
-->

<template>
  <div v-if="trustPilotWidget" class="placeholder-for-trust">
    <ClientOnly>
      <TrustPilot :content="trustPilotWidget" />
    </ClientOnly>
  </div>
  <HtmlContent v-if="content.title" :content="content.title" class="title" />
  <HtmlContent v-if="content.byline" :content="content.byline" class="byline" />
  <HtmlContent v-if="content.content" :content="content.content" class="content" />
  <div class="links" :class="content.byline ? '' : 'no-byline'">
    <LinkItem v-for="cta in content.ctas" :key="cta.system.id" :content="cta" :gtm="props.gtm" />
  </div>
</template>

<script setup>
import { defineAsyncComponent } from 'vue'

import HtmlContent from '#root/components/utils/HtmlContent.vue'
const TrustPilot = defineAsyncComponent(() => import('#root/components/pageblocks/TrustPilot.vue'))
import LinkItem from '#root/components/buttons/LinkItem.vue'
import ClientOnly from '#root/components/utils/ClientOnly.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  trustPilotWidget: { type: Object, default: () => ({}) },
  gtm: { type: String, required: false, default: '' }
})
</script>

<style lang="scss">
.placeholder-for-trust {
  height: 20px;
  overflow: hidden;
}

.banner-content {
  display: flex;
  flex-direction: column;
  padding: $spacing-10 $spacing-4 $spacing-6;
  gap: $spacing-4;

  .placeholder-for-trust {
    order: 0;
  }

  .title {
    order: 1;

    h1 {
      strong {
        color: $primary-cta;
      }
    }
  }

  .byline {
    order: 1;

    p {
      font-weight: $w-subheading;
    }

    strong {
      font-size: $body-large;
    }
  }

  .content {
    order: 3;

    p {
      font-weight: $w-body;

      a {
        text-decoration: underline;
      }
    }

    ul {
      margin: 0 0 0 $spacing-8;

      li {
        font-weight: $w-heading;
        font-size: $body-regular;
        margin-bottom: $spacing-4;
        position: relative;
        padding-left: 0;
      }

      li:last-child {
        margin-bottom: 0;
      }

      li:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: -34px;
        top: 1px;
        height: 28px;
        width: 28px;
        border-radius: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-image: url('/assets/images/icons/tick-list-icon.svg');
        background-position: center center;
      }
    }
  }

  .links {
    order: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: $spacing-4;

    &.no-byline {
      order: 3;
    }
  }
}

@include media-query('phablet') {
  .banner-content {
    max-width: $g-tablet-breakpoint-min-width;
    margin: auto;
    width: 100%;

    .links {
      flex-direction: row;
    }
  }
}

@include media-query('desktop') {
  .banner-content {
    padding: $spacing-10 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .content {
      order: 2;
      margin: 0;

      p {
        font-weight: $w-body;
        margin: 0;
      }
    }

    .links {
      margin: $spacing-2 0 0;
      order: 3;
      gap: $spacing-6;
    }
  }
}
</style>
