<!--
  * Component Name: ArticleCard
  * Description: Global card, for latest blog, press, and blog purposes
  * Props:
      content, position, nofollow
  * Components:
      CtaButton
  * Usage:
      <article-card
        :content="value"
        :position="index + 1"
        :nofollow="nofollow"
      />
-->

<template>
  <div class="card" :content-id="props.content?.system?.id">
    <div class="card-image-container">
      <div ref="imageRef" class="card-image-inner">
        <div v-if="contentData.cardHighlight === 'yes'" class="highlight">
          <play-icon class="play-icon" />
        </div>
      </div>
    </div>
    <div class="card-content-container">
      <div class="card-title-container">
        <span v-if="contentData.cardTitle" class="card-title" v-html="contentData.cardTitle" />
      </div>
      <CtaButton variant="tertiary" :size="'normal'" :right-arrow="true" tabindex="-1">
        {{ contentData.cardLinkTitle }}
      </CtaButton>
      <a
        class="card-link"
        :href="contentData.cardUrl"
        :data-gtm="`${gtm.articleCTA} ${contentData.cardTitle.toLowerCase()} pos=${position}`"
        :nofollow="nofollow"
      >
        {{ contentData.cardTitle }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUpdated, onUnmounted, ref } from 'vue'
import { helperImage } from '#root/utils/helpers'
import { useGetContent, useGetContentOptions } from '#root/components/composables/getContent.js'

import CtaButton from '#root/components/buttons/CtaButton.vue'
import PlayIcon from '#root/assets/images/icons/play-icon.svg'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  position: { type: Number, default: null },
  nofollow: { type: String, required: false, default: '' }
})

const contentData = computed(() => {
  const basicInfo =
    props.content.system.type === 'article_model'
      ? props.content.elements.basic_info.linkedItems[0]
      : props.content
  return {
    cardHighlight: useGetContent(basicInfo, 'highlight.value[0].codename', null),
    cardTitle: useGetContent(basicInfo, 'title.value', ''),
    cardThumbImage: helperImage.optimise(useGetContent(basicInfo, 'thumbnail_image.value[0].url', null)),
    cardImage: helperImage.optimise(useGetContent(basicInfo, 'featured_image.value[0].url', null), 0, {
      w: 600
    }),
    cardLinkTitle: useGetContent(basicInfo, 'article_link.linkedItems[0].elements.link_text.value', null),
    cardUrl: useGetContent(
      basicInfo,
      'article_link.linkedItems[0].elements.site_link.linkedItems[0].elements.url.value',
      null
    )
  }
})

const gtm = computed(() => {
  return {
    articleCTA: useGetContentOptions('article_cta.value', '')
  }
})

const imageRef = ref(null)
let observer = null

const loadImage = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      imageRef.value.style.backgroundImage = `url(${
        contentData.value.cardThumbImage ? contentData.value.cardThumbImage : contentData.value.cardImage
      })`
      observer.unobserve(imageRef.value)
      observer.disconnect()
    }
  })
}

onMounted(() => {
  observer = new IntersectionObserver(loadImage, {
    root: null,
    threshold: 0.1
  })
  observer.observe(imageRef.value)
})

onUpdated(() => {
  observer.observe(imageRef.value)
})

onUnmounted(() => {
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style lang="scss">
.card {
  @include flex-parent(column, nowrap, flex-start, stretch);
  border-radius: $card-radius;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  text-decoration: none;
  transition: transform 0.3s ease;
  position: relative;
  box-shadow: $g-box-shadow;

  @include media-query('phablet') {
    @include flex-parent(row, nowrap, flex-start, stretch);
  }

  @include media-query('desktop') {
    @include flex-parent(column, nowrap, flex-start, stretch);
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    cursor: pointer;

    .highlight {
      transform: scale(0.94);
    }

    .card-image-inner {
      transform: scale(1.1);
      transition: transform 0.4s ease-in-out;
    }

    .card-content-container {
      background-color: $background-accent;
    }
  }

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }

  &:visited {
    text-decoration: none;
  }

  .card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
  }
}

.highlight {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: $spacing-4;
  transition: all 0.3s ease-in-out;

  .play-icon {
    width: 40px;
    height: 40px;
  }
}

.card-image-container {
  width: 100%;
  height: 245px;
  overflow: hidden;
  flex-shrink: 0;

  @include media-query('phablet') {
    height: 274px;
    max-width: 264px;
  }

  @include media-query('tablet') {
    height: 274px;
    max-width: 384px;
  }

  @include media-query('desktop') {
    height: 285px;
    max-width: 100%;
  }
}

.card-image-inner {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.3s ease-in-out;

  @include media-query('desktop') {
    max-width: 100%;
  }
}

.card-title-container {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacing-6;
}

.card-content-container {
  background-color: $white;
  padding: $spacing-6;
  max-width: 100%;
  @include flex-parent(column, nowrap, space-between, flex-start);
  flex: 1 1 auto;
}

.card-title {
  display: block;
  color: $headers;
  font-size: $body-large;
  line-height: 2.4rem;
  font-weight: $w-heading;
  margin: 0;
  max-width: 100%;
}
</style>
