<!--
  * Component Name: Porgress Bar
  * Description: Progress Bar component for multi step form
  * Props:
      steps, content
  * Components:
  * Usage:
      <ProgressBar :content="content" :steps="steps" />
-->
<template>
  <div class="progress-bar-container" :content-id="props.content?.system?.id">
    <div class="progress-bar-top">
      <span v-if="contentData.title" class="title">{{ contentData.title }}</span>
      <span class="percent">{{ `${progress}%` }}</span>
    </div>
    <div class="progress-bar-outer">
      <div class="progress-bar-inner" :style="{ width: `${progress}%` }"></div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import { storeToRefs } from 'pinia'
import { useFormStore } from '#root/stores'

const props = defineProps({
  content: {
    type: Object,
    default: null
  },
  steps: {
    type: Number,
    default: 0
  }
})
const formStore = useFormStore()
const { activeStep } = storeToRefs(formStore)

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'details.value', null)
  }
})

const progress = computed(() => {
  const steps = props.steps
  const width = ((activeStep.value.index / steps) * 100).toFixed(0)
  return width > 100 ? 100 : width
})
</script>

<style lang="scss">
.progress-bar-container {
  width: 100%;
  margin: 0 0 $spacing-4;

  + form {
    .form-step {
      h2 {
        padding-top: 0;
      }
    }
  }

  .progress-bar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title,
    .percent {
      font-size: $legal-text;
      margin: 0 0 $spacing-2 !important;
    }
  }

  .progress-bar-outer {
    background: $gs-300;
    border-radius: 100px;
    height: 8px;
    overflow: hidden;
  }

  .progress-bar-inner {
    position: relative;
    background: $postive-green;
    border-radius: 100px;
    height: 8px;
    width: 12px;
    min-width: 2%;
    transition: all 0.5s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 8px;
      width: 12px;
      background: $postive-green;
      max-width: 100%;
      border-radius: 100px;
    }
  }

  @include media-query('desktop') {
    width: 100%;
  }
}
</style>
