<!--
  * Component Name: Tabbed Panel
  * Description: Tabbed Panel with title + Icon, Content and cta
  * Props:
      Content
  * Components:
      HtmlContent, LinkItem
  * Usage:
      <TabbedPanel :content="value" />
-->

<template>
  <div class="tabbed-panel-container py-10" :content-id="props.content?.system?.id">
    <div class="grid-container grid grid-cols-1 xl:grid-cols-2 mx-auto py-10 px-4 xxl:px-6">
      <div class="col-span-full">
        <div class="tabs-wrapper">
          <div
            v-for="(tab, tabIndex) in contentData.tabItems"
            :key="tabIndex"
            class="tab-item"
            :class="{ active: selectedTab === tabIndex }"
            tabindex="0"
            :data-gtm="`${gtmData.tabbedContentCta}${tabIndex + 1}`"
            @click="selectedTab = tabIndex"
            @keyup.enter.prevent="selectedTab = tabIndex"
            role="button"
          >
            <img
              v-if="tab.elements.icon.value.length"
              :src="helperImage.optimise(tab.elements.icon.value[0])"
              :alt="tab.elements.icon.value[0]?.description || 'Icon'"
              class="tab-icon"
              fetchpriority="low"
              decoding="async"
              loading="lazy"
            />
            <span class="tab-title m-desktop">
              {{ tab.elements.title_desktop.value }}
            </span>
            <span class="tab-title m-tablet">{{ tab.elements.title_tablet.value }}</span>
            <span class="tab-title m-mobile">{{ tab.elements.title_mobile.value }}</span>
          </div>
        </div>
        <div
          v-for="(tabContent, contentIndex) in contentData.tabItems"
          :key="contentIndex"
          class="tab-item-content py-10 px-8"
          :class="{ active: selectedTab === contentIndex }"
        >
          <!-- {{ tabContent }} -->
          <HtmlContent class="tab-item-title mb-4" :content="tabContent.elements.content_title.value" />
          <HtmlContent class="tab-item-copy mb-4" :content="tabContent.elements.content.value" />
          <LinkItem
            v-if="tabContent.elements.cta.linkedItems.length"
            class="mt-4"
            :content="tabContent.elements.cta.linkedItems[0]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import { gtmData } from '#root/utils/types'
import { helperImage } from '#root/utils/helpers'
import HtmlContent from '#root/components/utils/HtmlContent.vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const selectedTab = ref(0)
const contentData = computed(() => {
  return {
    tabItems: useGetContent(props.content, 'tab_items.linkedItems', null)
  }
})
</script>

<style lang="scss">
.tabs-wrapper {
  @include flex-parent(row, nowrap);
}

.tab-item {
  @include flex-parent();
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: $spacing-4 $spacing-2;
  background-color: $gs-200;
  flex: 1 0 auto;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: $spacing-2;
  }

  &.active {
    background-color: $secondary-banner;
  }

  .tab-icon {
    height: 60px;
    width: 60px;
    padding-bottom: $spacing-4;
  }

  .tab-title {
    display: none;
    font-size: $h4-d;
    font-weight: $w-display;
    line-height: 29px;
  }

  .tab-title.m-mobile {
    display: block;
  }

  @include media-query('phablet') {
    &:not(:last-child) {
      margin-right: $spacing-4;
    }

    .tab-title.m-mobile {
      display: none;
    }
    .tab-title.m-tablet {
      display: block;
    }
  }

  @include media-query('desktop') {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: $spacing-5 $spacing-6;
    flex: 1 0 30%;

    .tab-icon {
      height: 80px;
      width: 80px;
      padding-bottom: $spacing-0;
      margin-right: $spacing-4;
    }

    .tab-title.m-tablet {
      display: none;
    }

    .tab-title.m-desktop {
      display: block;
    }
  }

  @include media-query('desktop-large') {
    padding: $spacing-5 $spacing-8;
  }
}

.tab-item-content {
  display: none;
  background-color: $secondary-banner;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  .tab-item-copy h5 {
    margin-bottom: $spacing-4;
  }

  &.active {
    display: block;
  }
}
</style>
