<!--
  * Component Name: BreadCrumb
  * Description: BreadCrumb component
  * Usage:
      <BreadCrumb/>
-->
<template>
  <div class="breadcrumb-container">
    <div class="breadcrumbs grid-container mx-auto px-4 py-2 xxl:px-6">
      <a href="/" alt="Return to Home" aria-label="Return to Home">
        <HomeIcon class="breadcrumb-home-icon" />
      </a>
      <ChevronIcon class="breadcrumb-chevron-icon" />
      <a
        :href="parentPage.parentPageUrl"
        class="breadcrumbs-home-link"
        :data-gtm="gtm.breadcrumbs"
        :rel="nofollow"
      >
        {{ parentPage.parentPageTitle }}
      </a>
      <ul class="breadcrumbs-items">
        <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
          <ChevronIcon class="breadcrumb-chevron-icon" />
          <span class="breadcrumbs-link">{{ breadcrumb.elements.title.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContentPage, useGetContentOptions } from '#root/components/composables/getContent'
import HomeIcon from '#root/assets/images/icons/house-icon.svg'
import ChevronIcon from '#root/assets/images/icons/breadcrumb-icon.svg'

const props = defineProps({
  nofollow: {
    type: String,
    default: ''
  }
})

const breadcrumbs = computed(() => {
  return useGetContentPage('breadcrumb.linkedItems', [])
})

const gtm = computed(() => {
  return {
    breadcrumbs: useGetContentOptions('breadcrumbs_tracking.value', '')
  }
})

const parentPage = computed(() => {
  const parent = breadcrumbs.value.length
    ? breadcrumbs.value[0].elements.parent.linkedItems.length
      ? breadcrumbs.value[0].elements.parent.linkedItems[0].elements
      : null
    : null
  return {
    parentPageTitle: parent ? parent.title.value : 'Home',
    parentPageUrl: parent ? parent.url.value : '/'
  }
})
</script>

<style lang="scss">
.breadcrumb-container {
  background: $breadcrumb-bg;
  border-bottom: 1px solid $breadcrumb-stroke;

  .breadcrumbs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: $body-small;
    line-height: 2.8rem;
    overflow-x: auto;

    .breadcrumb-home-icon {
      fill: $breadcrumb-font;
      margin: 0 0 6px;

      path {
        fill: $breadcrumb-font;
      }
    }

    .breadcrumbs-home-link {
      white-space: nowrap;
    }

    .breadcrumb-chevron-icon {
      margin: 0 $spacing-2;
      transform: rotate(0deg);
      fill: $breadcrumb-icon;

      path {
        fill: $breadcrumb-icon;
      }
    }

    .breadcrumbs-items {
      white-space: nowrap;

      li {
        display: flex;
        align-items: center;
      }
    }
  }

  .breadcrumbs-link {
    color: $breadcrumb-font;
  }
}
</style>
