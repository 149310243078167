<!--
  * Component Name: Multi Step Form
  * Description: Multi Step Form component, dynamic steps and step count. Type banner or quote
  * Props:
      wrapperClass, content
  * Components:
       InputRadioSelect, InputText, InputLabel, CtaButton, GenericModal, HtmlContent
  * Usage:
      <MultiStepForm :content="content" :wrapper-class="'m-focused'" />
-->
<template>
  <div :class="[wrapperClass]" :content-id="props.content?.system?.id">
    <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <div :class="['col-span-full', formPosition, formBackground]">
        <div v-if="contentData.trustPilot.length" class="placeholder-for-trust mb-4">
          <ClientOnly>
            <TrustPilot :content="contentData.trustPilot[0]" />
          </ClientOnly>
        </div>
        <ProgressBar
          v-if="
            contentData.progressBar &&
            activeStep.index >= progressRange.start &&
            activeStep.index <= progressRange.end
          "
          :content="contentData.progressBar"
          :steps="numberOfSteps"
        />
        <form id="multi-form-wrapper" autocomplete="off" list="autocompleteOff" novalidate>
          <div class="form-steps">
            <div
              v-for="(step, i) in contentData.formSteps"
              :key="i"
              :class="['form-step', step.system.type, i === activeStep.index ? 'active-step' : '']"
            >
              <Transition mode="out-in" :name="wrapperClass.includes('funnel') ? 'fade' : ''">
                <div v-if="activeStep.index === i">
                  <span v-if="stepContentData.titleRT" class="step-title" v-html="stepContentData.titleRT" />
                  <h2 v-else-if="stepContentData.title" class="step-title">{{ stepContentData.title }}</h2>

                  <span
                    v-if="stepContentData.contentRT"
                    class="step-content"
                    v-html="stepContentData.contentRT"
                  />
                  <p v-else-if="stepContentData.content" class="step-content">
                    {{ stepContentData.content }}
                  </p>
                  <div v-if="stepContentData">
                    <!-- <StepIntro
                      v-if="
                        activeStep.index === i &&
                        step.system.type === 'form_step___intro_outro' &&
                        contentData.formType[0].codename === 'banner'
                      "
                      :content="step"
                      @proceed="stepOrSubmitFunc(true)"
                    /> -->
                    <div
                      v-if="hasPart.name"
                      :class="['inline-input-group', wrapperClass.includes('funnel') ? 'stacked' : '']"
                    >
                      <InputText
                        input-type="text"
                        name="firstName"
                        provider-name="firstName"
                        v-model="firstName"
                        :label="stepContentData.fNameLabel"
                        :label-class="contentData.labelSize"
                        :pattern="inputPatterns.name"
                        :blur-on-event="onInputBlur"
                        :disabled-error="contentData.nameValidationType === 'combined'"
                        :validation-check-icon="contentData.nameValidationType !== 'combined'"
                        :aria-label="stepContentData.fNameLabel"
                        :error="errors.firstName"
                        :is-validated="!errors.firstName && firstName?.length >= 2"
                        :wrapper-class="nameWrapperClasses.fname"
                      />
                      <InputText
                        v-model="lastName"
                        name="lastName"
                        input-type="text"
                        provider-name="lastName"
                        :label="stepContentData.lNameLabel"
                        :label-class="contentData.labelSize"
                        :wrapper-class="nameWrapperClasses.lname"
                        :pattern="inputPatterns.name"
                        :blur-on-event="onInputBlur"
                        :disabled-error="contentData.nameValidationType === 'combined'"
                        :aria-label="stepContentData.lNameLabel"
                        :error="errors.lastName"
                        :is-validated="!errors.lastName && lastName?.length >= 2"
                        :validation-check-icon="contentData.nameValidationType !== 'combined'"
                        :name-validation-type="contentData.nameValidationType === 'combined'"
                      />
                      <small
                        v-if="
                          contentData.nameValidationType === 'combined' &&
                          (errors.firstName || errors.lastName)
                        "
                        class="form-error m-small"
                      >
                        {{ contentData.nameValidation }}
                      </small>

                      <InputCheckbox
                        v-if="stepContentData.ageRequirement"
                        v-model="ageCheck"
                        name="ageCheck"
                        :value="ageCheck"
                        wrapper-class="m-centered"
                        label-class="step-requirement"
                        provider-name="ageCheck"
                        :label="stepContentData.ageRequirement"
                        :aria-label="stepContentData.ageRequirement"
                        :is-validated="errors.ageCheck"
                        v-bind="validationProps.ageCheck.bind"
                        v-on="validationProps.ageCheck.on"
                        :error-message="errors.ageCheck ? `${errors.ageCheck} ${buttonText}` : ''"
                      />
                    </div>

                    <div v-if="hasPart.dob && !hasPart.birthdate" class="dob-input-group mt-6">
                      <InputDate
                        v-bind="validationProps.dob.bind"
                        v-model="dob"
                        :sub-label="stepContentData.dobSubLabel"
                        :labels="stepContentData.dobLabelMap"
                        :label="stepContentData.dobLabel"
                        :label-class="contentData.labelSize"
                        name="dob"
                        v-on="validationProps.dob.on"
                      />
                    </div>

                    <div v-if="hasPart.birthdate" class="dob-input-group mt-6">
                      <InputBirthdate
                        v-bind="validationProps.dob.bind"
                        v-model="dob"
                        :content="stepContent"
                        name="dob"
                        :validated="isPassed.dob"
                        v-on="validationProps.dob.on"
                      />
                    </div>

                    <div v-if="hasPart.phone" class="input-group mt-6">
                      <InputText
                        v-bind="validationProps.phone.bind"
                        v-model="phone"
                        :label="stepContentData.phoneLabel"
                        :sub-label="stepContentData.phoneCopy"
                        :label-class="contentData.labelSize"
                        :pattern="'^[0-9 ]*$'"
                        :paste-pattern="/[^0-9 ]/g"
                        :strip-spaces="true"
                        :aria-label="stepContentData.phoneLabel"
                        name="phone"
                        input-type="tel"
                        mode="numeric"
                        provider-name="Phone"
                        v-on="validationProps.phone.on"
                      />
                    </div>

                    <div v-if="hasPart.email" class="input-group mt-6">
                      <InputText
                        v-bind="validationProps.email.bind"
                        v-model="email"
                        name="email"
                        :label="stepContentData.emailLabel"
                        :label-class="contentData.labelSize"
                        :strip-spaces="true"
                        :aria-label="stepContentData.emailLabel"
                        input-type="text"
                        model="email"
                        provider-name="Email"
                        v-on="validationProps.email.on"
                      />
                    </div>

                    <div
                      :class="[
                        'input-group',
                        { 'mt-6': !wrapperClass.includes('in-page'), 'mt-10': isFunnel }
                      ]"
                      v-if="hasPart.provinceId"
                    >
                      <InputRadioSelect
                        v-if="stepContentData.regionFieldType === 'dropdown'"
                        v-model="provinceId"
                        name="provinceId"
                        provider-name="regionSelect"
                        :options="helperForm.regionCodenameToNum(stepContentData.regions)"
                        :label="stepContentData.regionLabel"
                        :label-class="contentData.labelSize"
                        :aria-label="stepContentData.regionLabel"
                        placeholder="Please Select"
                        :is-validated="errors.provinceId"
                        v-bind="validationProps.provinceId.bind"
                        v-on="validationProps.provinceId.on"
                      />
                      <InputRadio
                        v-if="stepContentData.regionFieldType === 'radio'"
                        v-model="provinceId"
                        name="provinceId"
                        provider-name="regionSelect"
                        :content="stepContent"
                        :options="helperForm.regionCodenameToNum(stepContentData.regionsRadio)"
                        :label="stepContentData.regionLabel"
                        :label-class="contentData.labelSize"
                        :aria-label="stepContentData.regionLabel"
                        :is-validated="errors.provinceId"
                        v-bind="validationProps.provinceId.bind"
                        v-on="validationProps.provinceId.on"
                      />
                      <small v-if="errors.provinceId" class="form-error">
                        {{ contentData.regionValidation }}
                      </small>
                    </div>

                    <div v-if="hasPart.radioListStep" class="input-group-radio mt-10">
                      <InputRadioList
                        v-model="radioListStepBind"
                        :content="stepContent"
                        :variation="stepContent.elements?.list_type?.value[0].codename || 'list'"
                        @next-step="stepOrSubmitFunc"
                      />
                      <small v-if="errors.ageCheckRange" class="form-error">
                        {{ contentData.ageCheckValidation }}
                      </small>
                    </div>

                    <div
                      v-if="stepContentData.legal && contentData.disclaimerPosition === 'above_cta'"
                      :class="[
                        'legal-text',
                        'mt-6',
                        contentData.disclaimerTextAlignment,
                        contentData.disclaimerTextSize
                      ]"
                      @click="onDisclaimerClick"
                      @keyup.enter.prevent="onDisclaimerClick"
                      v-html="stepContentData.legal"
                      role="button"
                      tabIndex="0"
                    ></div>

                    <div v-if="showButton" :class="['form-submit-container', buttonPosition, buttonColour]">
                      <CtaButton
                        type="button"
                        variant="primary"
                        :size="contentData.size"
                        :loading="(isFormLoading, isFormBtnDisabled)"
                        :disabled="(isFormLoading, isFormBtnDisabled)"
                        :disable-scroll="true"
                        :class="contentData.disclaimerPosition === 'below_cta' ? 'mt-10 mb-10' : 'mt-6'"
                        @mousedown="toggleValidationBlock(true)"
                        @click="stepOrSubmitFunc()"
                      >
                        {{ buttonText }}
                      </CtaButton>
                    </div>

                    <div
                      v-if="stepContentData.legal && contentData.disclaimerPosition === 'below_cta'"
                      :class="[
                        'legal-text',
                        contentData.disclaimerTextAlignment,
                        contentData.disclaimerTextSize
                      ]"
                      @click="onDisclaimerClick"
                      @keyup.enter.prevent="onDisclaimerClick"
                      v-html="stepContentData.legal"
                      role="button"
                      tabIndex="0"
                    ></div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="contentData.noticeBars && showNoticeBars">
    <NoticeBar v-for="(notice, i) in contentData.noticeBars" :key="i" :content="notice" />
  </div>
</template>

<script setup>
import { computed, ref, watch, defineAsyncComponent, onMounted, nextTick } from 'vue'
import { storeToRefs } from 'pinia'

import { useGlobalStore, useFormStore, useTrackingStore } from '#root/stores'
import { usePageContext } from '#root/renderer/usePageContext'
import { useGetContent } from '#root/components/composables/getContent.js'
import { useTrackFormErrors } from '#root/components/composables/trackFormErrors.js'
import { helperScroll, helperForm, helperDateTime } from '#root/utils/helpers'
import * as date from '#root/utils/models/Date'

import InputText from '#root/components/inputs/InputText.vue'
import InputDate from '#root/components/inputs/InputDate.vue'
import InputRadioList from '#root/components/inputs/InputRadioList.vue'
import InputBirthdate from '#root/components/inputs/InputBirthDate.vue'
import InputRadio from '#root/components/inputs/InputRadio.vue'
import InputRadioSelect from '#root/components/inputs/InputRadioSelect.vue'
import InputCheckbox from '#root/components/inputs/InputCheckbox.vue'
import CtaButton from '#root/components/buttons/CtaButton.vue'
import ProgressBar from '#root/components/forms/multi-step/ProgressBar.vue'
import NoticeBar from '#root/components/pageblocks/NoticeBar.vue'
// import StepIntro from '#root/components/forms/multi-step/StepIntro.vue'
import ClientOnly from '#root/components/utils/ClientOnly.vue'
import { navigate } from 'vike/client/router'
const TrustPilot = defineAsyncComponent(() => import('#root/components/pageblocks/TrustPilot.vue'))

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  wrapperClass: { type: String, default: 'm-focused' },
  trustPilotWidget: { type: Object, default: () => ({}) }
})

const pageContext = usePageContext()
const { trackFormFieldInteraction, trackMultiStepProgress, trackFormError } = useTrackingStore()
const globalStore = useGlobalStore()
const formStore = useFormStore()
const { setActiveStep, createLead } = formStore
const { activeStep } = storeToRefs(formStore)

const nameWrapperClasses = computed(() => {
  if (props.wrapperClass.includes('funnel')) {
    return {
      fname: 'mb-3 m-half md:pr-0',
      lname: 'mb-1 m-half md:pl-0 mt-0 md:mt-0'
    }
  } else if (props.wrapperClass.includes('in-page')) {
    return {
      fname: 'm-half',
      lname: 'm-half mt-6'
    }
  } else {
    return {
      fname: 'm-half md:pr-2',
      lname: 'm-half md:pl-2 mt-6 md:mt-0'
    }
  }
})

const isFunnel = computed(() => {
  return props.wrapperClass.includes('funnel')
})

const currentFields = computed(() => {
  if (contentData.value.formSteps.length > activeStep.value.index) {
    const step = contentData.value.formSteps[activeStep.value.index]
    return helperForm.getStepFields(step.system?.type)
  }
  return []
})

const allFieldsPassed = (fields = []) => {
  for (const field of fields) {
    if (!isPassed.value[field]) {
      return false
    }
  }
  return true
}

const overrideButtonState = computed(() => {
  return hasPart.value.birthdate && dob.value?.year && dob.value?.month && dob.value?.day
})

const showButton = computed(() => {
  return overrideButtonState.value || (!hasPart.value.radioListStep && !hasPart.value.birthdate)
})

const formBackground = computed(() => {
  return contentData.value.formBackground === 'primary'
    ? 'primary-bg'
    : contentData.value.formBackground === 'gs_200'
      ? 'gs-200-bg'
      : ''
})

const submit = async () => {
  await validateFields()

  let gtmCompanyName = ''
  let age = null
  if (dob) {
    age = new date.Parts(dob.value?.year ?? null, dob.value?.month ?? null, dob.value?.day ?? null)
    if (dobMaxOverride.value && age) {
      gtmCompanyName =
        helperDateTime.getAge(age.toPostString()) > dobMax.value
          ? contentData.value.companyNames[1]
          : contentData.value.companyNames[0]
    }
  }

  if (isFormValid.value) {
    // Disable button to prevent multiple submissions
    isFormBtnDisabled.value = true

    const context = {
      productCode: contentData.value.productCode,
      formType: contentData.value.formType,
      callMeLaterOffered: contentData.value.callMeLaterOffered,
      trackLeadFormType: `${contentData.value.formSteps.length}-step ${contentData.value.formType}`,
      ...(gtmCompanyName ? { gtmCompanyName: gtmCompanyName } : {})
    }

    try {
      const env = pageContext.env
      await submitForm((values) => {
        const valuesExt = {
          ...values,
          provinceName: provinceName.value
        }
        return createLead({ values: valuesExt, context, env }, pageContext)
      })
    } catch (e) {
      // Enable button to allow resubmission
      isFormBtnDisabled.value = false

      console.log(`${contentData.value.formType} form error:`, e)
      let errorLog = ''
      if (e instanceof Error) {
        errorLog = e.message
      } else {
        errorLog = e ?? 'Unknown Error'
      }
      trackFormError('Server Error', 'Request Form error: ' + errorLog, contentData.value.formType)
    }
  }
}

onMounted(() => {
  if (contentData.value.formSteps.length > 0) {
    setActiveStep({
      step: contentData.value.formSteps[0].elements.step_name.value,
      index: 0
    })
  }
  useTrackFormErrors('Multi Step Form', 'multi-form-wrapper')
})

const stepOrSubmitFunc = async (intro = false) => {
  const index = activeStep.value.index + 1
  const nextStep = contentData.value.formSteps[index]?.system?.type ?? null

  toggleValidationBlock(false)

  await Promise.all(
    currentFields.value.filter((field) => !isTested.value[field]).map((field) => validateField(field))
  )

  if (!allFieldsPassed(currentFields.value)) {
    onValidationFailed()
    return
  } else {
    trackMultiStepProgress({
      step: contentData.value.formSteps[activeStep.value.index].elements.step_name.value,
      index: activeStep.value.index + 1
      // region
    })
  }

  let step = true
  // submit before comming back to intro_outro step or submit on last step of non banner multi step form
  if (nextStep === 'form_step___intro_outro') {
    await submit()
    onSubmitComplete()
  } else if (!nextStep && index === contentData.value.formSteps.length) {
    step = false
    await submit()
    onSubmitComplete()
  }

  if (step) {
    setActiveStep({
      step: contentData.value.formSteps[index].elements.step_name.value,
      index
    })
  }
  scrollToTop()
}

const contentData = computed(() => {
  return {
    productCode: useGetContent(props.content, 'product.linkedItems[0].elements.product_code.value', null),
    callMeLaterOffered:
      useGetContent(props.content, 'call_me_later_offered.value[0].codename', 'disable') === 'enable',
    formSteps: useGetContent(props.content, 'form_steps.linkedItems', []),
    formType: useGetContent(props.content, 'form_type.value[0].codename', ''),
    thankYouPageUrl: useGetContent(
      props.content,
      'thank_you_page_url.linkedItems[0].elements.url.value',
      null
    ),
    progressBar: useGetContent(props.content, 'progress_bar.linkedItems[0].elements', ''),
    size: useGetContent(props.content, 'button_size.value[0].codename', 'large'),
    ctaButton: useGetContent(props.content, 'cta_button.value', ''),
    stepButton: useGetContent(props.content, 'step_button.value', ''),
    modal: {
      thanks: useGetContent(props.content, 'thanks_modal.linkedItems[0].elements', null)
    },
    trustPilot: useGetContent(props.content, 'trustpilot_widget.linkedItems', null),
    formBackground: useGetContent(props.content, 'form_background.value[0].codename', ''),
    label: useGetContent(props.content, 'label.value', ''),
    labelSize: useGetContent(props.content, 'label_size.value[0].codename', 'large'),
    buttonPosition: useGetContent(props.content, 'button_position.value[0].codename', 'left'),
    butttonSize: useGetContent(props.content, 'button_size.value[0].codename', 'normal'),
    buttonColour: useGetContent(props.content, 'button_colour.value[0].codename', 'primary_cta'),
    buttonStyles: useGetContent(props.content, 'button_style.value', []).map((style) => style.codename),
    disclaimerPosition: useGetContent(
      props.content,
      'disclaimer_text_position.value[0].codename',
      'above_cta'
    ),
    disclaimerTextAlignment: useGetContent(
      props.content,
      'disclaimer_text_alignment.value[0].codename',
      'left'
    ),
    disclaimerTextSize: useGetContent(props.content, 'disclaimer_text_size.value[0].codename', 'normal'),
    nameValidation: useGetContent(
      props.content,
      'form_validations.linkedItems[0].elements.validations_name_default.value',
      null
    ),
    regionValidation: useGetContent(
      props.content,
      'form_validations.linkedItems[0].elements.validations_region_default.value',
      null
    ),
    ageCheckValidation: useGetContent(
      props.content,
      'form_validations.linkedItems[0].elements.validations_dob_invalid.value',
      null
    )
      .replace(
        'MINAGE',
        useGetContent(props.content, 'form_validations.linkedItems[0].elements.validations_min_age.value', '')
      )
      .replace(
        'MAXAGE',
        useGetContent(props.content, 'form_validations.linkedItems[0].elements.validations_max_age.value', '')
      ),
    companyNames: [
      useGetContent(props.content, 'company_name_1.value', ''),
      useGetContent(props.content, 'company_name_2.value', '')
    ],
    disableEmailValidation: !!useGetContent(props.content, 'disable_email_validation.value.length', []),
    nameValidationType: useGetContent(props.content, 'name_validation.value[0].codename', 'combined'),
    noticeBars: useGetContent(props.content, 'notice_bars.linkedItems', []),
    showNoticeBars: useGetContent(props.content, 'show_notice_bars.value[0].name', 'All Steps')
  }
})

const showNoticeBars = computed(() => {
  if (contentData.value.showNoticeBars === 'All Steps') {
    return true
  } else {
    return Number(contentData.value.showNoticeBars) === activeStep.value.index + 1
  }
})

const buttonPosition = computed(() => {
  return contentData.value.buttonPosition === 'left'
    ? 'left'
    : contentData.value.buttonPosition === 'left_center'
      ? 'left-center'
      : contentData.value.buttonPosition === 'right'
        ? 'right'
        : contentData.value.buttonPosition === 'right_center'
          ? 'right-center'
          : contentData.value.buttonPosition === 'center'
            ? 'center'
            : contentData.value.buttonPosition === 'stretch'
              ? 'stretch'
              : 'left'
})

const buttonColour = computed(() => {
  return contentData.value.buttonColour === 'primary_cta'
    ? 'primary'
    : contentData.value.buttonColour === 'secondary_incentive'
      ? 'secondary-incentive'
      : 'primary'
})

const formPosition = computed(() => {
  if (props.wrapperClass.includes('left')) {
    return 'xl:col-span-7'
  } else if (props.wrapperClass.includes('center_small')) {
    return 'xl:col-span-6 xl:col-start-3'
  } else if (
    ['center', 'm_center', 'm_native_center', 'form_center_submit_right'].some((c) =>
      props.wrapperClass.includes(c)
    )
  ) {
    return 'xl:col-span-8 xl:col-start-3 centered-form'
  } else if (props.wrapperClass.includes('in-page')) {
    return 'multistep-quote-container-in-page'
  } else if (props.wrapperClass.includes('funnel')) {
    return 'xl:col-span-6 xl:col-start-4'
  } else {
    return ''
  }
})

const stepContent = computed(() => contentData.value.formSteps[activeStep.value.index])
const stepContentData = computed(() => {
  return {
    title: useGetContent(stepContent.value, 'title.value'),
    titleRT: useGetContent(stepContent.value, 'title_rt.value', ''),
    content: useGetContent(stepContent.value, 'content.value'),
    contentRT: useGetContent(stepContent.value, 'content_rt.value', ''),
    fNameLabel: useGetContent(stepContent.value, 'fname_label.value', 'First Name'),
    lNameLabel: useGetContent(stepContent.value, 'lname_label.value', 'Last Name'),
    ageRequirement: useGetContent(stepContent.value, 'age_requirement.value', null),
    regionLabel: useGetContent(stepContent.value, 'label.value', ''),
    regionTitle: useGetContent(stepContent.value, 'title.value', ''),
    regions: useGetContent(stepContent.value, 'regions.linkedItems[0].elements.regions.value', []),
    regionsRadio: useGetContent(stepContent.value, 'regions.linkedItems[0].elements.regions_radio.value', []),
    regionFieldType: useGetContent(
      stepContent.value,
      'regions.linkedItems[0].elements.field_type.value[0].codename',
      'radio'
    ),
    minAge: useGetContent(stepContent.value, 'min_age.value', ''),
    maxAge: useGetContent(stepContent.value, 'max_age.value', ''),
    dobLabels: useGetContent(stepContent.value, 'date_of_birth_labels.value', []),
    dobLabel: useGetContent(stepContent.value, 'date_of_birth_label.value', ''),
    dobSubLabel: useGetContent(stepContent.value, 'date_of_birth_sub_label.value', ''),
    dobLabelMap: helperForm.getDOBLabels(stepContent.value),
    emailLabel: useGetContent(stepContent.value, 'email_label.value'),
    phoneLabel: useGetContent(stepContent.value, 'phone_label.value'),
    phoneCopy: useGetContent(stepContent.value, 'phone_number_copy.value'),
    radioListStep: useGetContent(stepContent.value, 'options.value', []),
    dobContraints: useGetContent(stepContent.value, 'dob_constraints.value', []),
    dobMaxOverride: useGetContent(stepContent.value, 'dob_max_override.value', ''),
    legal: useGetContent(stepContent.value, 'legal.value')
      .replace(
        'TERMS_MODAL',
        '<a tabindex="0" class="modal-link showModalA">' +
          useGetContent(props.content, 'terms_replacement_text.value', 'terms of use') +
          '</a>'
      )
      .replace(
        'PRIVACY_MODAL',
        '<a tabindex="0" class="modal-link showModalB">' +
          useGetContent(props.content, 'policy_replacement_text.value', 'privacy policy') +
          '</a>'
      ),
    modal: {
      terms: useGetContent(props.content, 'terms_modal.linkedItems[0].elements', null),
      policy: useGetContent(props.content, 'privacy_modal.linkedItems[0].elements', null)
    }
  }
})

const inputPatterns = computed(() => helperForm.inputPatterns(globalStore.locale))

const dobMaxOverride = ref('')
const dobMax = ref('')
const provinceName = ref('')

watch(
  () => stepContentData.value.dobContraints,
  (value) => {
    if (value.length) dobMax.value = value[1].name
  }
)

watch(
  () => stepContentData.value.dobMaxOverride,
  (value) => {
    if (value) dobMaxOverride.value = value
  }
)

const progressRange = computed(() => {
  let start = 0
  let end = contentData.value.formSteps.length - 1
  if (
    contentData.value.formSteps[contentData.value.formSteps.length - 1].system.codename ===
    'banner_form_intro'
  ) {
    end = contentData.value.formSteps.length - 2
  }
  if (contentData.value.formSteps[0].system.codename === 'banner_form_intro') {
    start = 1
  }
  return {
    start,
    end
  }
})

const numberOfSteps = computed(() => {
  let count = 0
  for (let step = 0; step < contentData.value.formSteps.length; step++) {
    if (contentData.value.formSteps[step].system.codename === 'banner_form_intro') {
      count++
    }
  }
  return contentData.value.formSteps.length - count
})

let radioListStepBind = null
const hasPart = computed(() => {
  const { type } = stepContent.value?.system ?? null
  const data = stepContentData.value
  const hasNameData = !!(data.fNameLabel && data.lNameLabel)
  const hasDOBData = !!(data.dobLabel || data.dobLabels.length)
  const hasBirthDateData = !!data.dobContraints
  const hasEmailData = !!data.emailLabel
  const hasPhoneData = !!data.phoneLabel
  const hasRadioListData = !!data.radioListStep.length
  const hasRegionData = !!data.regionTitle

  let name = false,
    dob = false,
    birthdate = false, // funnel component
    email = false,
    phone = false,
    radioListStep = false,
    provinceId = false

  switch (type) {
    case 'form_step___fname_lname':
      name = hasNameData
      break
    case 'form_step___fname_lname__agecheck':
      name = hasNameData
      break
    case 'form_step___dob':
      dob = hasDOBData
      break
    case 'form_step___phone_email':
      phone = hasPhoneData
      email = hasEmailData
      break
    case 'form_step___name_dob':
      name = hasNameData
      dob = hasDOBData
      break
    case 'form_step___regions':
      provinceId = hasRegionData
      break
    case 'form_step___cover_group':
      radioListStep = hasRadioListData
      radioListStepBind = coverGroup
      break
    case 'form_step___gender':
      radioListStep = hasRadioListData
      radioListStepBind = gender
      break
    case 'form_step___cover_type':
      radioListStep = hasRadioListData
      radioListStepBind = coverType
      break
    case 'form_step___cover_amount':
      radioListStep = hasRadioListData
      radioListStepBind = coverAmount
      break
    case 'form_step___smoker_status':
      radioListStep = hasRadioListData
      radioListStepBind = smoker
      break
    case 'form_step___birthdate':
      birthdate = hasBirthDateData
      dob = true
      break
    case 'form_step___age_check_range':
      radioListStep = hasRadioListData
      radioListStepBind = ageCheckRange
      break
  }

  return {
    name,
    dob,
    birthdate,
    email,
    phone,
    radioListStep,
    radioListStepBind,
    provinceId
  }
})

const buttonText = computed(() => {
  if (contentData.value.formType[0].codename === 'banner') {
    //Check for second last step
    if (
      contentData.value.formSteps.length > 2 &&
      activeStep.value.index === contentData.value.formSteps.length - 2
    ) {
      //If last step is thank you/text return submit text otherwise return step text
      if (
        contentData.value.formSteps[contentData.value.formSteps.length - 1].system.codename ===
        'banner_form_intro'
      ) {
        return contentData.value.ctaButton
      } else {
        return contentData.value.stepButton
      }
    }
  }

  if (activeStep.value.index === contentData.value.formSteps.length - 1) {
    return contentData.value.ctaButton
  }

  return contentData.value.stepButton
})

const {
  ageCheck,
  ageCheckRange,
  firstName,
  lastName,
  dob,
  phone,
  email,
  coverGroup,
  gender,
  coverType,
  coverAmount,
  smoker,
  validationProps,
  errors,
  provinceId,
  isPassed,
  validateField,
  isFormLoading,
  isFormBtnDisabled,
  isFormValid,
  isTested,
  submitForm,
  validateFields,
  toggleValidationBlock
} = helperForm.useGeneralForm(
  {
    ...helperForm.getGenericParams(props.content)
  },
  contentData.value.disableEmailValidation
)

watch(
  () => provinceId?.value,
  (value) => {
    if (value) {
      let regions = []
      if (stepContentData.value.regionFieldType === 'radio') {
        regions = helperForm.regionCodenameToNum(stepContentData.value.regionsRadio)
      } else {
        regions = helperForm.regionCodenameToNum(stepContentData.value.regions)
      }

      const foundRegion = regions.find((r) => r.codename === value)
      if (foundRegion) {
        provinceName.value = foundRegion.name
      }
    }
  }
)

const onSubmitComplete = () => {
  if (contentData.value.thankYouPageUrl) {
    navigate(contentData.value.thankYouPageUrl)
  }
  if (contentData.value.modal?.thanks) {
    showModal.value.thanks = true
  }
}

const onValidationFailed = () => {
  scrollToTop()
}

const onInputBlur = (event) => {
  if (!event) return
  trackFormFieldInteraction(event)
}

const { windowSize } = storeToRefs(globalStore)

const scrollToTop = () => {
  nextTick(() => {
    if (contentData.value.progressBar) {
      helperScroll.scrollToElement('.progress-bar-container', windowSize.value?.isDesktop ? 160 : 80)
    } else {
      helperScroll.scrollToElement('.form-step', windowSize.value?.isDesktop ? 190 : 80)
    }
  })
}

const onDisclaimerClick = (e) => {
  if (e.target.matches('.showModalA')) {
    globalStore.toggleModal('genericModal', stepContentData.value.modal.terms)
  } else if (e.target.matches('.showModalB')) {
    globalStore.toggleModal('genericModal', stepContentData.value.modal.policy)
  }
}
</script>

<style lang="scss" scoped>
.placeholder-for-trust {
  height: 20px;
  overflow: hidden;
}

.multistep-quote-container {
  .inline-input-group,
  .input-group {
    width: calc(100% - 48px);
  }

  .step-content p {
    font-size: $legal-text;
    font-weight: $w-body;
    line-height: 20px;

    a {
      text-decoration: underline;
    }
  }

  .dob-input-group {
    width: 100%;

    :deep(input) {
      text-align: center;
    }
  }

  .form-submit-container {
    &.secondary-incentive {
      button {
        background: $secondary-incentive;

        &:active,
        &:focus {
          outline: 4px solid darken($secondary-incentive, 8%);
          box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);
        }

        &:hover {
          background-color: darken($secondary-incentive, 5%);
          cursor: pointer;
        }

        &:disabled {
          pointer-events: none;
          background: $gs-200;
          color: $gs-500;
        }
      }
    }

    &.stretch {
      text-align: left;

      :deep(button) {
        width: 100%;
      }
    }

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.left-center {
      text-align: left;

      @include media-query('desktop') {
        text-align: center;
      }
    }

    &.right-center {
      text-align: right;

      @include media-query('desktop') {
        text-align: center;
      }
    }
  }

  .form-disclaimer,
  .form-disclaimer :deep(p) {
    font-size: $legal-text;
    line-height: 24px;
    font-weight: $w-body;
    text-align: left;
    color: $gs-600;
  }

  .form-disclaimer :deep(a) {
    font-weight: $w-display;
    text-decoration: underline;
    color: $gs-600;
  }

  .legal-text {
    :deep(p) {
      font-size: $legal-text;
      font-weight: $w-light;
      line-height: 24px;
    }

    &.center {
      :deep(p) {
        text-align: center;
      }
    }

    &.left {
      :deep(p) {
        text-align: left;
      }
    }

    &.large {
      :deep(p) {
        font-size: $body-small;
        font-weight: $w-body;
      }
    }
  }

  :deep(.modal-link) {
    font-weight: $w-heading;
    cursor: pointer;
  }

  .field-check {
    position: absolute;
    right: 20px;
    top: 40px;
    color: #11ad78;
  }

  .field-loading {
    position: absolute;
    right: 20px;
    top: 35px;

    svg.loading {
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .field-check-sub-label {
    position: absolute;
    right: 20px;
    top: 60px;
    color: #11ad78;
  }

  .thank-you-modal {
    text-align: center;

    p {
      font-size: $body-regular;
      line-height: 3rem;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .form-steps {
    display: flex;
    flex-direction: column;
    position: relative;

    .form-step {
      .step-title:deep(h1) {
        font-size: 3.2rem;
        font-weight: $w-heading;
        line-height: 120%;
        margin-bottom: $spacing-2;

        @include media-query('desktop') {
          font-size: 3.6rem;
        }
      }

      h2 {
        font-size: 3.2rem;
        font-weight: $w-heading;
        line-height: 120%;
        margin-bottom: $spacing-2;

        @include media-query('desktop') {
          font-size: 3.6rem;
        }
      }

      p {
        font-size: $legal-text;
        font-weight: $w-body;
        line-height: 20px;
      }

      .legal-text,
      .legal-text p {
        font-size: $legal-text;
        line-height: 3rem;
      }

      .step-content {
        :deep(p) {
          font-size: $legal-text;
          font-weight: $w-body;
          line-height: 20px;

          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.multistep-quote-container.banner {
  @include media-query('desktop') {
    padding-right: 49px;
  }

  .form-step {
    .input-group {
      margin: $spacing-4 0 0;
    }

    h2 {
      margin: 0 0 $spacing-2;
    }

    .step-title:deep(h1) {
      margin: 0 0 $spacing-2;
    }

    p {
      margin: 0 0 $spacing-2;
      font-size: $legal-text;
    }
  }

  .form-submit-container {
    width: calc(100% - 48px);
  }

  .legal-text {
    :deep(p) {
      text-align: left;
    }
  }

  @include media-query('desktop') {
    .inline-input-group,
    .input-group {
      width: 100%;
    }
    .form-submit-container {
      width: 100%;
    }

    .inline-input-group {
      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }

    .form_step___name_dob {
      .input-group {
        width: 100%;

        .input-wrapper:nth-child(1) {
          .validation-check {
            display: block !important;
          }
        }
      }
    }
  }
}

.multistep-quote-container.funnel {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: $spacing-4 $spacing-4 $spacing-10;
  min-height: 60vh;

  .progress-bar-container {
    margin: $spacing-4 0;
  }

  .form-steps {
    gap: 0;
    max-width: 100%;
    min-height: 620px;

    h2,
    p {
      text-align: center;
    }
  }

  .form-step {
    max-width: 100%;
  }

  .legal-text {
    :deep(p) {
      text-align: center;
    }

    &.center {
      :deep(p) {
        text-align: center;
      }
    }

    &.left {
      :deep(p) {
        text-align: left;
      }
    }
  }

  .inline-input-group,
  .input-group {
    width: calc(100% - 48px);
  }

  .input-wrapper {
    :deep(input.invalid),
    :deep(input.valid) {
      width: 100%;
    }
    :deep(input.invalid ~ .validation-check),
    :deep(input.valid ~ .validation-check) {
      right: -48px;
    }
  }

  .inline-input-group.stacked {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    :deep(.field-loading-container) {
      .validation-check {
        &.lastName {
          top: -40px;
        }

        &.individually {
          top: 6px;
        }

        &.individually.firstName {
          @include media-query('phablet') {
            opacity: 1;
          }
        }
      }
    }
  }

  .input-group-radio {
    width: 100%;
  }

  :deep(.radio-list.opened) {
    top: 0;
  }

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
    padding: $spacing-6 $spacing-6 $spacing-10;

    .progress-bar-container {
      margin: 32px 0 40px;
    }
  }

  @include media-query('desktop') {
    padding: $spacing-6 $spacing-6 $spacing-12;
    max-width: $g-desktop-breakpoint-min-width;
  }

  @include media-query('desktop-large') {
    max-width: 1280px;
  }
}

.multistep-quote-container.left {
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: $spacing-4;

  .form-step {
    h2 {
      margin: 0 0 $spacing-2;
    }

    p {
      margin: 0 0 $spacing-6;
      font-size: $legal-text;
    }

    .cta-btn {
      margin: $spacing-10 0 0;
    }
  }

  @include media-query('phablet') {
    .inline-input-group {
      width: calc(100% - 48px);

      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
  }

  @include media-query('desktop') {
    padding: $spacing-12 $spacing-6;
    max-width: $g-desktop-breakpoint-min-width;

    .inline-input-group {
      .input-wrapper:nth-child(1) {
        .validation-check {
          display: none;
        }
      }
    }
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;
  }
}

.multistep-quote-container.center {
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: $spacing-4 $spacing-4 $spacing-10;

  .inline-input-group,
  .input-group {
    width: calc(100% - 48px);
  }

  .centered-form {
    .progress-bar-container {
      margin: $spacing-4 0 $spacing-10;
    }
  }

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
    padding: $spacing-6 $spacing-6 $spacing-10;
  }

  @include media-query('desktop') {
    padding: $spacing-10 $spacing-6 $spacing-12;
    max-width: $g-desktop-breakpoint-min-width;
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;

    .centered-form {
      max-width: 712px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  position: absolute;
  width: 100%;
  transition: opacity 0.25s ease 0.15s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.multistep-quote-container-in-page {
  border-radius: $g-radius;
  padding: $spacing-6;
  min-width: unset;
  margin: 32px auto 0 auto;
  width: 100%;

  .form-step {
    h2 {
      text-align: center;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: $spacing-5;
      width: 100%;
      margin-bottom: $spacing-6;
    }
  }

  &.gs-200-bg {
    background-color: $gs-200;
    border-radius: $spacing-4;

    .input-group {
      padding-right: $spacing-10;
    }

    .input-group-radio {
      margin-bottom: $spacing-2;

      :deep(.radio-list) {
        margin-bottom: $spacing-6;
      }

      :deep(.radio-list.grid) {
        .radio-list-item {
          background: $white;

          svg {
            fill: $gs-500;

            path {
              fill: $gs-500;
            }
          }

          label {
            color: $gs-500;
          }

          &.selected {
            background: $headers;
            border: 1px solid $headers;

            label {
              color: $white;
            }

            &:before {
              border: 2px solid $headers;
            }
          }
        }
      }
    }

    .inline-input-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 90%;

      :deep(.field-loading-container) {
        .validation-check.lastName {
          top: -40px;
        }

        .validation-check.individually.firstName {
          @include media-query('phablet') {
            opacity: 1;
          }
        }
      }

      :deep(.form-error) {
        width: 100%;
      }
    }

    .step-title {
      padding-top: $spacing-2;
      font-size: $h2-m;
    }

    .step-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: $spacing-10;
      padding-top: $spacing-6;
      font-size: $h4-m;
      font-weight: $w-link;
      color: $core-font;
    }

    .checkbox-wrapper {
      font-size: $body-small;
      color: $core-font;
      font-weight: $w-cta;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      :deep(input) {
        width: $sec-h4-m;
        height: $sec-h4-m;
        margin-right: $spacing-3;
      }
    }
  }

  .legal-text {
    :deep(p) {
      font-size: $legal-text;
      font-weight: $w-light;
      line-height: 24px;
    }
  }

  @include media-query('phablet') {
    max-width: 548px;
    width: 100%;
  }

  @include media-query('desktop') {
    :deep(.radio-list-item) {
      flex: 40%;
    }
  }
  @include media-query('desktop-large') {
    :deep(.radio-list-item) {
      flex: 20%;
    }
  }
}
</style>
