<template>
  <div class="input-group">
    <div :class="inputCSS.wrapper">
      <InputLabel
        v-if="label"
        :label="label"
        :label-class="labelClass"
        :sub-label="subLabel"
        for-label="day"
      />
      <div class="field-loading-container flex align-bottom">
        <InputText
          :label="label ? '' : props.label.day"
          name="day"
          input-type="tel"
          placeholder="DD"
          :pattern="'^[0-9 ]*$'"
          mode="numeric"
          wrapper-class="m-third mr-2 xl:mr-4"
          :max-length="2"
          :validation-check-icon="false"
          :aria-label="props.labels.day"
          :error="!!error"
          :model-value="modelValue.day"
          :is-validated="isValidated"
          @blur="onDayBlur"
          @focus="onFieldFocus"
          @update:model-value="updateDay"
        />
        <InputText
          input-type="tel"
          :label="label ? '' : props.labels.month"
          name="month"
          placeholder="MM"
          :pattern="'^[0-9 ]*$'"
          mode="numeric"
          wrapper-class="m-third mr-2 xl:mr-4"
          :max-length="2"
          :validation-check-icon="false"
          :aria-label="props.labels.month"
          :error="!!error"
          :model-value="modelValue.month"
          :is-validated="isValidated"
          @blur="onMonthBlur"
          @focus="onFieldFocus"
          @update:model-value="updateMonth"
        />
        <InputText
          input-type="tel"
          :label="label ? '' : props.labels.year"
          name="year"
          placeholder="YYYY"
          :pattern="'^[0-9 ]*$'"
          mode="numeric"
          wrapper-class="m-third"
          :max-length="4"
          :aria-label="props.labels.year"
          :error="!!error"
          :model-value="modelValue.year"
          :is-validated="isValidated"
          :is-validating="isValidating"
          @blur="onYearBlur"
          @focus="onFieldFocus"
          @update:model-value="updateYear"
        />
      </div>
    </div>
    <small v-if="error && typeof error === 'string'" class="form-error m-small">
      <span v-html="error" />
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useTrackingStore } from '#root/stores'

import * as date from '#root/utils/models/Date'

import InputText from '#root/components/inputs/InputText.vue'
import InputLabel from '#root/components/inputs/InputLabel.vue'

const { trackFormFieldInteraction } = useTrackingStore()
const emit = defineEmits(['focus', 'blur', 'update:modelValue'])

const props = defineProps({
  labels: {
    type: Object,
    default: () => {
      return {
        year: 'Year',
        month: 'Month',
        day: 'Day'
      }
    }
  },
  label: { type: String, default: '' },
  labelClass: { type: String, default: 'normal' },
  subLabel: { type: String, default: '' },
  modelValue: {
    type: Object,
    default: () => new date.Model('', '', '')
  },
  name: { type: String, required: true },
  error: { type: [String, Boolean], default: false },
  isValidated: { type: Boolean, default: false },
  isValidating: { type: Boolean, default: false },
  validationCheckIcon: { type: Boolean, default: true },
  wrapperClass: { type: String, default: '' }
})

// const { year, month, day, errors, validateField, setValues } = helperForm.useGeneralForm({ fields: ['year', 'month', 'day'] })

let focusCount = 0
let eventInProgress = false

const blurCheckerFactory = (field) => {
  return (event) => {
    trackFormFieldInteraction(event)
    event.stopPropagation()
    event.preventDefault()

    // trackFormFieldInteraction(event)
    focusCount -= 1
    eventInProgress = true
    setTimeout(() => {
      eventInProgress = false
      if (focusCount === 0) {
        emit('blur', event)
      }
    }, 0)
  }
}

const updateFactory = (field) => {
  return (value) => {
    const { year, month, day } = props.modelValue
    const dateObject = new date.Model(year, month, day)

    dateObject[field] = value

    emit('update:modelValue', dateObject)
  }
}

const updateYear = updateFactory('year')
const updateMonth = updateFactory('month')
const updateDay = updateFactory('day')

const onYearBlur = blurCheckerFactory('year')
const onMonthBlur = blurCheckerFactory('month')
const onDayBlur = blurCheckerFactory('day')

const onFieldFocus = (event) => {
  event.stopPropagation()
  event.preventDefault()

  focusCount += 1

  if (!eventInProgress) {
    emit('focus', event)
  }
}

const inputCSS = computed(() => {
  return {
    wrapper: props.wrapperClass
  }
})
</script>
