<!--
  * Component Name: ContentTable
  * Description: Styled table component
  * Props:
      content
  * Components:

  * Usage:
      <ContentTable :content="value" />
-->

<template>
  <section
    :content-id="props.content?.system?.id"
    :class="
      contentData.type === 'standalone'
        ? 'grid-container mx-auto py-10 xxl:py-11 px-4 xxl:px-6 standalone-table'
        : 'sidebar-table'
    "
  >
    <div
      :class="
        contentData.type === 'standalone' ? 'grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12' : ''
      "
    >
      <div v-if="contentData.introduction" class="col-span-full">
        <HtmlContent class="compare-table-intro" :content="contentData.introduction" />
      </div>
      <div
        class="col-span-full table-full-container"
        :class="contentData.type === 'standalone' ? 'my-10 xl:mb-0' : 'mt-8 xl:mt-0'"
      >
        <div class="table">
          <div class="row table-head">
            <div v-for="(head, item) in contentData.head" :key="item" class="column table-column p-4">
              <HtmlContent :content="head.elements.text.value" />
            </div>
          </div>

          <div class="table-rows-container">
            <div v-for="(row, index) in contentData.rows" :key="index" class="row table-row">
              <div
                v-for="(option, item) in row.elements.columns.linkedItems"
                :key="item"
                class="column table-column p-4"
              >
                <HtmlContent :content="option.elements.text.value" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import HtmlContent from '#root/components/utils/HtmlContent.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    introduction: useGetContent(props.content, 'introduction.value', null),
    head: useGetContent(props.content, 'head.linkedItems', null),
    rows: useGetContent(props.content, 'rows.linkedItems', null),
    type: useGetContent(props.content, 'type.value[0].codename', 'standalone')
  }
})
</script>

<style lang="scss">
.compare-table-intro {
  @include pageblock-header();
}

.table {
  width: 100%;
  border-radius: $spacing-2;
  overflow: hidden;

  .column {
    width: 100%;

    @include media-query('tablet') {
      display: block;
      flex: 1 0 50%;
      text-align: center;
    }
  }

  .row {
    @include flex-parent(row, nowrap, space-between, stretch);
  }
}

.table-head {
  background-color: $table-background;
  color: $table-font;
  border-radius: 8px 8px 0 0;

  .column {
    display: none;
    text-align: center;

    p {
      font-size: $body-large;
      line-height: 32px;
      color: $table-font;
    }

    &:nth-child(-n + 4) {
      display: block;
      flex: 1 0 50%;

      &:first-child {
        flex: 1 0 50%;
        text-align: center;
        font-family: $primary-font;
      }

      @include media-query('tablet') {
        flex: 1 0 50%;
      }
    }

    @include media-query('tablet') {
      display: block;
    }
  }
}

.table-column h4 {
  font-size: $body-regular;
  color: $primary;
  margin: 0;
}

.table-column,
.table-column p {
  font-size: $body-regular;
  line-height: 28px;
  font-weight: $w-body;
  color: $tertiary;
  margin: 0;
}

.sidebar-table {
  .table-row {
    background-color: $gs-200;

    &:nth-child(odd) {
      background-color: $support;
    }

    &:nth-child(even) {
      background-color: $white;
    }
  }

  .table .column {
    text-align: center;
  }
}

.standalone-table {
  .table-row {
    background-color: $gs-200;
    border: 0;
    border-bottom: solid 1px $gs-300;
    background-color: $white;

    &:nth-child(even) {
      background-color: $support;
    }

    &:last-of-type {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border-bottom: none;
    }
  }

  .table .column {
    text-align: left;
  }

  .row.table-row .table-column:first-child {
    border-right: 1px solid $gs-400;
  }

  .table-head {
    min-height: 88px;
    align-items: center;

    .column {
      p {
        font-size: $body-regular;
        line-height: 30px;
        text-align: center;
      }
    }
  }
}
</style>
