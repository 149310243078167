import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

export default function getBannerContent(props) {
  const contentData = computed(() => {
    return {
      bannerImageDesktop: useGetContent(props.content, 'image.linkedItems[0].elements.image.value[0]', null),
      bannerImageTablet: useGetContent(
        props.content,
        'image.linkedItems[0].elements.tablet_image.value[0]',
        null
      ),
      bannerImageMobile: useGetContent(
        props.content,
        'image.linkedItems[0].elements.mobile_image.value[0]',
        null
      ),
      trustBadge: useGetContent(props.content, 'trust_badge.linkedItems[0].elements', null),
      trustBadgeType: useGetContent(
        props.content,
        'trust_badge.linkedItems[0].elements.image.value[0].description',
        ''
      ),
      appointmentSubBanner: !!useGetContent(props.content, 'appointment_sub_banner.value[0]', false),
      appointmentAltContent: useGetContent(props.content, 'content.linkedItems[1].elements', null),
      content: useGetContent(props.content, 'content.linkedItems[0].elements', null)
    }
  })

  return {
    contentData
  }
}
