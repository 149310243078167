<!--
  * Component Name: InputRadioList
  * Description: Input Radio List
  * Props:
      steps, content
  * Components:
  * Usage:
      <InpuRadioList :content="content" />
-->
<template>
  <div>
    <div :class="variation" class="radio-list">
      <div
        v-for="(option, i) in contentData.options"
        :key="i"
        :class="{ selected: modelValue === option.name }"
        class="radio-list-item"
        :data-gtm="
          (contentData.gtm[i]?.name ? `${contentData.stepName.toLowerCase()} ${contentData.gtm[i].name}` : '',
          contentData.ageGTM.length
            ? `${option.name === 'Yes' ? 'Age' : 'Not'} ${contentData.ageGTM[0].name} to ${contentData.ageGTM[1].name}`
            : '')
        "
        @click.prevent="selectOption(option.name)"
        @keyup.enter.prevent="selectOption(option.name)"
        role="button"
        tabIndex="0"
      >
        <template v-if="icon">
          <SvgIcon v-if="icon" :name="option.name.toLowerCase()" />
        </template>
        <template v-else>
          <SvgIcon v-if="variation === 'list' && modelValue === option.name" name="tick-list-icon" />
          <SvgIcon v-if="i === 0 && variation === 'grid'" name="smoker" />
          <SvgIcon v-if="i === 1 && variation === 'grid'" name="non-smoker" />
        </template>
        <slot v-if="i === 0" name="yes"></slot>
        <slot v-if="i === 1" name="no"></slot>
        <input
          :id="option.codename"
          :name="option.codename"
          :value="option.name"
          type="radio"
          class="raido-list"
        />
        <label
          :for="option.codename"
          :data-gtm="
            contentData.gtm[i]?.name ? `${contentData.stepName.toLowerCase()} ${contentData.gtm[i].name}` : ''
          "
        >
          {{ option.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import SvgIcon from '#root/components/utils/SvgIcon.vue'

const props = defineProps({
  modelValue: { type: String, default: '' },
  content: { type: Object, default: () => ({}) },
  variation: { type: String, default: 'list' },
  icon: { type: String, default: '' }
})

const emit = defineEmits(['update:modelValue', 'nextStep', 'input'])
const selectOption = (value) => {
  emit('update:modelValue', value)
  emit('input', value)
  setTimeout(() => {
    emit('nextStep', null)
  }, 300)
}

const contentData = computed(() => {
  return {
    options: useGetContent(props.content, 'options.value', []),
    gtm: useGetContent(props.content, 'classes.value', []),
    ageGTM: useGetContent(props.content, 'dob_constraints.value', []),
    stepName: useGetContent(props.content, 'step_name.value', '')
  }
})
</script>

<style lang="scss" scoped>
.radio-list.list {
  display: flex;
  flex-direction: column;

  .radio-list-item {
    background: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-4;
    border-radius: $input-radius;
    border: 1px solid $gs-500;
    position: relative;
    transition: all 0.3s ease;

    svg {
      position: absolute;
      left: 22px;
      margin: auto;
      top: 2px;
      bottom: 0;

      :deep(path) {
        fill: $core-font;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 2px solid $gs-400;
      background: $white;
    }

    label {
      cursor: pointer;
      color: $gs-600;
      font-size: $body-small;
      font-weight: $w-label;
      padding: 16px 16px 16px 64px;
      width: 100%;
      z-index: 2;
    }

    input {
      display: none;
      opacity: 0;
    }

    &.selected {
      background: $headers;
      border: 1px solid $headers;

      label {
        color: $white;
      }

      &:before {
        border: 2px solid $headers;
      }
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.radio-list.grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .radio-list-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid $gs-400;
    border-radius: $spacing-2;
    position: relative;
    transition: all 0.3s ease;
    height: 128px;
    flex: 0 1 48%;
    flex-direction: column;
    justify-content: center;

    svg {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 26px;
      z-index: 1;
    }

    label {
      cursor: pointer;
      color: $core-font;
      font-size: $body-small;
      font-weight: $w-label;
      width: 100%;
      margin: $spacing-2 0 0;
      z-index: 2;
      text-align: center;
      height: 100%;
      padding: $spacing-11 0 0;
      z-index: 2;
    }

    input {
      display: none;
      opacity: 0;
    }

    &.selected {
      background: $headers;
      border: 1px solid $headers;

      svg {
        filter: brightness(0) invert(1);
      }

      label {
        color: $white;
      }
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
