<!--
  * Component Name: Dual Text Block
  * Description: Dual Text blocks
  * Props:
      Content
  * Components:
      TextModule
  * Usage:
      <dual-text-block :content="value" />
-->

<template>
  <section class="dual-text-container" :content-id="props.content?.system?.id">
    <div
      :class="['grid-container', 'mx-auto', [!removePadding ? ['pt-12', 'pb-10', 'px-4', 'xxl:px-6'] : []]]"
    >
      <div class="dual-text-content">
        <!-- Content Block A  -->
        <div v-if="contentData.contentBlockA" class="dual-text-item m-bg-light p-6 mb-6 xl:mb-0 xl:mr-6">
          <TextContent :content="contentData.contentBlockA" />
        </div>
        <!-- Content Block B -->
        <div v-if="contentData.contentBlockB" class="dual-text-item m-bg-dark p-6">
          <TextContent :content="contentData.contentBlockB" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import TextContent from '#root/components/pageblocks/TextContent.vue'
import { useGetContent } from '#root/components/composables/getContent.js'

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  removePadding: { type: Boolean, default: false }
})

const contentData = computed(() => {
  return {
    contentBlockA: useGetContent(props.content, 'column_a.linkedItems[0].elements', null),
    contentBlockB: useGetContent(props.content, 'column_b.linkedItems[0].elements', null)
  }
})
</script>

<style lang="scss">
.dual-text-container {
  .dual-text-content {
    @include flex-parent(column, wrap, flex-start, flex-start);

    @include media-query('desktop') {
      @include flex-parent(row, nowrap, flex-start, stretch);
    }
  }

  .dual-text-item {
    flex: 1;
    width: 100%;
    border-radius: $g-radius;

    h2 {
      font-size: $h4-d;
      line-height: 2.8rem;
      margin-bottom: $spacing-2;
    }
  }

  .m-bg-light {
    background-color: $dual-text-item-light-bg;
    color: $dual-text-item-light-font;
  }

  .m-bg-dark {
    background-color: $dual-text-item-dark-bg;

    @include headings {
      color: $dual-text-item-dark-font;
    }

    a {
      color: $dual-text-item-dark-font;
    }

    [href^='tel:'] {
      color: $headers;
      background-color: $white;
    }

    p {
      color: $dual-text-item-dark-font;
    }
  }
}

.dual-text-item.m-bg-dark {
  .text-container {
    .links {
      display: flex;
      flex-wrap: wrap;

      &:empty {
        display: none;
      }

      .link-element {
        .m-secondary {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 0 $spacing-6;
          height: 56px;
          border-radius: $btn-radius;
          font-family: $primary-font;
          font-size: $body-small;
          line-height: 3.2rem;
          font-weight: $w-cta;
          text-align: center;
          text-decoration: none;
          color: white;
          background-color: transparent;
          border: 2px solid $white;
          cursor: pointer;

          &::before {
            width: 24px;
            height: 24px;
            background: white;
          }
        }
      }
    }
  }
}
</style>
