<!--
  * Component Name: FAQ Block
  * Description:
  * Props:
      content
  * Usage:
      <faq-block :content="value" />
-->
<template>
  <div class="grid-container mx-auto py-10 px-4 lg:py-12 xl:xxl:px-6" :content-id="props.content?.system?.id">
    <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <!-- Main Question Set -->
      <div class="col-span-full">
        <accordion-view :content="contentData.mainQuestionSet" />
      </div>
    </div>

    <!-- Popular Guides -->
    <BlogFeed :content="contentData.popularGuides" class="px-0 xxl:px-0" />

    <div class="grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12">
      <!-- Additional Question Sets -->
      <div
        v-for="(set, index) in contentData.additonalQuestionSets"
        :key="index"
        class="col-span-full xl:col-span-6 mb-10 lg:mb-12 last:mb-0"
      >
        <accordion-view :content="set" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

import AccordionView from '#root/components/pageblocks/AccordionView.vue'
import BlogFeed from '#root/components/pageblocks/BlogFeed.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    mainQuestionSet: useGetContent(props.content, 'main_question_set.linkedItems[0]', null),
    popularGuides: useGetContent(props.content, 'guides.linkedItems[0]', null),
    additonalQuestionSets: useGetContent(props.content, 'additional_question_sets.linkedItems', null)
  }
})
</script>
