<!--
  * Component Name: Sub Banner
  * Description: Banner used to replace main banner
  * Props:
      Content
  * Components:
  * Usage:
      <Component is="bannerType" :content="value" />
-->

<template>
  <div class="subbanner-container">
    <div class="grid-container mx-auto xl:px-4 xxl:px-6">
      <div class="subbanner grid gap-x-4 grid-cols-1 xl:grid-cols-12">
        <div class="banner-image xl:col-span-6">
          <picture>
            <source
              :srcset="helperImage.optimise(contentData.bannerImageDesktop)"
              media="(min-width: 1024px)"
              :width="contentData.bannerImageDesktop?.width || 'auto'"
              :height="contentData.bannerImageDesktop?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <source
              :srcset="helperImage.optimise(contentData.bannerImageTablet)"
              media="(min-width: 768px)"
              :width="contentData.bannerImageTablet?.width || 'auto'"
              :height="contentData.bannerImageTablet?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <img
              :src="helperImage.optimise(contentData.bannerImageMobile)"
              :alt="contentData.bannerImageDesktop?.description || 'Banner Image'"
              :width="contentData.bannerImageMobile?.width || 'auto'"
              :height="contentData.bannerImageMobile?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
          </picture>
        </div>
        <div class="banner-content xl:col-span-6">
          <ContentImage
            v-if="contentData.trustBadge"
            :content="contentData.trustBadge"
            :class="['trust-badge', contentData.trustBadgeType]"
          />
          <ClientOnly :over-ride="!contentData.appointmentSubBanner">
            <TextContent v-if="bannerContent" :content="bannerContent" class="content" />
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGlobalStore } from '#root/stores'
import { helperImage } from '#root/utils/helpers'
import TextContent from '#root/components/pageblocks/TextContent.vue'
import ContentImage from '#root/components/pageblocks/ContentImage.vue'
import ClientOnly from '#root/components/utils/ClientOnly.vue'

import getSubBannerContent from '#root/components/composables/getSubBannerContent.js'

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const { contentData } = getSubBannerContent(props)
const { callCenterOpen } = useGlobalStore()
const bannerContent = computed(() => {
  return callCenterOpen && contentData.value.appointmentSubBanner && contentData.value.appointmentAltContent
    ? contentData.value.appointmentAltContent
    : contentData.value.content
})
</script>

<style lang="scss">
.site-dignity {
  .subbanner-container {
    background: $support;
    width: 100%;
    overflow-x: hidden;

    .grid-container {
      max-width: unset;
      width: unset;

      @include media-query('desktop') {
        width: $g-desktop-breakpoint-min-width;
      }

      @include media-query('desktop-large') {
        width: $g-desktop-large-breakpoint-min-width;
      }
    }

    .subbanner {
      position: relative;
      overflow: hidden;

      .banner-image {
        border-bottom: 4px solid $primary;
        height: 300px;

        @include media-query('tablet') {
          height: 436px;
        }

        @include media-query('desktop') {
          border: none;
          top: 0;
          height: 100%;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .banner-content {
        padding: $spacing-6 $spacing-4;
        gap: 0;

        .toggle-callback-modal {
          color: $core-font;
          text-decoration: underline;
          font-weight: $w-heading;
          cursor: pointer;
        }

        .text-content {
          margin: 0;
        }

        h1 {
          margin: $spacing-4 0;
        }

        p {
          font-weight: $w-body;
          margin: 0;
        }

        .trust-badge {
          height: 23px;
          &.trustpilot {
            width: 204px;
          }
        }
      }

      @include media-query('phablet') {
        .banner-content {
          width: 100%;
          max-width: $g-phablet-breakpoint-min-width;
          margin: auto;
        }
      }

      @include media-query('tablet') {
        .banner-content {
          max-width: $g-tablet-breakpoint-min-width;
        }
      }

      @include media-query('desktop') {
        min-height: 290px;

        .banner-image {
          position: absolute;
          top: 0;
          right: 0;
          border: none;

          img {
            width: 404px;
            vertical-align: top;
          }
        }

        .banner-content {
          padding: $spacing-10 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
}
</style>
