<!--
  * Component: Key Benefits
  * Description: Short Description of component
  * Props:
      content
  * Components:
      linkElement
  * Usage:
      <key-benefits :content="value" />
-->

<template>
  <section
    class="key-benefits-container"
    :content-id="props.content?.system?.id"
    :class="{
      'm-grid': contentData.type === 'grid',
      'm-stacked': contentData.type === 'stacked',
      'm-column': contentData.type === 'column',
      'm-column-left': contentData.type === 'column_left_aligned'
    }"
  >
    <div class="grid-container mx-auto pt-5 pb-10 px-4 lg:pt-10 xxl:px-6">
      <!-- Markup for type stacked -->
      <div v-if="contentData.benefitItems && contentData.type === 'stacked'" class="key-benefits-wrapper">
        <div class="key-benefits-content mb-10">
          <div v-if="contentData.title" class="key-benefits-title" v-html="contentData.title" />
          <div v-if="contentData.content" class="key-benefits-copy my-6" v-html="contentData.content" />
          <LinkItem v-if="contentData.contentCta" :content="contentData.contentCta" :right-arrow="true" />
        </div>
        <div class="key-benefits-stacked-items">
          <div
            v-for="(benefit, index) in contentData.benefitItems"
            :key="index"
            class="key-benefits-card-wrapper"
          >
            <KeyBenefitCard :content="benefit" />
          </div>
        </div>
      </div>

      <!-- Markup for type grid & Grid 2 Column-->
      <div
        v-if="
          contentData.benefitItems && (contentData.type === 'grid' || contentData.type === 'grid_2_column')
        "
      >
        <div
          :class="
            contentData.type === 'grid'
              ? 'grid gap-x-4 grid-cols-4 lg:grid-cols-8 xl:grid-cols-12'
              : 'grid gap-10 grid-cols-4 lg:grid-cols-8 xl:grid-cols-12'
          "
        >
          <div class="heading col-span-full">
            <div v-if="contentData.title" class="key-benefits-title" v-html="contentData.title"></div>
            <div v-if="contentData.content" class="key-benefits-copy mt-2" v-html="contentData.content" />
          </div>

          <div
            v-for="(benefit, index) in contentData.benefitItems"
            :key="index"
            class="key-benefits-grid-items col-span-full"
            :class="contentData.type === 'grid' ? 'lg:col-span-4' : 'xl:col-span-6 m-grid_2_column'"
          >
            <KeyBenefitCard :content="benefit" />
          </div>
        </div>
        <div v-if="contentData.contentCta" class="key-benefits-grid-cta col-span-full mt-6">
          <LinkItem v-if="contentData.contentCta" :content="contentData.contentCta" :in-content="true" />
        </div>
      </div>

      <!-- Markup for type column -->
      <div
        v-if="
          contentData.benefitItems &&
          (contentData.type === 'column' || contentData.type === 'column_left_aligned')
        "
      >
        <div class="key-benefits-content mb-10">
          <div v-if="contentData.title" class="key-benefits-title mb-2" v-html="contentData.title" />
          <div v-if="contentData.content" class="key-benefits-copy" v-html="contentData.content" />
        </div>

        <div class="key-benefits-column-items">
          <div
            v-for="(benefit, index) in contentData.benefitItems"
            :key="index"
            class="key-benefits-card-wrapper"
          >
            <KeyBenefitCard :content="benefit" />
          </div>
        </div>

        <div v-if="contentData.contentCta" class="key-benefits-cta mt-10">
          <LinkItem :content="contentData.contentCta" :in-content="true" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'

import KeyBenefitCard from '#root/components/pageblocks/KeyBenefitCard.vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    type: useGetContent(props.content, 'type.value[0].codename', 'column'),
    title: useGetContent(props.content, 'title.value', null),
    content: useGetContent(props.content, 'content.value', null),
    contentCta: useGetContent(props.content, 'content_cta.linkedItems[0]', null),
    benefitItems: useGetContent(props.content, 'benefit_items.linkedItems', []),
    moduleCta: useGetContent(props.content, 'module_cta.linkedItems[0]', null)
  }
})
</script>

<style lang="scss">
.key-benefits-grid-items:nth-child(1),
.key-benefits-grid-items:nth-child(2) {
  margin-top: $spacing-10;

  @include media-query('tablet') {
    margin-top: 0;
  }
}

.key-benefits-title {
  @include pageblock-header();
}

.key-benefits-container.m-stacked {
  background-color: $background-accent;

  .key-benefits-title {
    margin-top: $spacing-6;
  }

  @include media-query('desktop') {
    margin: 120px 0;
    padding: 0;
  }
}

.key-benefits-grid-items.m-grid_2_column {
  margin-top: 0;

  .key-benefits-card {
    border-radius: $card-radius;
  }

  .key-benefits-card-content {
    text-align: center;

    @include media-query('phablet') {
      text-align: left;
    }
  }
}

.key-benefits-container.m-grid {
  @include media-query('tablet') {
    .key-benefits-title h2 {
      margin-bottom: $spacing-10;
    }
  }
}

.key-benefits-container.m-column,
.key-benefits-container.m-column-left {
  background-color: $support;

  .key-benefits-card {
    border-radius: $card-radius;
  }

  .grid-container {
    padding-top: 56px;
    padding-bottom: 56px;

    @include media-query('desktop') {
      padding-top: $spacing-12;
      padding-bottom: $spacing-12;
    }

    @include media-query('desktop-large') {
      padding-top: 104px;
      padding-bottom: 104px;
    }
  }

  .key-benefits-content {
    text-align: center;
  }

  .key-benefits-column-items {
    @include media-query('desktop') {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-4;
    }
  }

  .key-benefits-card-wrapper {
    margin: 0 auto;
    max-width: 400px;
  }

  .key-benefits-card-title {
    font-size: $h4-d;
    line-height: 120%;
  }

  .key-benefits-copy p,
  .key-benefits-card-copy p {
    font-size: $body-regular;
    line-height: 3rem;
  }

  .key-benefits-icon {
    width: 104px;
    flex: 0 0 104px;
    margin: 0;
  }

  .key-benefits-card-content {
    margin-top: $spacing-4;
  }

  .key-benefits-cta {
    text-align: center;
  }
}

.key-benefits-container.m-column-left {
  background-color: transparent;

  .key-benefits-content {
    text-align: left;
  }
}

.key-benefits-copy {
  p {
    a {
      text-decoration: underline;
    }
  }
}

.key-benefits-wrapper {
  @include media-query('desktop') {
    display: flex;
    justify-content: space-between;
    position: relative;

    .key-benefits-content,
    .key-benefits-stacked-items {
      flex: 0 0 50%;
    }

    .key-benefits-stacked-items {
      position: absolute;
      width: calc(50% - 16px); /* 16px is the padding from parent */
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  @include media-query('desktop-large') {
    .key-benefits-content {
      max-width: 608px;
      flex: 0 0 608px;
    }

    .key-benefits-stacked-items {
      max-width: 503px;
      right: 0px;
    }
  }
}

.key-benefits-card-wrapper {
  &:not(:last-child) {
    margin-bottom: $spacing-6;
  }
}

.key-benefits-grid-cta {
  text-align: center;
}

// plain list style
hr {
  width: 100%;
  display: block;
  border-top: 1px solid $gs-300;
  border: solid $gs-300;
  border-width: 1px 0 0 0;
  margin: 0;
}

//List with image
.m-list_with_image {
  .img {
    text-align: center;
    img {
      width: 100%;
      max-width: 256px;
      height: auto;
    }
  }

  .key-benefits-title {
    text-align: center;
  }
}
</style>
