<!--
  * Component Name: InputText
  * Description: Component to handle all types of inputs
  * Props:
      inputType, inputClass, wrapperClass, placeholder, formMin, formMax, maxLength,
      name, disabled, label, labelClass, subLabel, toolTip, error, mode, pattern
  * Components:
  * Usage:
      <input-text :propA="value" :propB="value" :propC="value" />
-->
<template>
  <div class="input-wrapper" :class="inputCSS.wrapper">
    <InputLabel
      :label="label"
      :label-class="labelClass"
      :for-label="name"
      :sub-label="useCleanText(subLabel)"
    />
    <div class="field-loading-container">
      <input
        :id="name"
        :value="modelValue"
        :type="inputType"
        :placeholder="placeholder"
        :min="formMin"
        :mask="mask"
        :max="formMax"
        :maxlength="maxLength"
        :name="name"
        :disabled="disabled"
        :class="[inputClass, { invalid: error, valid: isValidated, 'has-left-icon': euroIcon }]"
        :pattern="pattern"
        :inputmode="mode"
        class="p-4"
        :aria-label="ariaLabel"
        @blur.stop="[blurOnEvent($event), stripInputSpaces(), emit('blur', $event)]"
        @focus.stop="onFocus($event)"
        @change.stop="changeOnEvent"
        @keypress="keyPressEvent, checkPattern($event)"
        @input="onInput"
        @paste="checkPaste"
      />
      <SvgIcon v-if="euroIcon" name="euro-icon" class="icon" />
      <div
        v-if="validationCheckIcon || nameValidationType"
        :class="['validation-check', `${name}`, validationCheckIcon ? 'individually' : '']"
      >
        <LoadIcon v-if="isValidating" class="loading" />
        <InvalidIcon v-else-if="error" class="invalid-icon" />
        <ValidatedIcon v-else-if="isValidated" class="valid-icon" />
      </div>
    </div>
    <small
      v-if="error && typeof error === 'string' && !disabledError"
      class="form-error"
      :class="props.errorFix ? 'error-spacer' : ''"
    >
      <!-- If it's a correctable error we assume it's a template -->
      <ExclamationIcon v-if="props.errorFix" class="exclamation-icon" />
      <span>
        <v-render :value="error" :mapping="errorTemplateMapping" />
      </span>
    </small>
  </div>
</template>

<script setup>
import { computed, h } from 'vue'
import { useCleanText } from '#root/components/composables/getContent.js'
import { helperForm, helperRender } from '#root/utils/helpers'

import InputLabel from '#root/components/inputs/InputLabel.vue'
import ValidatedIcon from '#root/assets/images/icons/validated-icon.svg'
import InvalidIcon from '#root/assets/images/icons/validation-error-icon.svg'
import LoadIcon from '#root/assets/images/icons/load-icon.svg'
import SvgIcon from '#root/components/utils/SvgIcon.vue'
import ExclamationIcon from '#root/assets/images/icons/exclamation-icon.svg'

const VRender = helperRender.templateRendererFactory({ allowHTML: true })

const props = defineProps({
  modelValue: { type: String, default: '' },
  inputType: { type: String, default: 'text' },
  inputClass: { type: String, default: null },
  wrapperClass: { type: String, default: '' },
  placeholder: { type: String, default: null },
  formMin: { type: Number, default: null },
  formMax: { type: Number, default: null },
  maxLength: { type: Number, default: null },
  name: { type: String, default: null },
  disabled: { type: String, default: null },
  label: { type: String, default: null },
  labelClass: { type: String, default: 'normal' },
  subLabel: { type: String, default: null },
  ariaLabel: { type: String, default: null },
  mode: { type: String, default: null },
  pattern: { type: String, default: null },
  pastePattern: { type: RegExp, default: null },
  mask: { type: String, default: null },
  rules: { type: Object, default: () => ({}) },
  blurOnEvent: { type: Function, default: () => ({}) },
  changeOnEvent: { type: Function, default: () => ({}) },
  keyPressEvent: { type: Function, default: () => ({}) },
  disabledError: { type: Boolean, default: false },
  stripSpaces: { type: Boolean, default: false },
  validationCheckIcon: { type: Boolean, default: true },
  nameValidationType: { type: Boolean, default: false },
  isValidated: { type: Boolean, default: false },
  isValidating: { type: Boolean, default: false },
  error: { type: [Boolean, String], default: null },
  errorFix: { type: String, default: null },
  veeFree: { type: Boolean, default: false },
  euroIcon: { type: Boolean, default: null }
})

const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'validate'])

const onFocus = (event) => {
  emit('focus', event)
}

const onInput = (event) => {
  event.stopPropagation()
  event.preventDefault()

  updateValue(event.target.value)
}

const fixError = () => {
  const value = props.errorFix

  updateValue(value)
  emit('validate', { value, force: true })
}

const updateValue = (value) => {
  emit('update:modelValue', value)
}

const stripInputSpaces = () => {
  if (props.stripSpaces && props.modelValue) {
    updateValue(
      props.modelValue
        .split('')
        .filter((e) => e.trim().length)
        .join('')
    )
  }
}

// prevent user input if the pattern is not matched

const checkPattern = (e) => helperForm.getCheckPattern(e, props.pattern)

const checkPaste = (e) => {
  if (props.pastePattern) {
    e.preventDefault()
    e.stopPropagation()
    const pasteData = (e.clipboardData || Window.clipboardData).getData('text')
    const cleanedString = pasteData.replace(props.pastePattern, '')
    updateValue(cleanedString)
  }
}

const inputCSS = computed(() => {
  return {
    wrapper: props.wrapperClass
  }
})

const errorTemplateMapping = computed(() => {
  return {
    CORRECTED: h('a', { class: 'error-fix-suggestion', onClick: () => fixError() }, props.errorFix + '?')
  }
})
</script>

<style lang="scss">
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.field-loading-container {
  position: relative;

  .validation-check {
    position: absolute;
    content: '';
    width: 32px;
    height: 32px;
    right: -40px;
    top: 6px;
  }

  .loading {
    animation: spin 2s linear infinite;
  }

  .icon {
    position: absolute;
    top: -50%;
    transform: translateY(-50%);
    top: 50%;
    left: 16px;
  }
}

.input-wrapper {
  .field-loading-container {
    .has-left-icon {
      padding-left: 48px;
    }
  }
}

.inline-input-group {
  position: relative;
  .field-loading-container {
    .validation-check {
      top: -49px;

      @include media-query('phablet') {
        top: 6px;
      }

      &.individually {
        top: 6px;
      }

      &.individually.firstName {
        @include media-query('phablet') {
          opacity: 0;
        }
      }
    }
  }
}

a.error-fix-suggestion {
  cursor: pointer;
  color: $orange;
  text-decoration: underline;
}

.exclamation-icon {
  margin-right: $spacing-1;
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.form-error.error-spacer {
  display: flex;
  align-items: flex-start;
  margin-top: $spacing-5;
  width: calc(100% + 48px);
}
</style>
