import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#153758",
      "fill-rule": "evenodd",
      d: "M24 12c0-6.614-5.386-12-12-12S0 5.386 0 12s5.386 12 12 12 12-5.386 12-12ZM1.92 12C1.92 6.446 6.446 1.92 12 1.92S22.08 6.446 22.08 12 17.554 22.08 12 22.08 1.92 17.554 1.92 12Zm8.969-6.667v2.222h2.222V5.333H10.89Zm0 12.223V9.778h2.222v7.778H10.89Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }