<!--
  * Component Name: Incentive
  * Description: Different versions of incentive.
  * Props:
      Content, Type
  * Components:
      Link
  * Usage:
      <incentive :content="content" />
-->
<template>
  <section :class="['incentive-container', incentiveClasses]">
    <!-- Type Banner -->
    <div v-if="contentData.type === 'banner'" class="banner">
      <picture v-if="bannerImage">
        <source
          :srcset="helperImage.optimise(contentData.bannerImageDesktop)"
          media="(min-width: 1280px)"
          :width="contentData.bannerImageDesktop?.width || 'auto'"
          :height="contentData.bannerImageDesktop?.height || 'auto'"
          fetchpriority="high"
          loading="eager"
        />
        <img
          :src="helperImage.optimise(contentData.bannerImageMobile)"
          :alt="formImage?.description || 'Form Image'"
          width="auto"
          height="auto"
          fetchpriority="high"
          loading="eager"
        />
      </picture>
    </div>
    <!-- Type Form Top & Side -->
    <div v-if="contentData.type === 'form'" class="form">
      <picture v-if="formImage">
        <source
          :srcset="helperImage.optimise(contentData.formImageDesktop)"
          media="(min-width: 1024px)"
          :width="contentData.foregroundImageDesktop?.width || 'auto'"
          :height="contentData.foregroundImageDesktop?.height || 'auto'"
          fetchpriority="high"
          loading="eager"
        />
        <source
          :srcset="helperImage.optimise(contentData.formImageTablet)"
          media="(min-width: 560px)"
          :width="contentData.foregroundImageTablet?.width || 'auto'"
          :height="contentData.foregroundImageTablet?.height || 'auto'"
          fetchpriority="high"
          loading="eager"
        />
        <img
          :src="helperImage.optimise(contentData.formImageMobile)"
          :alt="formImage?.description || 'Form Image'"
          class="desktop"
          width="auto"
          height="auto"
          fetchpriority="high"
          loading="eager"
        />
      </picture>
    </div>
    <!-- Type Form Top -->
    <div
      v-if="contentData.type === 'form_top_banner' || contentData.type === 'form_top_banner_small'"
      class="form_top grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12"
    >
      <div :class="['col-span-full', formTopCols]">
        <picture v-if="formImage">
          <source
            :srcset="helperImage.optimise(contentData.formImageDesktop)"
            media="(min-width: 1024px)"
            :width="contentData.foregroundImageDesktop?.width || 'auto'"
            :height="contentData.foregroundImageDesktop?.height || 'auto'"
            fetchpriority="high"
            loading="eager"
          />
          <source
            :srcset="helperImage.optimise(contentData.formImageTablet)"
            media="(min-width: 768px)"
            :width="contentData.foregroundImageTablet?.width || 'auto'"
            :height="contentData.foregroundImageTablet?.height || 'auto'"
            fetchpriority="high"
            loading="eager"
          />
          <img
            :src="helperImage.optimise(contentData.formImageMobile)"
            :alt="formImage?.description || 'Form Image'"
            width="auto"
            height="auto"
            fetchpriority="high"
            loading="eager"
          />
        </picture>
      </div>
    </div>
    <!-- Gift Block -->
    <div v-if="contentData.type === 'gift_block'" class="gift">
      <div class="badge">
        <img
          v-if="contentData.badge.url"
          :src="helperImage.optimise(contentData.badge)"
          :alt="contentData.badge?.description || 'Badge Image'"
          width="160"
          height="160"
          loading="lazy"
        />
      </div>
      <div class="brands">
        <div v-for="(brand, i) in contentData.brands" :key="i" class="brand">
          <img
            v-if="brand.url"
            :src="helperImage.optimise(brand)"
            :alt="brand.description?.description || 'Brand Image'"
            :class="brand.description"
            :width="imageSizings(brand.description).width"
            :height="imageSizings(brand.description).height"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { helperImage } from '#root/utils/helpers'
import { useGetContent } from '#root/components/composables/getContent.js'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) }
})

const incentiveClasses = computed(() => {
  if (contentData.value.type === 'form') {
    return ['form-left', 'px-4', 'pt-6']
  } else if (
    contentData.value.type === 'form_top_banner' ||
    contentData.value.type === 'form_top_banner_small'
  ) {
    return ['top-banner', 'px-4', 'pt-6']
  } else {
    return []
  }
})

const formTopCols = computed(() => {
  if (contentData.value.type === 'form_top_banner') {
    return 'xl:col-span-8 xl:col-start-3'
  } else if (contentData.value.type === 'form_top_banner_small') {
    return 'xl:col-span-6 xl:col-start-4 pb-4'
  } else {
    return ''
  }
})

const contentData = computed(() => {
  const bannerImageDesktop = useGetContent(props.content, 'banner_image_desktop.value[0]', null)
  const bannerImageMobile = useGetContent(props.content, 'banner_image_mobile.value[0]', null)
  const formMobile = useGetContent(props.content, 'form_mobile.value[0]', null)
  const formTablet = useGetContent(props.content, 'form_tablet.value[0]', null)
  const formDesktop = useGetContent(props.content, 'form_desktop.value[0]', null)

  return {
    bannerImage: bannerImageDesktop || bannerImageMobile,
    bannerImageMobile,
    bannerImageDesktop,
    brands: useGetContent(props.content, 'brands.value', null),
    badge: useGetContent(props.content, 'badge.value[0]', null),
    type: useGetContent(props.content, 'type.value[0].codename', null),
    formMotif: useGetContent(props.content, 'form_motif.value[0]', null),
    formImageMobile: formMobile,
    formImageTablet: formTablet,
    formImageDesktop: formDesktop,
    formImage: formMobile || formTablet || formDesktop
  }
})

const bannerImage = computed(() => contentData.value.bannerImage || null)
const formImage = computed(() => contentData.value.formImage || null)

const imageSizings = (brand) => {
  switch (brand) {
    case 'amazon':
      return {
        width: '125',
        height: '26'
      }
    case 'ms':
      return {
        width: '45',
        height: '27'
      }
    case 'tesco':
      return {
        width: '70',
        height: '20'
      }
    default:
      return {
        width: 'auto',
        height: 'auto'
      }
  }
}
</script>

<style lang="scss">
.top-banner {
  max-width: 100%;
  margin: auto;
  position: relative;
  padding: $spacing-4 $spacing-4 0;

  @include media-query('phablet') {
    max-width: $g-phablet-breakpoint-min-width;
  }

  @include media-query('desktop') {
    padding: $spacing-6 $spacing-4 0;
    max-width: $g-desktop-breakpoint-min-width;

    .form-submit-container {
      text-align: right;
    }
  }

  @include media-query('desktop-large') {
    max-width: $g-desktop-large-breakpoint-min-width;
  }
}

.banner {
  img {
    width: auto;
    height: 144px;
  }

  @include media-query('phablet') {
    img {
      height: 217px;
    }
  }

  @include media-query('desktop') {
    &.top_right {
      top: -20px;
      bottom: unset;
    }

    img {
      height: 173px;
    }
  }

  @include media-query('desktop-large') {
    &.top_right {
      top: -20px;
      bottom: unset;
    }

    img {
      width: 242px;
      height: auto;
    }
  }
}

.gift {
  display: flex;
  flex-direction: row;
  align-items: center;

  .badge {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 $spacing-4 0 0;

    img {
      width: 100%;
      max-width: 135px;
      height: auto;
    }
  }

  .brands {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    padding: $spacing-4;
    border-radius: 8px;

    .brand {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: $spacing-6;
      }

      img {
        width: 100%;
        height: auto;

        &.amazon {
          max-width: 125px;
        }

        &.ms {
          max-width: 45px;
        }

        &.tesco {
          max-width: 70px;
        }
      }
    }
  }

  @include media-query('phone') {
    .badge {
      img {
        max-width: 160px;
      }
    }

    .brands {
      width: auto;
      padding: $spacing-6;

      .brand {
        img {
          &.amazon {
            max-width: 146px;
          }

          &.ms {
            max-width: 55px;
          }

          &.tesco {
            max-width: 81px;
          }
        }
      }
    }
  }

  @include media-query('phablet') {
    .badge {
      img {
        max-width: 226px;
      }
    }

    .brands {
      justify-content: space-around;
      padding: $spacing-6;

      .brand {
        img {
          &.amazon {
            max-width: 190px;
          }

          &.ms {
            max-width: 72px;
          }

          &.tesco {
            max-width: 105px;
          }
        }
      }
    }
  }

  @include media-query('tablet') {
    .badge {
      justify-content: right;
    }

    .brands {
      width: 30%;
    }
  }

  @include media-query('desktop') {
    .badge {
      justify-content: center;
      margin: 0 auto;
    }

    .brands {
      width: 40%;
    }
  }
}

.form {
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: auto;
    max-width: 528px;
    min-width: unset;
    margin: 0 auto;

    @include media-query('desktop') {
      position: absolute;
      display: block;
      width: auto;
      right: 82px;
      top: 215px;
    }

    @include media-query('desktop-large') {
      right: -123px;
    }
  }
}

.form_top {
  position: relative;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }

  .desktop {
    display: none;
  }

  .mobile {
    max-width: 528px;
    min-width: unset;
    margin: 0 auto;
  }

  @include media-query('phablet') {
    .desktop {
      display: block;
    }
  }

  @include media-query('desktop') {
    max-width: $g-desktop-large-breakpoint-min-width;

    .mobile {
      display: none;
    }
  }
}

.form-left {
  max-width: 100%;
  margin: auto;

  @include media-query('desktop-large') {
    max-width: 712px;
    margin: 0 auto;
  }
}

.form_top {
  img {
    max-width: 712px;
    margin: 0 auto;
  }
}
</style>
