<!--
  * Component Name: Blog Feed
  * Description: Max 3 cards, to display the latest article, title, link to the all blogs post, article object.
  * Props:
       content, nofollow
  * Components:
      Card
  * Usage:
      <blog-feed :content="value" :nofollow="nofollow" />
-->

<template>
  <div
    class="grid-container mx-auto py-10 xl:py-12"
    :class="contentData.type === 'stacked' ? 'px-0' : 'px-4 xxl:px-6'"
    :content-id="props.content?.system?.id"
  >
    <section class="latest-articles">
      <div class="latest-articles-header mb-10">
        <h3 v-if="contentData.title">
          {{ contentData.title }}
        </h3>
        <LinkItem
          v-if="contentData.viewAllLink"
          :content="contentData.viewAllLink"
          :gtm="gtm.readAllGuidesCTA"
          :right-arrow="true"
          :nofollow="nofollow"
        />
      </div>
      <section
        class="latest-articles-cards"
        :class="
          contentData.type === 'stacked'
            ? 'stacked grid gap-y-6 grid-flow-row lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-4'
            : 'grid grid-flow-row xl:grid-flow-col auto-cols-fr gap-x-4 gap-y-10'
        "
      >
        <template v-if="isLatestReady">
          <article-card
            v-for="(article, index) in latest"
            :key="index"
            :content="
              article.system.type === 'article' ? article.elements.basic_info.linkedItems[0] : article
            "
            :position="index + 1"
            :nofollow="nofollow"
          />
        </template>
      </section>
    </section>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useGetContent, useGetContentOptions } from '#root/components/composables/getContent.js'
import { useArticleStore, useContentStore } from '#root/stores'

const contentStore = useContentStore()
const articleStore = useArticleStore()

import ArticleCard from '#root/components/pageblocks/ArticleCard.vue'
import LinkItem from '#root/components/buttons/LinkItem.vue'

const props = defineProps({
  content: { type: Object, required: true, default: () => ({}) },
  nofollow: { type: String, required: false, default: '' }
})

onMounted(() => {
  setLatest()
})

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'title.value', null),
    viewAllLink: useGetContent(props.content, 'view_all_link.linkedItems[0]', false),
    articleType: useGetContent(props.content, 'article_type.value[0].codename', 'blog_article'),
    type: useGetContent(props.content, 'type.value[0].codename', false)
  }
})

const gtm = computed(() => {
  return {
    readAllGuidesCTA: useGetContentOptions('read_all_guides_cta.value', '')
  }
})

let latest = ref([])
let isLatestReady = ref(false)

const setLatest = async () => {
  const mode = useGetContent(props.content, 'mode.value[0].name', 'Auto')
  let articles = []

  if (mode === 'Auto') {
    await contentStore.getArticleLatest({ articleType: contentData.value.articleType })
    articles = articleStore.latest[contentData.value.articleType]
  } else {
    articles = useGetContent(props.content, 'selected_articles.linkedItems', [])
  }

  latest.value = articles
  isLatestReady.value = true
}
</script>

<style lang="scss">
.latest-articles-header {
  @include pageblock-header();

  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0;
  }

  .link {
    font-weight: $w-link;
    font-size: $body-large;
    line-height: 32px;
    color: $core-font;
    text-decoration: none;

    &:hover {
      color: $cta;
    }
  }
}

.stacked {
  .card {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: $g-radius;

    .card-image-container {
      height: 245px;
      max-width: unset;

      @include media-query('phablet') {
        height: 377px;
        max-height: 377px;
      }

      @include media-query('tablet') {
        height: 257px;
        max-height: 257px;
      }

      @include media-query('desktop') {
        height: 168px;
        max-height: 168px;
      }

      @include media-query('desktop-large') {
        height: 211px;
        max-height: 211px;
      }
    }
  }
}
</style>
