<!--
  * Component Name: InputRadioList
  * Description: Input Radio List
  * Props:
      steps, content
  * Components:
  * Usage:
      <InpuRadio :content="content" />
-->
<template>
  <div>
    <InputLabel :label="label" :label-class="labelClass" :for-label="name" :sub-label="subLabel" />
    <div class="radio-list" :class="{ valid: isValidated, invalid: error }">
      <div
        v-for="(option, i) in options"
        :key="i"
        class="radio-list-item"
        :class="{ selected: modelValue === option.codename }"
        role="button"
        tabindex="0"
        @click.prevent="[selectOption(option.codename), blurOnEvent($event), emit('blur', $event)]"
        @keyup.enter.prevent="[selectOption(option.codename), blurOnEvent($event), emit('blur', $event)]"
        :name="option.name"
      >
        <input
          :id="option.codename"
          :name="option.codename"
          :value="option.codename"
          type="radio"
          class="raido-list"
        />
        <label :for="option.codename" :data-gtm="`${contentData.stepName.toLowerCase()} position ${i + 1}`">
          {{ option.name }}
        </label>
      </div>
      <div v-if="props.validationCheckIcon" class="validation-check">
        <LoadIcon v-if="isValidating" class="loading" />
        <InvalidIcon v-else-if="error" class="invalid-icon" />
        <ValidatedIcon v-else-if="isValidated" class="valid-icon" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGetContent } from '#root/components/composables/getContent.js'
import InputLabel from '#root/components/inputs/InputLabel.vue'
import ValidatedIcon from '#root/assets/images/icons/validated-icon.svg'
import InvalidIcon from '#root/assets/images/icons/validation-error-icon.svg'
import LoadIcon from '#root/assets/images/icons/load-icon.svg'

const props = defineProps({
  content: { type: Object, default: () => ({}) },
  modelValue: { type: [String, Number], default: '' },
  options: { type: Array, default: () => [] },
  name: { type: String, default: '' },
  inputClass: { type: String, default: '' },
  wrapperClass: { type: String, default: '' },
  disabled: { type: String, default: '' },
  label: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  subLabel: { type: String, default: '' },
  errorMessage: { type: String, default: '' },
  isValidated: { type: Boolean, default: false },
  isValidating: { type: Boolean, default: false },
  validationCheckIcon: { type: Boolean, default: true },
  error: { type: [Boolean, String], default: null },
  blurOnEvent: { type: Function, default: () => ({}) },
  placeholder: { type: String, default: '' },
  changeOnEvent: { type: Function, default: () => ({}) },
  rules: { type: Object, default: () => ({}) }
})

const contentData = computed(() => {
  return {
    stepName: useGetContent(props.content, 'step_name.value', '')
  }
})

const emit = defineEmits(['update:modelValue', 'input', 'blur'])

const selectOption = (value) => {
  emit('update:modelValue', value)
  emit('input', value)
}
</script>

<style lang="scss" scoped>
.radio-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  position: relative;

  .radio-list-item {
    background: $white;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: $input-radius;
    border: 1px solid $gs-500;
    position: relative;
    transition: all 0.3s ease;
    flex: 46%;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-2 $spacing-4;
    height: 56px;

    @include media-query('phablet') {
      flex: 20%;
    }

    label {
      cursor: pointer;
      color: $core-font;
      font-size: 1.6rem;
      line-height: 24px;
      font-weight: 600;
      width: 100%;
      text-align: center;
      z-index: 2;
    }

    input {
      display: none;
      opacity: 0;
    }

    &.selected {
      background: $headers;
      border: 1px solid $headers;

      svg {
        width: 40px;
        height: 40px;
        filter: brightness(0) invert(1);
      }

      label {
        color: $white;
      }
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &.invalid {
    .radio-list-item {
      border: 2px solid $red;

      label {
        color: $red;
      }
    }
  }

  .validation-check {
    position: absolute;
    top: 84px;
    right: -40px;

    @include media-query('phablet') {
      top: 14px;
    }
  }
}
</style>
