<template>
  <div :class="['landing-page-template', ...vwoKeys, brandClass, pageButtonColour ? 'page-btn-colour' : '']">
    <!-- Header -->
    <GlobalHeader
      v-if="pageHeader || globalHeader"
      :content="pageHeader || globalHeader"
      :nofollow="noFollow"
      :is-homepage="isHomepage"
      :content-id="contentHeaderId"
      :cookie="cookies"
    />

    <ClientOnly v-if="trustPilot">
      <TrustPilot :content="trustPilot" codename="trustPilot.system.codename" />
    </ClientOnly>

    <BreadCrumb v-if="showBreadcrumbs" :nofollow="noFollow" />

    <!-- Banner -->
    <component
      :is="bannerType"
      v-if="bannerType && bannerContent"
      key="banner"
      :content="bannerContent"
      :content-id="contentBannerId"
    />

    <!-- Page Blocks -->
    <div
      v-for="(pageBlock, i) in pageBlocks"
      :key="`${pageBlock.system.type}-${i} || ${i}`"
      :codename="pageBlock.system.type"
      class="page-block"
    >
      <!-- Any rendering occurring on this component will rerender this loop -->
      <!-- Modals moved to its own component to stop forms resetting on modal state change -->
      <component
        :is="useGetComponentByType(pageBlock.system.type, pageBlock)"
        v-if="!['trust_pilot_model', 'text_model'].includes(pageBlock.system.type)"
        :key="`${pageBlock.system.type}-${i}`"
        :wrapper-class="useGetWrapperClassByType(pageBlock.system.type, pageBlock)"
        :content="pageBlock"
      />

      <ClientOnly v-if="pageBlock.system.type === 'trust_pilot_model'">
        <TrustPilot :key="`${pageBlock.system.type}-${i}`" :content="pageBlock" style-height="240px" />
      </ClientOnly>
      <div
        v-if="pageBlock.system.type === 'text_model'"
        class="grid-container mx-auto py-10 xxl:py-11 px-4 xxl:px-6"
      >
        <component
          :is="useGetComponentByType(pageBlock.system.type, pageBlock)"
          :key="`${pageBlock.system.type}-${i}`"
          :content="pageBlock"
        />
      </div>
    </div>
    <!-- Footer -->
    <GlobalFooter
      v-if="pageFooter || globalFooter"
      :content="pageFooter || globalFooter"
      :nofollow="noFollow"
      :content-id="contentFooterId"
    />
  </div>

  <template v-if="afterMount">
    <Modals />
  </template>
</template>

<script setup>
import { onMounted, onUpdated, computed, defineAsyncComponent, onBeforeMount, ref } from 'vue'
import { usePageContext } from '#root/renderer/usePageContext'
import { useGetComponentByType, useGetWrapperClassByType } from '#root/components/composables/getComponent.js'
import { useSetBGColourPageOption } from '#root/components/composables/setBGColor.js'
import { useVWOStore } from '#root/stores'

import {
  useGetContentPage,
  useGetContentFooter,
  useGetContentHeader,
  useGetContentOptions
} from '#root/components/composables/getContent.js'

import GlobalHeader from '#root/components/global/GlobalHeader.vue'
import GlobalFooter from '#root/components/global/GlobalFooter.vue'
import BreadCrumb from '#root/components/pageblocks/BreadCrumb.vue'
import ClientOnly from '#root/components/utils/ClientOnly.vue'

import MainBanner from '#MainBanner'
import SubBanner from '#SubBanner'

const Modals = defineAsyncComponent(() => import('#root/components/modals/Modals.vue'))
const TrustPilot = defineAsyncComponent(() => import('#root/components/pageblocks/TrustPilot.vue'))

const pageContext = usePageContext()
const vwoStore = useVWOStore()
const afterMount = ref(false)
const brandClass = computed(() => {
  return pageContext.env.BRAND ? `${pageContext.env.BRAND}-layout` : ''
})
const pageBlocks = computed(() => {
  return useGetContentPage('page_blocks.linkedItems', null)
})
const bannerContent = computed(() => {
  return useGetContentPage('banner.linkedItems[0].elements', null)
})
const pageButtonColour = computed(() => {
  return !!useGetContentPage('button_colour.value[0].name', false)
})
let pageButtonColourVar = ref('')

const bannerType = computed(() => {
  if (useGetContentPage('banner.linkedItems[0].system.type', null) === 'sub_banner_model') {
    return SubBanner
  } else {
    return MainBanner
  }
})

const trustPilot = computed(() => {
  return (
    useGetContentPage('trust_pilot.linkedItems[0]', null) ||
    useGetContentPage('trust_pilot_module.linkedItems[0]', null)
  )
})
const globalHeader = computed(() => {
  return useGetContentHeader('elements', null)
})
const pageHeader = computed(() => {
  return useGetContentPage('header.linkedItems[0].elements', null)
})
const isHomepage = computed(() => {
  return pageContext.urlPathname === '/'
})
const globalFooter = computed(() => {
  return useGetContentFooter('elements', null)
})
const pageFooter = computed(() => {
  return useGetContentPage('footer.linkedItems[0].elements', null)
})
const showBreadcrumbs = computed(() => {
  return useGetContentPage('show_breadcrumbs.value[0].codename', 'no') === 'yes'
})
const noFollow = computed(() => {
  return useGetContentPage('enable_nofollow.value[0].codename', null)
})
const contentHeaderId = computed(() => {
  return pageHeader.value ? useGetContentPage('header.system.id', '') : useGetContentHeader('system.id', '')
})
const contentFooterId = computed(() => {
  return pageFooter.value ? useGetContentPage('footer.system.id', '') : useGetContentFooter('system.id', '')
})
const contentBannerId = computed(() => {
  return useGetContentPage('banner.linkedItems[0].system.id', '')
})

const cookies = computed(() => {
  return useGetContentOptions('cookies.linkedItems[0]', null)
})

const vwoKeys = computed(() => {
  return vwoStore.vwoActivatedCampaigns.map((campaign) => {
    if (campaign.assignedVariantName === 'Variation-1') {
      return campaign.campaign_key.value
    }
  })
})

const noticeBarMargins = () => {
  const noticeBars = document.querySelectorAll('.landing-page-template > [codename="notice_bar_model"]')
  noticeBars.forEach((noticeBar, index) => {
    if (noticeBars.length === 1) {
      noticeBar.style.marginTop = '40px'
      noticeBar.style.marginBottom = '40px'
    } else if (index === 0) {
      noticeBar.style.marginTop = '40px'
    } else if (index === noticeBars.length - 1) {
      noticeBar.style.marginBottom = '40px'
    } else {
      noticeBar.style.marginTop = '16px'
      noticeBar.style.marginBottom = '16px'
    }
  })
}

const setPageButtonColour = () => {
  useSetBGColourPageOption(pageButtonColourVar, 'button_colour.value[0].name')
}

onBeforeMount(() => {
  setPageButtonColour()
})

onMounted(() => {
  noticeBarMargins()
  afterMount.value = true
})

onUpdated(() => {
  setPageButtonColour()
  noticeBarMargins()
})
</script>

<style lang="scss">
.landing-page-template.page-btn-colour {
  a.cta-btn.m-primary,
  button.cta-btn.m-primary {
    background-color: v-bind(pageButtonColourVar);
    transition: all 0.3s ease;

    &:active,
    &:focus {
      outline: 4px solid color-mix(in srgb, v-bind(pageButtonColourVar), #000 15%);
      box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.32);
    }

    &:hover {
      background-color: color-mix(in srgb, v-bind(pageButtonColourVar), #000 15%);
      cursor: pointer;
    }

    &:disabled {
      pointer-events: none;
      background: $gs-200;
      color: $gs-500;
    }
  }

  .sticky-nav-container {
    background: v-bind(pageButtonColourVar);

    @include media-query('desktop') {
      background: $headers;
    }
  }
}
</style>
