import { defineAsyncComponent, hydrateOnVisible } from 'vue'

import KeyBenefits from '#root/components/pageblocks/KeyBenefits.vue'
import BannerContent from '#root/components/banners/BannerContent.vue'
import TextContent from '#root/components/pageblocks/TextContent.vue'
import MultiStepForm from '#root/components/forms/multi-step/MultiStepForm.vue'
import GenericForm from '#root/components/forms/GenericForm.vue'
import CoverIncentive from '#root/components/pageblocks/CoverIncentive.vue'
import CompareTable from '#root/components/pageblocks/CompareTable.vue'
import FaqBlock from '#root/components/pageblocks/FaqBlock.vue'
import TextMediaBlock from '#root/components/pageblocks/TextMediaBlock.vue'
import DualTextBlock from '#root/components/pageblocks/DualTextBlock.vue'
import MultiCardPanel from '#root/components/pageblocks/MultiCardPanel.vue'
import TabbedPanel from '#root/components/pageblocks/TabbedPanel.vue'

export const useGetComponentByType = (type, data) => {
  if (!data) return null
  switch (type) {
    case 'key_benefits_model':
      return KeyBenefits
    case 'banner_content_model':
      return BannerContent
    case 'text_model':
      return TextContent
    case 'multi_step_form_model':
      return MultiStepForm
    case 'form_model':
      return GenericForm
    case 'incentive_model':
      return CoverIncentive
    case 'compare_table_model':
      return CompareTable
    case 'faq_block_model':
      return FaqBlock
    case 'text_media_block_model':
      return TextMediaBlock
    case 'dual_text_block_model':
      return DualTextBlock
    case 'multi_card_panel_model':
      return MultiCardPanel
    case 'tabbed_panel_model':
      return TabbedPanel
    case 'page_block_wrapper_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/PageBlockWrapper.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'appointment_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/AppointmentSelector.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'calculator_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/CoverCalculator.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'funeral_calculator_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/FuneralCostCalculator.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'steps_compare_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/StepsCompare.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'table_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/ContentTable.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'reviews_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/CustomerReviews.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'award_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/AwardsBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'award_block_slider_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/AwardsBlockSlider.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'award_bar_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/AwardsBar.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'award_card_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/AwardsCard.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'cta_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/CtaBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'content_cta_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/ContentCtaBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'gift_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/GiftBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'gift_block_text_media':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/GiftBlockTextMedia.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'product_cards_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/ProductCards.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'blog_feed_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/BlogFeed.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'blog_listing_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/BlogListing.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'full_width_text_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/FullWidthTextBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'key_benefits_list_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/KeyBenefitsList.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'dual_card_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/DualCardBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'double_cta_block_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/DoubleCtaBlock.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'notice_bar_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/NoticeBar.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'trust_pilot_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/TrustPilot.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'sticky_nav_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/StickyNav.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'unsubscribe_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/EmailUnsubscribe.vue'),
        hydrate: hydrateOnVisible()
      })
    case 'thank_you_funnel_model':
      return defineAsyncComponent({
        loader: () => import('#root/components/pageblocks/ThankYouFunnel.vue'),
        hydrate: hydrateOnVisible()
      })
    default:
      return null
  }
}

export const useGetWrapperClassByType = (type, data) => {
  if (!data) return null
  switch (type) {
    case 'multi_step_form_model':
      return `multistep-quote-container p-4 sm:p-6 ${
        data.elements.form_style?.value[0]?.codename ? data.elements.form_style.value[0].codename : 'left'
      }`
    case 'form_model':
      return `${
        data.elements.form_style?.value[0]?.codename ? data.elements.form_style.value[0].codename : 'left'
      }`
    default:
      return null
  }
}
