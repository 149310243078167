<!--
  * Component Name: InputSelect
  * Description: Select Input Type
  * Props:
      inputClass, wrapperClass, disabled, label, subLabel,
      toolTip, errorMessage, options, placeholder
  * Components:

  * Usage:
      <input-select :propA="value" :propB="value" :propC="value" />
-->

<template>
  <div class="input-wrapper mb-8" :class="wrapperClass">
    <div class="dropdown">
      <input-label
        :label="label"
        :label-class="labelClass"
        :for-label="name"
        :sub-label="subLabel"
        @click="opened = !opened"
        @keyup.enter.prevent="opened = !opened"
      />
      <div
        class="opener"
        :class="{ unconfirmed: !confirmed }"
        @keyup.enter.prevent="opened = !opened"
        @click="opened = !opened"
        role="button"
        tabIndex="0"
      >
        {{ model.text }}
        <time-icon v-if="timeIcon" class="icon" />
        <date-icon v-if="dateIcon" class="icon" />
      </div>
      <div class="dropdown-list" :class="{ opened: opened }">
        <ul>
          <li
            v-for="(option, index) in options"
            :key="index"
            :value="{ text: option.text, value: option.value }"
            @click="setValue(option)"
            @keyup.enter.prevent="setValue(option)"
            role="button"
            tabIndex="0"
          >
            <checked-icon v-if="model.value === option.value" />
            <span :class="{ check: model.text === option.text }">{{ option.text }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="mobile-mask" :class="{ 'm-active': opened }">
      <div class="mobile-bg" />
      <div class="mobile-list" :class="{ opened: opened }">
        <ul>
          <li
            v-for="(option, index) in options"
            :key="index"
            :value="{ text: option.text, value: option.value }"
            tabindex="0"
            @click="setValue(option)"
            @keyup.enter.prevent="setValue(option)"
            role="button"
          >
            <span :class="{ 'select-text': model.value === option.value }">{{ option.text }}</span>
            <div class="radio-btn" :class="{ 'select-radio': model.text === option.text }"></div>
          </li>
        </ul>
      </div>
    </div>
    <small v-if="errorMessage" class="form-error pl-4 mt-2">
      {{ errorMessage }}
    </small>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import inputLabel from '#root/components/inputs/InputLabel.vue'
import CheckedIcon from '#root/assets/images/icons/checked-icon.svg'
import DateIcon from '#root/assets/images/icons/date-icon.svg'
import TimeIcon from '#root/assets/images/icons/time-icon.svg'

const props = defineProps({
  modelValue: { type: Object, default: () => ({}) },
  name: { type: String, default: '' },
  inputClass: { type: String, default: '' },
  wrapperClass: { type: String, default: '' },
  disabled: { type: String, default: '' },
  label: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  subLabel: { type: String, default: '' },
  errorMessage: { type: String, default: '' },
  options: {
    type: Array,
    default: () => [{ value: '', text: '' }]
  },
  placeholder: { type: String, default: '' },
  changeOnEvent: { type: Function, default: null },
  dateIcon: { type: Boolean, default: null },
  timeIcon: { type: Boolean, default: null }
})

const emit = defineEmits(['update:modelValue'])

const opened = ref(false)
const confirmed = ref(false)

const closeSelectOnOutsideClick = () => {
  document.addEventListener('click', (e) => {
    if (e.target.classList.contains('mobile-bg') && opened.value) {
      opened.value = false
    }
  })
}

onMounted(() => {
  closeSelectOnOutsideClick()
})

const model = computed({
  get() {
    if (props.modelValue) {
      const { text, value } = props.modelValue
      return { text, value }
    } else {
      return { text: '', value: '' }
    }
  },
  set(newItem) {
    const item = newItem ? props.options.find((option) => option.value === newItem.value) : null

    if (item) {
      emit('update:modelValue', item)
    }
  }
})

watch(opened, () => {
  const dropList = document.getElementsByClassName('dropdown-list')
  for (let i = 0; i < dropList.length; i++) {
    if (dropList[i].className.includes('opened')) {
      dropList[i].className = dropList[i].className.replace('opened', '')
    }
  }
})

const setValue = (option) => {
  model.value = { text: option.text, value: option.value }
  opened.value = false
  confirmed.value = true
  emit('update:modelValue', option)
}
</script>

<style lang="scss">
select option {
  font-weight: $w-body;
}

select {
  display: none;
}

.dropdown {
  position: relative;
  max-width: 100%;
  flex: 1;
}

.opener {
  @include flex-parent(row, nowrap, space-between, center);
  padding: 10px 16px;
  background-color: $white;
  border: solid 1px $gs-400;
  border-radius: $spacing-1;
  color: $core-font;
  cursor: pointer;
  font-size: $body-small;
  line-height: 28px;
  height: 48px;
  user-select: none;

  &.unconfirmed {
    color: $gs-500;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-left: $spacing-2;
  }

  .icon {
    color: $gs-500;
    fill: $gs-500;

    path {
      fill: $gs-500;
    }
  }

  span {
    flex: 1 0 calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
  }
}

.dropdown-list {
  font-size: $body-small;
  line-height: 28px;
  display: none;
  z-index: 99;
  position: absolute;
  top: 78px;
  left: 0;
  background-color: $gs-100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: $spacing-1;
  border: 1px solid $gs-300;
  padding: $spacing-2;
  min-width: 100%;
  max-height: 136px;
  overflow-y: none;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: calc(136px - 16px);
  }

  li {
    @include flex-parent(row, nowrap, flex-start, center);
    margin-top: $spacing-2;
    cursor: pointer;
    user-select: none;
    color: $gs-800;
    font-size: $legal-text;
    line-height: 24px;

    &:first-child {
      margin-top: 0;
    }

    svg {
      fill: currentColor;
      width: 16px;
      height: 16px;
      margin-right: 7px;

      path {
        fill: currentColor;
      }
    }

    span {
      margin-left: 23px;
    }

    .check {
      margin-left: 0;
    }
  }

  &.opened {
    display: none;

    @include media-query('phablet') {
      display: block;
    }
  }
}

.mobile-list {
  font-size: $body-small;
  line-height: 28px;
  display: none;
  z-index: 99;
  position: absolute;
  top: 180px;
  background-color: $white;
  border-radius: $spacing-2;
  border: 1px solid $gs-300;
  max-width: 300px;
  width: 100%;
  max-height: 340px;
  overflow-y: scroll;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-height: calc(340px - 16px);
    width: 100%;
  }

  li {
    @include flex-parent(row, nowrap, space-between, center);
    margin-top: $spacing-2;
    cursor: pointer;
    user-select: none;
    color: $gs-800;
    font-size: $legal-text;
    line-height: 24px;
    border-bottom: 1px solid $gs-300;
    padding: $spacing-2;

    &:first-child {
      margin-top: 0;
    }

    svg {
      fill: currentColor;
      width: 16px;
      height: 16px;
      margin-right: 7px;

      path {
        fill: currentColor;
      }
    }

    .radio-btn {
      width: 24px;
      height: 24px;
      border: 2px solid $gs-400;
      background-color: $white;
      border-radius: 100px;

      &.select-radio {
        background-color: $white;
        border: 2px solid $primary;
        position: relative;

        &:after {
          content: '';
          width: 16px;
          height: 16px;
          background-color: $primary;
          border-radius: 100px;
          position: absolute;
          right: 2px;
          top: 2px;
        }
      }
    }
  }

  &.opened {
    display: block;
    margin: 10px;

    @include media-query('phablet') {
      display: none;
    }
  }
}

.mobile-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: table;
  -webkit-transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.m-active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-query('phablet') {
      display: none;
    }
  }
}

.mobile-bg {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>
