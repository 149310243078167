<!--
  * Component Name: Text and Component
  * Description: Text/Component and Component
  * Props:
      content
  * Components:
      Image, Text, Form, Product Card, Table, Accordion
  * Usage:
      <TextComponent :content="value" />
-->
<template>
  <div :class="[`bgcontainer-${contentData.background}`]" :content-id="props.content?.system?.id">
    <div
      class="text-component-container grid-container mx-auto py-10 lg:py-12 px-4 xxl:px-6"
      :class="{ 'lg:py-9': contentData.background }"
    >
      <HtmlContent
        v-if="contentData.title && contentData.alignment === 'paragraph'"
        :content="contentData.title"
        class="text-component-title"
      />
      <div
        class="text-component grid gap-x-4 grid-cols-4 md:grid-cols-8 xl:grid-cols-12"
        :class="contentData.alignment"
      >
        <div v-if="contentData.leftCol" :class="['left-col', classes.leftCol()]">
          <component
            :is="componentTypeLeft"
            :content="contentData.leftCol"
            :show-title="contentData.alignment !== 'paragraph'"
          />
        </div>
        <div v-if="contentData.rightCol" :class="['right-col', classes.rightCol()]">
          <component
            :is="componentTypeRight"
            :content="contentData.rightCol"
            :wrapper-class="rightColType === 'form_model' || 'multi_step_form_model' ? 'in-page' : ''"
            :remove-padding="rightColType === 'dual_text_block_model'"
            :class="{
              'vertical-product-card': rightColType === 'product_card_model',
              'hide-image': rightColType === 'image_model' && contentData.hideImage,
              'square-image': rightColType === 'image_model' && contentData.squareImage
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useGetContent } from '#root/components/composables/getContent'
import HtmlContent from '#root/components/utils/HtmlContent.vue'
import TextContent from '#root/components/pageblocks/TextContent.vue'
import AccordionView from '#root/components/pageblocks/AccordionView.vue'
import ContentImage from '#root/components/pageblocks/ContentImage.vue'
import GenericForm from '#root/components/forms/GenericForm.vue'
import ProductCard from '#root/components/pageblocks/ProductCard.vue'
import ContentTable from '#root/components/pageblocks/ContentTable.vue'
import DualTextBlock from '#root/components/pageblocks/DualTextBlock.vue'
import MultiStepForm from '#root/components/forms/multi-step/MultiStepForm.vue'
import ContentCtaBlock from '#root/components/pageblocks/ContentCtaBlock.vue'

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const contentData = computed(() => {
  return {
    title: useGetContent(props.content, 'title.value', null),
    leftCol: useGetContent(props.content, 'left_col.linkedItems[0].elements', null),
    rightCol: useGetContent(props.content, 'right_col.linkedItems[0].elements', null),
    flip: useGetContent(props.content, 'flip_content.value[0].codename', null) === 'flip',
    alignment: useGetContent(props.content, 'alignment.value[0].codename', 'center'),
    background: useGetContent(props.content, 'background.value[0].codename', null),
    hideImage: useGetContent(props.content, 'hide_image_mobile.value[0].codename', null) === 'hide',
    squareImage: useGetContent(props.content, 'image_type.value[0].codename', null) === 'square'
  }
})

const leftColType = computed(() => useGetContent(props.content, 'left_col.linkedItems[0].system.type', null))
const rightColType = computed(() =>
  useGetContent(props.content, 'right_col.linkedItems[0].system.type', null)
)

const componentTypeLeft = computed(() => {
  if (leftColType.value === 'text_model') {
    return TextContent
  } else if (leftColType.value === 'accordion_model') {
    return AccordionView
  } else {
    return null
  }
})

const componentTypeRight = computed(() => {
  if (rightColType.value === 'image_model') {
    return ContentImage
  } else if (rightColType.value === 'text_model') {
    return TextContent
  } else if (rightColType.value === 'form_model') {
    return GenericForm
  } else if (rightColType.value === 'product_card_model') {
    return ProductCard
  } else if (rightColType.value === 'accordion_model') {
    return AccordionView
  } else if (rightColType.value === 'table_model') {
    return ContentTable
  } else if (rightColType.value === 'dual_text_block_model') {
    return DualTextBlock
  } else if (rightColType.value === 'multi_step_form_model') {
    return MultiStepForm
  } else if (rightColType.value === 'content_cta_block_model') {
    return ContentCtaBlock
  } else {
    return null
  }
})

const classes = computed(() => {
  return {
    leftCol: () => {
      if (leftColType.value === 'text_model' && rightColType.value === 'image_model') {
        // text and image
        if (contentData.value.squareImage) {
          return !contentData.value.flip
            ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-span-7', 'xxl:col-span-6']
            : [
                'order-2',
                'xl:order-2',
                'col-span-full',
                'xl:col-end-13',
                'xl:col-span-7',
                'xxl:col-end-13',
                'xxl:col-span-6',
                'flip'
              ]
        } else {
          return !contentData.value.flip
            ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-span-6']
            : ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-span-6', 'flip']
        }
      } else if (leftColType.value === 'text_model' && rightColType.value === 'accordion_model') {
        // text and acccordion
        return !contentData.value.flip
          ? ['order-1', 'xl:order-1', 'col-span-full', 'xl:col-span-6', 'xxl:col-end-5']
          : [
              'order-1',
              'xl:order-2',
              'col-span-full',
              'xl:col-start-13',
              'xl:col-end-7',
              'xxl:col-end-13',
              'xxl:col-span-4',
              'mb-10',
              'xl:mt-0',
              'flip'
            ]
      } else if (leftColType.value === 'text_model' && rightColType.value === 'dual_text_block_model') {
        // text and dual text (single item)
        return !contentData.value.flip
          ? ['order-1', 'xl:order-1', 'col-span-full', 'xl:col-span-6', 'xxl:col-end-7']
          : [
              'order-1',
              'xl:order-2',
              'col-span-full',
              'xl:col-start-13',
              'xl:col-end-7',
              'xxl:col-end-13',
              'xxl:col-span-4',
              'mb-10',
              'xl:mt-0',
              'flip'
            ]
      } else if (leftColType.value === 'text_model' && rightColType.value === 'text_model') {
        // text and text
        return !contentData.value.flip
          ? ['order-1', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7', 'mb-10', 'xl:mt-0']
          : ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7', 'flip']
      } else if (leftColType.value === 'accordion_model' && rightColType.value === 'product_card_model') {
        // accordion and product card
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7']
          : ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5', 'flip']
      } else if (
        leftColType.value === 'text_model' &&
        (rightColType.value === 'form_model' || 'multi_step_form_model')
      ) {
        // text and form
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7']
          : ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-end-13', 'xl:col-span-6', 'flip']
      } else {
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7']
          : ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5', 'flip']
      }
    },
    rightCol: () => {
      if (leftColType.value === 'text_model' && rightColType.value === 'image_model') {
        // text and image
        if (contentData.value.squareImage) {
          return !contentData.value.flip
            ? [
                'order-1',
                'xl:order-1',
                'col-span-full',
                'xl:col-end-13',
                'xl:col-span-4',
                'xxl:col-end-13',
                'xxl:col-span-5',
                'mb-6',
                'xl:mb-0'
              ]
            : [
                'order-1',
                'xl:order-1',
                'col-span-full',
                'xl:col-start-1',
                'xl:col-end-5',
                'xl:col-span-4',
                'xxl:col-end-6',
                'xxl:col-span-5',
                'mb-6',
                'xl:mb-0',
                'flip'
              ]
        } else {
          return !contentData.value.flip
            ? ['order-1', 'xl:order-2', 'col-span-full', 'xl:col-span-6']
            : ['order-1', 'xl:order-1', 'col-span-full', 'xl:col-span-6', 'flip']
        }
      } else if (leftColType.value === 'text_model' && rightColType.value === 'accordion_model') {
        // text and accordion
        return !contentData.value.flip
          ? [
              'order-2',
              'xl:order-2',
              'col-span-full',
              'xl:col-end-13',
              'xl:col-span-6',
              'xxl:col-end-13',
              'xxl:col-span-7',
              'mt-10',
              'xl:mt-0'
            ]
          : [
              'order-2',
              'lg:order-1',
              'col-span-full',
              'xl:col-start-1',
              'xl:col-span-6',
              'xxl:col-start-1',
              'xxl:col-span-7',
              'flip'
            ]
      } else if (leftColType.value === 'text_model' && rightColType.value === 'dual_text_block_model') {
        // text and dual text (single item)
        return !contentData.value.flip
          ? [
              'order-2',
              'xl:order-2',
              'col-span-full',
              'xl:col-end-13',
              'xl:col-span-6',
              'xxl:col-end-13',
              'xxl:col-span-5',
              'mt-10',
              'xl:mt-0'
            ]
          : [
              'order-2',
              'lg:order-1',
              'col-span-full',
              'xl:col-start-1',
              'xl:col-span-6',
              'xxl:col-start-1',
              'xxl:col-span-5',
              'flip'
            ]
      } else if (leftColType.value === 'text_model' && rightColType.value === 'text_model') {
        // text and text
        return !contentData.value.flip
          ? ['order-2', 'xl:order-2', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5']
          : [
              'order-1',
              'xl:order-1',
              'col-span-full',
              'xl:col-end-13',
              'xl:col-span-5',
              'mb-10',
              'xl:mb-0',
              'flip'
            ]
      } else if (leftColType.value === 'accordion_model' && rightColType.value === 'product_card_model') {
        // accordion and product cards
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5', 'mt-10', 'xl:mt-0']
          : ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7', 'flip']
      } else if (
        leftColType.value === 'text_model' &&
        (rightColType.value === 'form_model' || 'multi_step_form_model')
      ) {
        // text and form
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5', 'mt-6', 'xl:mt-0']
          : ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-6', 'flip']
      } else {
        return !contentData.value.flip
          ? ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-end-13', 'xl:col-span-5', 'mt-6', 'xl:mt-0']
          : ['order-2', 'xl:order-1', 'col-span-full', 'xl:col-start-1', 'xl:col-end-7', 'flip']
      }
    }
  }
})
</script>

<style lang="scss">
.bgcontainer-support {
  background-color: $accordion-bg;
  padding: 0;

  @include media-query('desktop') {
    padding: $spacing-10 0;
  }
}

.text-component-title {
  @include pageblock-header();
  margin: 0 0 $spacing-6;
}

.text-component {
  &.center {
    align-items: center;
  }

  &.top {
    align-items: flex-start;

    @include media-query('desktop') {
      .right-col {
        > .sidebar-table,
        .vertical-product-card {
          margin: 0;
        }
      }
    }
  }

  .vertical-product-card {
    max-width: 548px;
    margin: auto;

    @include media-query('desktop') {
      max-width: 100%;
    }
  }

  .image-container {
    margin-bottom: $spacing-6;

    img {
      width: 100%;
      display: block;
      margin: 0 auto;
      max-width: 320px;

      @include media-query('phablet') {
        max-width: 504px;
      }
    }
  }
}

@include media-query('tablet') {
  .right-col {
    .image-container {
      display: flex;
      justify-content: center;
    }

    .vertical-product-card {
      .image-container {
        display: block;
      }
    }
  }
}

@include media-query('desktop') {
  .right-col {
    .image-container {
      img {
        max-width: unset;
        margin-bottom: 0;
      }
    }
  }
}

.text-component-container {
  .text-title {
    @include pageblock-header();
  }

  .right-col,
  .left-col {
    .hide-image {
      display: none;
    }

    @include media-query('phablet') {
      .hide-image {
        display: flex;
      }
    }
  }

  .dual-text-content {
    .dual-text-item.m-bg-dark {
      .text-title {
        @include headings {
          color: $dual-text-item-dark-font;
        }
      }
    }
  }

  .content-cta-block-container {
    background-color: $brand-dark;
    color: $core-font !important;
    border-radius: $g-radius;
    padding: $spacing-0;

    .grid-container {
      padding: $spacing-8 $spacing-8;
    }

    .cta-block {
      display: flex;
      color: $core-font !important;
      margin-right: $spacing-6;
      align-items: start;

      .cta {
        margin-top: $spacing-6;
      }

      .phone {
        color: $core-font;
        border-radius: $g-radius !important;
        border: 2px solid $core-font;
        padding: $spacing-3 $spacing-6;
        margin-right: $spacing-12;
      }

      .phone a {
        color: $core-font;
        font-size: $base-font-size;
      }

      .phone svg {
        width: $spacing-6;
        height: $spacing-6;
      }
    }

    .text-block {
      .phone a {
        color: $core-font;
        font-size: $base-font-size;
      }
      .title h2 {
        color: $core-font !important;
        font-size: $h2-m;
      }
      .content p {
        color: $core-font;
      }
    }
    @media (max-width: 425px) {
      .content-cta-block-container .cta-block .title {
        text-align: start;
      }
    }
  }
}

.text-component {
  .right-col {
    .square-image {
      position: relative;
      overflow: hidden;
      width: fit-content;
      margin: 0 auto $spacing-6;
      border-radius: $spacing-6;

      picture {
        img {
          display: block;
          border-radius: $spacing-6;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
