<template>
  <div class="main-banner-container">
    <div class="grid-container mx-auto">
      <div class="main-banner grid gap-x-4 grid-cols-1 xl:grid-cols-12">
        <div
          class="banner-incentive-container banner-image-container xl:col-span-6 xxl:col-span-7 xl:order-2"
        >
          <picture>
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageDesktop)"
              media="(min-width: 1024px)"
              :width="contentData.foregroundImageDesktop?.width || 'auto'"
              :height="contentData.foregroundImageDesktop?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageTablet)"
              media="(min-width: 768px)"
              :width="contentData.foregroundImageTablet?.width || 'auto'"
              :height="contentData.foregroundImageTablet?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <img
              :src="helperImage.optimise(contentData.foregroundImageMobile)"
              :alt="contentData.foregroundImageDesktop?.description || 'Banner Image'"
              :width="contentData.foregroundImageMobile?.width || 'auto'"
              :height="contentData.foregroundImageMobile?.height || 'auto'"
              class="banner-image"
              fetchpriority="high"
              loading="eager"
            />
          </picture>

          <CoverIncentive
            v-if="contentData.incentive"
            :content="contentData.incentive"
            :class="contentData.incentiveLocation"
          />
        </div>
        <div
          class="banner-content flex flex-col justify-start gap-4 px-4 xxl:px-6 xl:col-span-6 xxl:col-span-5"
        >
          <AppointmentSelector
            v-if="contentData.appointmentForm && contentData.type === 'appointment'"
            :content="contentData.appointmentForm"
            :banner-content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
          />
          <MultiStepForm
            v-else-if="contentData.multiStepForm && contentData.type === 'step_banner'"
            :content="contentData.multiStepForm"
            wrapper-class="multistep-quote-container banner py-4"
          />
          <BannerContent
            v-else
            :content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
            :gtm="gtm.heroBannerCTA"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { helperImage } from '#root/utils/helpers'
import getBannerContent from '#root/components/composables/getBannerContent.js'
import MultiStepForm from '#root/components/forms/multi-step/MultiStepForm.vue'
import BannerContent from '#root/components/banners/BannerContent.vue'
import CoverIncentive from '#root/components/pageblocks/CoverIncentive.vue'
import AppointmentSelector from '#root/components/pageblocks/AppointmentSelector.vue'

const props = defineProps({
  content: {
    type: Object,
    default: () => ({})
  }
})

const { gtm, contentData } = getBannerContent(props)
</script>

<style lang="scss">
// Container reset
.site-dignity {
  .main-banner-container {
    background: $gs-200;
    width: 100%;
    overflow-x: hidden;

    .grid-container {
      max-width: unset;
      width: unset;

      @include media-query('desktop') {
        width: $g-desktop-breakpoint-min-width;
      }

      @include media-query('desktop-large') {
        width: $g-desktop-large-breakpoint-min-width;
      }
    }

    .banner-content {
      width: 100%;
      margin: 0 auto;
      z-index: 10;
      padding-top: $spacing-4;

      @include media-query('phablet') {
        max-width: $g-phablet-breakpoint-min-width;
        padding-top: $spacing-4;
      }

      @include media-query('tablet') {
        max-width: 100%;
        width: $g-tablet-breakpoint-min-width;
        padding-top: $spacing-4;
      }

      @include media-query('desktop') {
        max-width: unset;
        width: unset;
        padding-top: $spacing-4;
      }

      @include media-query('desktop-large') {
        max-width: unset;
        width: unset;
        padding-top: $spacing-4;
      }
    }

    .main-banner {
      @include media-query('desktop') {
        padding: 0 16px;
      }

      @include media-query('desktop-large') {
        padding: 0 24px;
      }

      .banner-image-container {
        height: 300px;
        border-bottom: 4px solid $primary;
        position: relative;
        overflow: visible;

        @include media-query('phablet') {
          height: 300px;
        }

        @include media-query('tablet') {
          height: 438px;
        }

        @include media-query('desktop') {
          height: 504px;
          width: auto;
          border-bottom: none;
        }

        @include media-query('desktop-large') {
          height: 608px;
        }

        .banner-image {
          width: 100%;
          object-fit: cover;
          object-position: top;
          display: block;
          height: 100%;

          @include media-query('desktop') {
            height: 100%;
            width: auto;
            position: absolute;
            top: 0;
            left: 16px;
          }
        }
      }

      // Incentive styles
      .incentive-container {
        img {
          width: 104px;
          height: 104px;
          position: absolute;
          right: 16px;
          bottom: 16px;

          @include media-query('tablet') {
            width: 120px;
            height: 120px;
          }

          @include media-query('desktop') {
            width: 132px;
            height: 132px;
            bottom: 24px;
          }

          @include media-query('desktop-large') {
            width: 218px;
            height: 218px;
            bottom: 32px;
          }
        }
      }
    }

    .links {
      .link-element,
      .cta-btn {
        width: 100%;
      }

      @include media-query('phablet') {
        .link-element,
        .cta-btn {
          width: unset;
        }
      }
    }
  }

  //BG shape accent
  .main-banner-container {
    overflow: hidden;

    .main-banner {
      position: relative;

      &::before {
        background-image: url('/assets/images/icons/dignity/banner-watermark.svg');
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        position: absolute;
        z-index: 0;
        pointer-events: none;
        left: 50%;
        transform: translateX(calc(-50% - 976px));
        top: -170px;
        height: 1831px;
        width: 2916px;
      }

      @include media-query('phone') {
        &::before {
          transform: translateX(calc(-50% - 950px));
        }
      }

      @include media-query('phablet') {
        &::before {
          transform: translateX(calc(-50% - 840px));
        }
      }

      @include media-query('tablet') {
        &::before {
          transform: translateX(calc(-50% - 730px));
        }
      }

      @include media-query('desktop') {
        &::before {
          transform: translateX(calc(-50% - 155px));
        }
      }

      @include media-query('desktop-large') {
        &::before {
          transform: translateX(calc(-50% - 172px));
        }
      }
    }
  }

  .main-banner-container {
    .m-phone {
      @include phone-standout();

      background: none;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      font-weight: $w-link;
      pointer-events: unset;

      &::before {
        top: 0px;
      }

      &:hover {
        background: unset;
        color: unset;

        &::before {
          background-color: $phone-standout-font;
        }
      }
    }
  }
}
</style>
